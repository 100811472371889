import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';

import { switchMap, map } from 'rxjs/operators';


// import { IDataModel } from '@plasma/models/base';

import { getUrl } from './utils';


@Injectable()
export class BasePlasmaService {

  protected namespace: string = '';

  constructor(protected http: HttpClient) {}

  public getResource(
    identifier: string,
    namespace: string = this.namespace
  ): Observable<any> {

    const resourceUrl = `${this.baseUrl(namespace)}/${identifier}`;
    return this.makeGet(resourceUrl).pipe(map(res => res as any));

  }

  protected baseUrl(namespace: string = this.namespace): string {
    return getUrl(namespace);
  }

  protected makeGet(url: string, headerOptions: HttpHeaders = null) {
    return this.http.get(url, {
      headers: new HttpHeaders({
        ...headerOptions
      })
    });
  }

  protected makePost(url: string, body = {}, headerOptions: HttpParams = null) {
    return this.http.post(url, body, {
      headers: new HttpHeaders({
        ...headerOptions
      })
    });
  }

  protected makePatch(url: string, body = {}, headerOptions: HttpParams = null) {
    return this.http.patch(url, body, {
      headers: new HttpHeaders({
        ...headerOptions
      })
    });
  }

  protected makeDelete(url: string, headerOptions: HttpHeaders = null) {
    return this.http.delete(url, {
      headers: new HttpHeaders({
        ...headerOptions
      })
    });
  }

}
