import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
  name: 'join'
})
export class JoinPipe implements PipeTransform {
  public transform(value: any[], separator?: string): string {
    if (value) {
      return value.join(separator);
    }
    return null;
  }
}
