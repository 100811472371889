import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';

import { HttpClientJsonpModule } from '@angular/common/http';
import { GoogleMapsModule } from '@angular/google-maps';

import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { FlexLayoutModule } from '@angular/flex-layout';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDividerModule } from '@angular/material/divider';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatTooltipModule } from '@angular/material/tooltip';

import { ClipboardModule } from '@angular/cdk/clipboard';

import { NgxEchartsModule } from 'ngx-echarts';

import { ServiceProviderDashboardComponent } from './service-provider-dashboard.component';
import { OrdersComponent } from './containers/orders/orders.component';
import { OrderComponent } from './containers/order/order.component';
import { ProfileComponent } from './containers/profile/profile.component';
import { ComponentsModule } from '@plasma/components';
// import { AccountComponent } from './account/account.component';

import { IconsProviderModule } from '../icons-provider.module';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzSpaceModule } from 'ng-zorro-antd/space';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzDescriptionsModule } from 'ng-zorro-antd/descriptions';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete';
import { NzStatisticModule } from 'ng-zorro-antd/statistic';
import { NzSwitchModule } from 'ng-zorro-antd/switch';

import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ButtonsModule } from 'ngx-bootstrap/buttons';

import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import {
  faTruckMoving,
  faTree,
  faFileAlt,
  faFileWord,
  faFilePdf,
  faMoneyBill,
  faLink,
  faListAlt,
  faCalendarAlt,
  faUserPlus,
  faPen,
  faEye,
  faBarcode,
  faQrcode,
  faFileUpload,
  faInfoCircle,
  faProjectDiagram,
  faFileDownload,
  faSignInAlt
} from '@fortawesome/free-solid-svg-icons';


const modules = [
  ServiceProviderDashboardComponent,
  OrdersComponent,
  OrderComponent,
  ProfileComponent
  /* AccountComponent, */
];


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    ComponentsModule,
    HttpClientJsonpModule,
    FlexLayoutModule,
    NgxEchartsModule,
    GoogleMapsModule,
    IconsProviderModule,
    NzLayoutModule,
    NzMenuModule,
    NzFormModule,
    NzInputModule,
    NzInputNumberModule,
    NzSelectModule,
    NzButtonModule,
    NzDatePickerModule,
    NzCheckboxModule,
    NzDividerModule,
    NzSpaceModule,
    NzEmptyModule,
    NzMessageModule,
    NzTableModule,
    NzToolTipModule,
    NzAvatarModule,
    NzDropDownModule,
    NzDescriptionsModule,
    NzBadgeModule,
    NzUploadModule,
    NzCardModule,
    NzPageHeaderModule,
    NzAutocompleteModule,
    NzStatisticModule,
    NzSwitchModule,
    BsDatepickerModule.forRoot(),
    ButtonsModule.forRoot(),
    FontAwesomeModule,
    MatFormFieldModule,
    MatInputModule,
    MatDividerModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    MatTableModule,
    MatPaginatorModule,
    MatToolbarModule,
    MatTooltipModule,
    ClipboardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatAutocompleteModule
  ],
  providers: [
  ],
  declarations: modules,
  exports: modules
})
export class ServiceProviderDashboardModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(
      faTruckMoving,
      faTree,
      faFileAlt,
      faFileWord,
      faFilePdf,
      faMoneyBill,
      faLink,
      faListAlt,
      faCalendarAlt,
      faUserPlus,
      faPen,
      faEye,
      faBarcode,
      faQrcode,
      faFileUpload,
      faInfoCircle,
      faProjectDiagram,
      faFileDownload,
      faSignInAlt);
  }
}
