import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import { OrderService } from '@plasma/services/order.plasma';
import { AssetService } from '@plasma/services/asset.plasma';
import { AuthService } from '@plasma/services/auth.plasma';

import { IAsset } from '@plasma/models/asset';
// import { ISupplier } from '@plasma/models/supplier';

import { NzMessageService } from 'ng-zorro-antd/message';


@Component({
  selector: 'app-payment',
  templateUrl: './payment.component.html',
  styleUrls: ['./payment.component.scss']
})
export class PaymentComponent implements OnInit {

  id: string;
  order: any;
  deduction: number = 0;
  deductionPercentage: number = 0;

  constructor(private orderService: OrderService,
              private assetService: AssetService,
              public authService: AuthService,
              private message: NzMessageService,
              protected activatedRoute: ActivatedRoute,
              private location: Location) { }

  ngOnInit(): void {

    this.id = this.activatedRoute.snapshot.paramMap.get('id');
    if (!this.id) {
      return;
    }

    this.orderService.get(this.id)
      .subscribe((order) => {
        this.order = order;
        console.log('order', order);
        this.calculateDeduction(order?.assets, order?.agreed_price);
      }, (error) => {
        console.error('Error', error);
        this.message.create('error', `Error: ${error.error.error}`);
      });

  }

  calculateDeduction(assets: any[], agreedPrice: number) {

    let deductionPercentage = 0;
    let deduction = 0;

    for (let asset of assets) {
      const plannedDates = asset?.planned_dates;
      const actualDates = asset?.actual_dates;

      if ((actualDates?.actual_completion_date > plannedDates?.planned_completion_date) || (actualDates?.actual_delivery_date > plannedDates?.planned_delivery_date)) {
        deductionPercentage += 5;
        deduction += (deductionPercentage/100) * agreedPrice;
      }
    }

    this.deductionPercentage = deductionPercentage;
    this.deduction = deduction;
  }

  authorizePayment(costPaid, deduction) {
    this.orderService.authorizePayment(this.id, costPaid, deduction)
      .subscribe((resp) => {
        console.log('resp', resp);
        // this.order.is_payment_paid = resp?.is_payment_paid;
        this.order.is_payment_paid = true;
        this.message.success(`Payment Authorized!`);
      }, (error) => {
        console.error('Error', error);
        this.message.error(`Error ${error.error.error}`);
      });
  }

  onBack() {
    this.location.back();
  }

}
