<nz-page-header class="site-page-header" (nzBack)="onBack()" nzBackIcon nzTitle="Create Requisition"></nz-page-header>
<nz-divider nzText="Requisition Info" nzOrientation="left"></nz-divider>

<div fxLayout="row" fxLayoutAlign="start center">
    <div fxLayoutAlign="center center" id="field-container">
      <mat-form-field appearance="fill" fxFlexFill>
        <mat-label>Project ID</mat-label>
        <input type="text" matInput [ngModel]="project?.projectId" disabled>
      </mat-form-field>
    </div>
  </div>
  <form novalidate [formGroup]="order" (ngSubmit)="onSubmit(order)">
    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between center">
      <div fxLayoutAlign="center center" id="field-container">
        <mat-form-field appearance="fill" fxFlexFill>
          <mat-label>Contract No</mat-label>
          <input matInput required formControlName="contractNo">
          <mat-error *ngIf="order.get('contractNo').invalid">The input is not a valid Contract No!</mat-error>
        </mat-form-field>
      </div>
      
      <div fxLayoutAlign="center center" id="field-container">
        <mat-form-field appearance="fill" fxFlexFill>
          <mat-label>Account No</mat-label>
          <input matInput required formControlName="accountNo">
          <mat-error *ngIf="order.get('accountNo').invalid">The input is not a valid Account No!</mat-error>
        </mat-form-field>
      </div>
      
      <div fxLayoutAlign="center center" id="field-container">
        <mat-form-field appearance="fill" fxFlexFill>
          <mat-label>Project Cost Centre Code</mat-label>
          <input matInput required formControlName="projectCostCentreCode">
          <mat-error *ngIf="order.get('projectCostCentreCode').invalid">The input is not a valid Project Cost Centre Code!</mat-error>
        </mat-form-field>
      </div>
    </div>
  
    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between center">
      <div fxLayoutAlign="center center" id="field-container">
        <mat-form-field appearance="fill" fxFlexFill>
          <mat-label>Purchase Order Reference No</mat-label>
          <input matInput required formControlName="orderNo">
          <mat-error *ngIf="order.get('orderNo').invalid">The input is not a valid Order Reference No!</mat-error>
        </mat-form-field>
      </div>
  
      <div fxLayoutAlign="center center" id="field-container">
        <mat-form-field appearance="fill" fxFlexFill>
          <mat-label>Confirmation Order</mat-label>
          <input matInput required formControlName="confirmationOrder">
          <mat-error *ngIf="order.get('confirmationOrder').invalid">The input is not a valid Confirmation Order!</mat-error>
        </mat-form-field>
      </div>

      <div fxLayoutAlign="center center" id="field-container">
        <mat-form-field appearance="fill" fxFlexFill>
          <mat-label>Service Provider</mat-label>
          <mat-select required formControlName="serviceProvider">
            <mat-option value="PLEASE_SELECT">Please Select</mat-option>
            <mat-option *ngFor="let serviceProvider of serviceProviders" [value]="serviceProvider?.publicKey">
              {{serviceProvider?.name}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="order.get('serviceProvider').invalid">Please select a Service Provider!</mat-error>
        </mat-form-field>
      </div>
    </div>

    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between center">
        <div fxLayoutAlign="center center" id="field-container">
          <mat-form-field appearance="fill" fxFlexFill>
            <mat-label>ID</mat-label>
            <input matInput formControlName="bim">
            <mat-error *ngIf="order.get('bim').invalid">The input is not a valid ID!</mat-error>
          </mat-form-field>
        </div>
        
        <div fxLayoutAlign="center center" id="field-container">
          <mat-form-field appearance="fill" fxFlexFill>
            <mat-label>Stage</mat-label>
            <mat-select required formControlName="stage">
              <mat-option *ngFor="let stage of stages" [value]="stage?.id">
                {{stage?.name}}
              </mat-option>
            </mat-select>
            <mat-error *ngIf="order.get('stage').invalid">Please select a Stage!</mat-error>
          </mat-form-field>
        </div>

        
        <div fxLayoutAlign="center center" id="field-container">
            <ng-template [ngIf]="order.get('stage').value">
              <mat-form-field appearance="fill" fxFlexFill>
                <mat-label>Phase</mat-label>
                <mat-select required formControlName="phase">
                  <mat-option *ngFor="let phase of phases" [value]="phase?.id">
                    {{phase?.name}}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="order.get('phase').invalid">Please select a Phase!</mat-error>
              </mat-form-field>
            </ng-template>
        </div>
        
    </div>

    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between center">
        <div fxLayoutAlign="center center" id="field-container">
          <mat-form-field appearance="fill" fxFlexFill>
            <mat-label>Total Price</mat-label>
            <input matInput formControlName="agreedPrice" readonly>
            <mat-error *ngIf="order.get('agreedPrice').invalid">The input is not a valid Price!</mat-error>
          </mat-form-field>
        </div>
    
        <div fxLayoutAlign="center center" id="field-container">
          <mat-checkbox formControlName="internalOrder">Internal</mat-checkbox>
        </div>
    </div>

    <nz-divider nzText="Items" nzOrientation="left"></nz-divider>

    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="end center">
        <div fxLayoutAlign="center center" id="field-container">
          <button mat-raised-button fxFlexFill style="color: white; background-color: #2196f3;" (click)="addItem($event)">Add Item</button>
        </div>
    </div>

    <ng-container formArrayName="assets" *ngFor="let item of order.get('assets')['controls']; let i = index;">
        <ng-container [formGroupName]="i">
          <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between center">
            <!-- <div fxLayoutAlign="center center" id="field-container">
              <mat-form-field appearance="fill" fxFlexFill>
                <mat-label>Item Type</mat-label>
                <input matInput required formControlName="assetType">
                <mat-error *ngIf="order.get('assets')[i].get('assetType').invalid">Please enter an Item Type!</mat-error>
              </mat-form-field>
            </div>
            
            <div fxLayoutAlign="center center">
              <mat-checkbox formControlName="multipleItems" id="{{'multipleItems_'+i}}">Multiple Items</mat-checkbox>
            </div> -->

            <div fxLayoutAlign="center center" id="field-container">
              <mat-form-field appearance="fill" fxFlexFill>
                <mat-label>Requisition Description</mat-label>
                <textarea matInput required formControlName="description" rows="1"></textarea>
                <!-- <mat-error *ngIf="order.get('contractNo').invalid">The input is not a valid Contract No!</mat-error> -->
              </mat-form-field>
            </div>
    
            <div fxLayoutAlign="center center" id="field-container">
              <!-- <button type="button" class="btn btn-primary btn-sm">Small button</button> -->
              <button nz-button nzType="primary" nzSize="small" nzShape="circle" (click)="removeItem($event, i)"><i nz-icon nzType="minus" nzTheme="outline"></i></button>
            </div>
          </div>
    
          <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between center">
            <div fxLayoutAlign="center center" id="field-container">
              <mat-form-field appearance="fill" fxFlexFill>
                <mat-label>Quantity</mat-label>
                <input matInput required formControlName="quantity">
                <!-- <mat-error *ngIf="order.get('projectCostCentreCode').invalid">The input is not a valid Project Cost Centre Code!</mat-error> -->
              </mat-form-field>
            </div>
    
    
            <div fxLayoutAlign="center center" id="field-container">
              <mat-form-field appearance="fill" fxFlexFill>
                <mat-label>Price</mat-label>
                <input matInput required formControlName="price">
                <!-- <mat-error *ngIf="order.get('contractNo').invalid">The input is not a valid Contract No!</mat-error> -->
              </mat-form-field>
            </div>
            
            <ng-container formGroupName="plannedDates">
              <div fxLayoutAlign="center center" id="field-container">
                <mat-form-field appearance="fill" fxFlexFill>
                  <mat-label>Delivery Date</mat-label>
                  <input matInput required [matDatepicker]="plannedDeliveryDate" formControlName="plannedDeliveryDate">
                  <mat-datepicker-toggle matSuffix [for]="plannedDeliveryDate"></mat-datepicker-toggle>
                  <mat-datepicker #plannedDeliveryDate></mat-datepicker>
                  <!-- <mat-error *ngIf="order.get('accountNo').invalid">The input is not a valid Account No!</mat-error> -->
                </mat-form-field>
              </div>
            </ng-container>
          </div>
    
          <!-- <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between center">
            <div fxLayoutAlign="center center" id="field-container">
              <mat-form-field appearance="fill" fxFlexFill>
                <mat-label>Description</mat-label>
                <textarea matInput formControlName="description" rows="1"></textarea>
                <mat-error *ngIf="order.get('contractNo').invalid">The input is not a valid Contract No!</mat-error>
              </mat-form-field>
            </div>
          </div> -->
    
        </ng-container>
    </ng-container>
    
    <nz-divider></nz-divider>

    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between end">
        <div fxLayoutAlign="center center" id="field-container">
          <mat-form-field appearance="fill" fxFlexFill>
            <mat-label>Delivery Location</mat-label>
            <input matInput required formControlName="deliveryLocation">
            <mat-error *ngIf="order.get('deliveryLocation').invalid">The input is not a valid Delivery Location!</mat-error>
          </mat-form-field>
        </div>
        
        <div fxLayoutAlign="center center" id="field-container">
          <mat-form-field appearance="fill" fxFlexFill>
            <mat-label>Date of Completion</mat-label>
            <input matInput required [matDatepicker]="completionDate" formControlName="completionDate">
            <mat-datepicker-toggle matSuffix [for]="completionDate"></mat-datepicker-toggle>
            <mat-datepicker #completionDate></mat-datepicker>
            <mat-error *ngIf="order.get('completionDate').invalid">Please select Completion Date!</mat-error>
          </mat-form-field>
        </div>
        
        <div fxLayoutAlign="center center" id="field-container">
          <mat-form-field appearance="fill" fxFlexFill>
            <mat-label>Description</mat-label>
            <textarea matInput rows="1" formControlName="description"></textarea>
            <mat-error *ngIf="order.get('description').invalid">The input is not a valid Project Cost Centre Code!</mat-error>
          </mat-form-field>
        </div>
    </div>

    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between end">
        <div fxLayoutAlign="center center" id="field-container">
          <mat-form-field appearance="fill" fxFlexFill>
            <mat-label>Payment Terms</mat-label>
            <textarea matInput rows="1" formControlName="paymentTerms"></textarea>
            <mat-error *ngIf="order.get('paymentTerms').invalid">The input is not a valid Payment Terms!</mat-error>
          </mat-form-field>
        </div>
        
        <div fxLayoutAlign="center center" id="field-container">
          <mat-form-field appearance="fill" fxFlexFill>
            <mat-label>Shipment Method/Dates</mat-label>
            <textarea matInput rows="1" formControlName="shipmentMethodDates"></textarea>
            <mat-error *ngIf="order.get('shipmentMethodDates').invalid">The input is not a valid Shipment Method/Dates!</mat-error>
          </mat-form-field>
        </div>
        
        <div fxLayoutAlign="center center" id="field-container">
          <mat-form-field appearance="fill" fxFlexFill>
            <mat-label>Technical Details</mat-label>
            <textarea matInput rows="1" formControlName="technicalDetails"></textarea>
            <mat-error *ngIf="order.get('technicalDetails').invalid">The input is not a valid Technical Details!</mat-error>
          </mat-form-field>
        </div>
    </div>

    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between end">
        <div fxLayoutAlign="center center" id="field-container">
          <mat-form-field appearance="fill" fxFlexFill>
            <mat-label>Special Instructions</mat-label>
            <textarea matInput rows="1" formControlName="specialInstructions"></textarea>
            <mat-error *ngIf="order.get('specialInstructions').invalid">The input is not a valid Special Instructions!</mat-error>
          </mat-form-field>
        </div>
        
        
        <div fxLayoutAlign="center center" id="field-container">
          <mat-form-field appearance="fill" fxFlexFill>
            <mat-label>Model Number/SKU</mat-label>
            <input matInput formControlName="sku">
            <mat-error *ngIf="order.get('sku').invalid">The input is not a valid Model Number/SKU!</mat-error>
          </mat-form-field>
        </div>

        <div fxLayoutAlign="center center" id="field-container">
            <mat-form-field appearance="fill" fxFlexFill>
              <mat-label>Company Billing Address</mat-label>
              <textarea matInput rows="1" required formControlName="billingAddress"></textarea>
              <mat-error *ngIf="order.get('billingAddress').invalid">The input is not a valid Company Billing Address!</mat-error>
            </mat-form-field>
        </div>
    </div>

    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between center">
        <div fxLayoutAlign="center center" id="field-container">
          <mat-checkbox [hidden]="true" formControlName="review">Review</mat-checkbox>
        </div>
    </div>
    
    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="end center">
        <div fxLayoutAlign="center center" id="field-container">
          <button type="submit" mat-raised-button fxFlexFill [disabled]="order.invalid" style="color: white; background-color: #2196f3;">Create Requisition</button>
        </div>
    </div>