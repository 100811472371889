<nz-page-header class="site-page-header" (nzBack)="onBack()" nzBackIcon></nz-page-header>
<ng-container *ngIf="user">
  <nz-divider nzText="User" nzOrientation="left"></nz-divider>
  <form novalidate="" [formGroup]="user" (ngSubmit)="onSubmit(user)">
    <div class="form-row">
      <div class="col-md-12">
        <div class="form-group row">
          <label for="name" class="col-sm-4 col-form-label">Full Name</label>
          <div class="col-sm-8">
            <input type="text" formControlName="name" class="form-control form-control-sm" id="name">
          </div>
        </div>
      </div>
    </div>

    <ng-container formGroupName="address">
      <div class="form-row">
        <div class="col-md-12">
          <div class="form-group row">
            <label for="address" class="col-sm-4 col-form-label">Address</label>
            <div class="col-sm-8">
              <input type="text" formControlName="address" class="form-control form-control-sm" id="address">
            </div>
          </div>
        </div>
      </div>
    </ng-container>

    <div class="form-row">
      <div class="col-md-12">
        <div class="form-group row">
          <label for="description" class="col-sm-4 col-form-label">Description</label>
          <div class="col-sm-8">
            <input type="text" formControlName="description" class="form-control form-control-sm" id="description">
          </div>
        </div>
      </div>
    </div>

    <div class="form-row">
      <div class="col-md-12">
        <div class="form-group row" formGroupName="role">
          <label for="role" class="col-sm-4 col-form-label">Role</label>
          <div class="col-sm-8">
            <input type="text" [value]="user.get('role').get('type').value | titlecase" formControlName="type" class="form-control form-control-sm" id="role">
          </div>
        </div>
      </div>
    </div>

    <div class="form-row">
      <div class="col-md-12">
        <div class="form-group row" formGroupName="subRole">
          <label for="subRole" class="col-sm-4 col-form-label">Sub Role</label>
          <div class="col-sm-8">
            <select formControlName="type" (change)="onSubRoleChange($event.target.value)" class="form-control form-control-sm custom-select custom-select-sm" id="supplier">
            <option *ngFor="let subRole of subRoleOptions" [value]="subRole?.value">{{subRole?.title}}</option>
          </select>
          </div>
        </div>
      </div>
    </div>

    <div class="form-row">
      <div class="col-md-12">
        <div class="form-group row">
          <label for="permissions" class="col-sm-4 col-form-label">Permissions</label>
          <div class="col-sm-8" id="permissions">
            <div class="custom-control custom-switch">
              <input type="checkbox" formControlName="canAssignUsers" class="custom-control-input" id="canAssignUsers">
              <label class="custom-control-label" for="canAssignUsers">Can Assign Users</label>
            </div>
            <div class="custom-control custom-switch">
              <input type="checkbox" formControlName="canAuthorisePayment" class="custom-control-input" id="canAuthorisePayment">
              <label class="custom-control-label" for="canAuthorisePayment">Can Authorise Payment</label>
            </div>
            <div class="custom-control custom-switch">
              <input type="checkbox" formControlName="canCheckProvenance" class="custom-control-input" id="canCheckProvenance">
              <label class="custom-control-label" for="canCheckProvenance">Can Check Provenance</label>
            </div>
            <div class="custom-control custom-switch">
              <input type="checkbox" formControlName="canInviteProjectOwners" class="custom-control-input" id="canInviteProjectOwners">
              <label class="custom-control-label" for="canInviteProjectOwners">Can Invite Project Owners</label>
            </div>
            <div class="custom-control custom-switch">
              <input type="checkbox" formControlName="canInviteUsers" class="custom-control-input" id="canInviteUsers">
              <label class="custom-control-label" for="canInviteUsers">Can Invite Users</label>
            </div>
            <div class="custom-control custom-switch">
              <input type="checkbox" formControlName="canManageOrders" class="custom-control-input" id="canManageOrders">
              <label class="custom-control-label" for="canManageOrders">Can Manage Orders</label>
            </div>
            <div class="custom-control custom-switch">
              <input type="checkbox" formControlName="canManageProjectOwners" class="custom-control-input" id="canManageProjectOwners">
              <label class="custom-control-label" for="canManageProjectOwners">Can Manage Project Owners</label>
            </div>
            <div class="custom-control custom-switch">
              <input type="checkbox" formControlName="canManageProjects" class="custom-control-input" id="canManageProjects">
              <label class="custom-control-label" for="canManageProjects">Can Manage Projects</label>
            </div>
            <div class="custom-control custom-switch">
              <input type="checkbox" formControlName="canManageUsers" class="custom-control-input" id="canManageUsers">
              <label class="custom-control-label" for="canManageUsers">Can Manage Users</label>
            </div>
            <div class="custom-control custom-switch">
              <input type="checkbox" formControlName="canTrackOrders" class="custom-control-input" id="canTrackOrders">
              <label class="custom-control-label" for="canTrackOrders">Can Track Orders</label>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="form-row">
      <div class="col-md-12">
        <div class="form-group row">
          <div class="col-sm-4">
            <button type="submit" [disabled]="user.invalid" class="btn btn-primary btn-sm">Update Profile</button>
          </div>
        </div>
      </div>
    </div>

  </form>
</ng-container>
