import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
  name: 'stageSplit'
})
export class StageSplitPipe implements PipeTransform {
  public transform(value: string): string {
    if (value) {
      return value.split('_').slice(1, value.split('_').length).join(' ');
    }
    return null;
  }
}
