import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import { OrderService } from '@plasma/services/order.plasma';

import { NzMessageService } from 'ng-zorro-antd/message';


@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss']
})
export class OrderComponent implements OnInit {

  id: string;
  order: any;
  checked = false;

  constructor(private orderService: OrderService,
              private message: NzMessageService,
              protected activatedRoute: ActivatedRoute,
              private location: Location) { }

  ngOnInit(): void {

    this.id = this.activatedRoute.snapshot.paramMap.get('id');
    if (!this.id) {
      return;
    }

    this.orderService.get(this.id)
      .subscribe((order) => {
        this.order = order;
        this.checked = order.is_completed;
        console.log('order', order);
      }, (error) => {
        console.error('Error', error);
        this.message.create('error', `Error: ${error.error.error}`);
      });

  }

  onChange(event) {
    console.log('event', event);
    if (this.order) {
      this.orderService.markComplete(this.id, event)
        .subscribe((resp) => {
          console.log('resp', resp);
          this.message.create('success', `Requisition was successfully marked ${event ? 'Completed' : 'In Complete'}!`);
        }, (error) => {
          console.error(error);
          this.message.create('error', `Error: ${error.error.error}`);
        });
    }
  }

  onBack() {
    this.location.back();
  }

}
