<h1 mat-dialog-title>{{data.title}}</h1>
<mat-dialog-content class="mat-typography">
  <!-- <ng-container *ngIf="data.paragraphs.length > 0">
    <p *ngFor="let paragraph of data.paragraphs; let i = index;">{{paragraph}}</p>
    <img *ngFor="let imgLink of data.imgLinks; let i = index;" [src]="'assets/img/'+imgLink" [width]="data.isMobile ? '200' : '800'">
  </ng-container> -->
  <nz-row [nzGutter]="5">
    <nz-col [nzSpan]="6">
      <nz-statistic [nzValue]="(calculateOnTimeInFull(data.suppliers[data.title]) | number)!" nzSuffix="%" [nzTitle]="'On Time in Full'"></nz-statistic>
    </nz-col>
    <nz-col [nzSpan]="6">
      <nz-statistic [nzValue]="(calculateRightFirstTime(data.suppliers[data.title]) | number)!" nzSuffix="%" [nzTitle]="'Right First Time'"></nz-statistic>
    </nz-col>
    <nz-col [nzSpan]="6">
      <nz-statistic [nzValue]="(calculateConformanceToSchedule(data.suppliers[data.title]) | number)!" nzSuffix="%" [nzTitle]="'Conformance to Schedule'"></nz-statistic>
    </nz-col>
    <nz-col [nzSpan]="6">
      <nz-statistic [nzValue]="(calculateDefectedPer(data.suppliers[data.title]) | number)!" [nzTitle]="'Defects per 1,000,000'"></nz-statistic>
    </nz-col>
  </nz-row>
  <div class="row">
    <!-- <div class="col-sm-4">
      <ng-template [ngIf]="displayOnTimeInFullChart">
        <div echarts [options]="generateHorizontalBarChart(supplier, calculateOnTimeInFull(suppliers[supplier]))"></div>
      </ng-template>
    </div> -->
    <div class="col-sm-6">
      <ng-template [ngIf]="data.displayRightFirstTimeChart">
        <div echarts [options]="generatePieChart(data.title, 'right_first_time', calculateRightFirstTime(data.suppliers[data.title]))"></div>
      </ng-template>
    </div>
    <div class="col-sm-6">
      <ng-template [ngIf]="data.displayConformanceChart">
        <div echarts [options]="generatePieChart(data.title, 'conformance_to_schedule', calculateConformanceToSchedule(data.suppliers[data.title]))"></div>
      </ng-template>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cancel</button>
</mat-dialog-actions>
