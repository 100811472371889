import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { AuthService } from './services/auth.plasma';
import { AgentsService } from './services/agents.plasma';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  constructor(private authService: AuthService,
              private agentService: AgentsService,
              private router: Router) {
    let sessionId: string = null;
    sessionId = this.authService.getAuthToken();

    if (sessionId) {
      console.log('sessionId', sessionId);
      this.authService.setAuthToken(sessionId);
      this.agentService.get(this.authService.getPublicKey())
        .subscribe((agent) => {
          console.log('setting agent');
          this.authService.setUser({agent, authorization: sessionId});
          console.log('user set');
          if (agent.role.type === 'SUPPLIER') {
            this.router.navigate(['/dashboard', { outlets: { dashboard: 'orders' }}]);
          } else if (agent.role.type === 'SERVICE_PROVIDER') {
            this.router.navigate(['/service-provider', { outlets: { dashboard: 'dashboard' }}]);
          } else {
            this.router.navigate(['/dashboard', { outlets: { dashboard: 'account' }}]);
          }
        }, (error) => {
          console.error('Error', error);
          // this.message.create('error', `Error: ${error.error.error}`);
        });
    }
  }

}
