<nz-page-header class="site-page-header" (nzBack)="onBack()" nzBackIcon></nz-page-header>
<div fxLayout="row" fxLayoutAlign="start center" style="background-color: #0d47a1; margin-bottom: 20px">
    
  <div fxLayoutAlign="center center" style="background-color: #2196f3; padding: 10px 10px 0 10px;">
    <button mat-icon-button class="example-icon" style="color: white;" aria-label="Example icon-button with menu icon" fxFlexFill>
      <fa-icon [icon]="['fas', 'list-alt']" size="2x"></fa-icon>
    </button>
  </div>
  
  <div fxLayoutAlign="center center" style="padding-left: 1em;">
    <span fxFlexFill><h3 class="mat-h2" style="margin-bottom: 0; color: white;">Payment</h3></span>
    <span class="example-spacer"></span>
  </div>

</div>
<ng-container>
  <h4 class="mat-h2" color="primary" style="color: #2196f3; margin-bottom: 0px;">Order Info</h4>
  <mat-divider style="margin-bottom: 20px;"></mat-divider>
  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between center">
    <div fxLayoutAlign="center center" id="field-container">
      <mat-form-field appearance="fill" fxFlexFill>
        <mat-label>Contract No</mat-label>
        <input matInput readonly [ngModel]="order?.contract_no">
      </mat-form-field>
    </div>
    
    <div fxLayoutAlign="center center" id="field-container">
      <mat-form-field appearance="fill" fxFlexFill>
        <mat-label>Account No</mat-label>
        <input matInput readonly [ngModel]="order?.account_no">
      </mat-form-field>
    </div>
    
    <div fxLayoutAlign="center center" id="field-container">
      <mat-form-field appearance="fill" fxFlexFill>
        <mat-label>Project Cost Centre Code</mat-label>
        <input matInput readonly [ngModel]="order?.project_cost_centre_code">
      </mat-form-field>
    </div>
  </div>

  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between center">
    <div fxLayoutAlign="center center" id="field-container">
      <mat-form-field appearance="fill" fxFlexFill>
        <mat-label>Order No</mat-label>
        <input matInput readonly [ngModel]="order?.order_no">
      </mat-form-field>
    </div>
    
    <div fxLayoutAlign="center center" id="field-container">
      <mat-form-field appearance="fill" fxFlexFill>
        <mat-label>Confirmation Order</mat-label>
        <input matInput readonly [ngModel]="order?.confirmation_order">
      </mat-form-field>
    </div>
    
    <div fxLayoutAlign="center center" id="field-container">
      <mat-form-field appearance="fill" fxFlexFill>
        <mat-label>Payment Status</mat-label>
        <input matInput readonly [value]="order?.is_payment_paid ? 'Paid' : 'Unpaid'">
      </mat-form-field>
    </div>
  </div>
  <ng-template [ngIf]="(authService.getUser() | async)?.role?.type === 'BUYER'">
    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between center">
      <div fxLayoutAlign="center center" id="field-container">
        <mat-form-field appearance="fill" fxFlexFill>
          <mat-label>Supplier</mat-label>
          <input matInput readonly [ngModel]="order?.assets[0]?.supplier?.name">
        </mat-form-field>
      </div>
      
      <div fxLayoutAlign="center center" id="field-container">
        <mat-form-field appearance="fill" fxFlexFill>
          <mat-label>Actual Cost</mat-label>
          <span matPrefix>&pound;</span>
          <input matInput readonly [value]="order?.actual_cost | number: '1.0-3'">
        </mat-form-field>
      </div>
      
      <div fxLayoutAlign="center center" id="field-container">
        <mat-form-field appearance="fill" fxFlexFill>
          <mat-label>Payable Price</mat-label>
          <span matPrefix>&pound;</span>
          <input matInput readonly [value]="order?.agreed_price - deduction | number: '1.0-3'">
        </mat-form-field>
      </div>
    </div>

    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between center">
      <div fxLayoutAlign="center center" id="field-container">
        <mat-form-field appearance="fill" fxFlexFill>
          <mat-label>Payment Status</mat-label>
          <input [ngClass]="{'text-danger': !order?.is_payment_paid ? true : false, 'text-success': order?.is_payment_paid ? true : false }" matInput readonly [value]="order?.is_payment_paid === false ? 'Payment Not Authorized' : 'Payment Authorized'">
        </mat-form-field>
      </div>
      
    </div>
  
    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="end center">
      <div fxLayoutAlign="center center" id="field-container">
        <button mat-raised-button fxFlexFill style="color: white; background-color: #2196f3;" (click)="authorizePayment(order?.agreed_price - deduction, deduction)">Authorize Payment</button>
      </div>
    </div>
  </ng-template>
</ng-container>
