import { Component, OnInit } from '@angular/core';

import { MatDialog } from '@angular/material/dialog';

import { AgentsService } from '@plasma/services/agents.plasma';

import { ISupplier } from '@plasma/models/supplier';

import { NzMessageService } from 'ng-zorro-antd/message';
import { UploadedDocumentsDialogComponent } from '@plasma/components/dialog/uploaded-documents.component';


@Component({
  selector: 'app-suppliers',
  templateUrl: './suppliers.component.html',
  styleUrls: ['./suppliers.component.scss']
})
export class SuppliersComponent implements OnInit {

  suppliers: any[];
  showLoading: boolean = false;
  displayedColumns: string[] = ['name', 'role', 'address', 'description', 'createdOn', 'actions'];
  dataSource = null;

  constructor(private agentService: AgentsService,
              private message: NzMessageService,
              public dialog: MatDialog) { }

  ngOnInit(): void {

    this.showLoading = true;
    this.agentService.getByRole('supplier')
      .subscribe((suppliers) => {
        this.suppliers = suppliers;
        this.dataSource = suppliers;
        this.showLoading = false;
      }, (error) => {
        this.showLoading = false;
        console.error('Error', error);
        this.message.create('error', `Error: ${error.error}`);
      });

  }

  openComplianceDocumentsDialog(supplier) {
    const dialogRef = this.dialog.open(UploadedDocumentsDialogComponent, {
      data: {
        title: `Compliance Evidence - ${supplier?.name}`,
        links: supplier?.account?.complianceEvidence
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

}
