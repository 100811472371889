import {DataSource} from '@angular/cdk/collections';
import { Observable } from 'rxjs';

import { IInventory } from '@plasma/models/inventory';

import { InventoryService } from '@plasma/services/inventory.plasma';


export class InventoryDataSource extends DataSource<any> {
    constructor(private inventoryService: InventoryService, private id: string) {
      super();
    }
    connect(): Observable<IInventory[]> {
      return this.inventoryService.getByAccount(this.id);
    }
    disconnect() {}
}
