<div nz-row [nzGutter]="8">
  <div nz-col [nzSpan]="24">
    <h6>{{user?.account?.name}} - {{user?.account?.id}}</h6>
  </div>
</div>
<ng-template [ngIf]="user?.subRole?.type === 'ADMINISTRATOR'">
  <div class="button-container">
    <ng-template [ngIf]="user?.canManageOrders">
      <button nz-button nzType="primary" [routerLink]="['/dashboard', { outlets: { dashboard: ['create-order'] } }]">Create Order</button>
    </ng-template>
    <button nz-button nzType="primary" [class]="user.canManageOrders ? 'ml-2' : null" (click)="createModal()">Create Project</button>
    <button nz-button nzType="primary" class="ml-2" (click)="createInviteProjectOwnerModal()">Invite Project Owner</button>
  </div>
</ng-template>

<div class="button-container" style="margin-top: 10px;">
  <mat-form-field style="width: 110px;">
    <mat-label>Project</mat-label>
    <mat-select (selectionChange)="updateProject($event)">
      <mat-option value="all">All</mat-option>
      <mat-option *ngFor="let project of projects" [value]="project.id">{{project.name}}</mat-option>
    </mat-select>
  </mat-form-field>
  <mat-form-field style="width: 110px;" class="ml-2">
    <mat-label>Stage</mat-label>
    <mat-select [disabled]="disableStage" (selectionChange)="updateStage($event)">
      <mat-option value="all">All</mat-option>
      <mat-option *ngFor="let stage of stages" [value]="stage.id">{{stage.name}}</mat-option>
    </mat-select>
  </mat-form-field>
  <button nz-button nzType="primary" class="ml-2" (click)="filterOrders()">View Orders</button>
  <ng-template [ngIf]="user?.subRole?.type !== 'USER'">
    <button nz-button nzType="primary" class="ml-2" (click)="navigateToProjectStatus()">View Project Status</button>
  </ng-template>
  <!-- <button nz-button nzType="primary" class="ml-2" (click)="resetOrders()">Reset Orders</button> -->
</div>


<div style="margin-top: 10px;">
  
  <!-- <mat-form-field>
    <mat-label>Filter</mat-label>
    <input matInput (keyup)="applyFilter($event)" placeholder="Ex. ium">
  </mat-form-field> -->
  
  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>
  
    <!-- Position Column -->
    <ng-container matColumnDef="contract_no">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Contract No. </th>
      <td mat-cell label="Contract No." *matCellDef="let order"> {{order.contract_no}} </td>
    </ng-container>
  
    <!-- Name Column -->
    <ng-container matColumnDef="account_no">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Account No. </th>
      <td mat-cell label="Account No." *matCellDef="let order"> {{order.account_no}} </td>
    </ng-container>
  
    <!-- Weight Column -->
    <ng-container matColumnDef="project_cost_centre_code">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Cost Centre </th>
      <td mat-cell label="Cost Centre" *matCellDef="let order"> {{order.project_cost_centre_code}} </td>
    </ng-container>
  
    <!-- Symbol Column -->
    <ng-container matColumnDef="order_no">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Order No. </th>
      <td mat-cell label="Order No." *matCellDef="let order"> {{order.order_no}} </td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="project">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Project </th>
      <td mat-cell label="Project" *matCellDef="let order"> {{order.project.name}} </td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="stage">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Stage </th>
      <td mat-cell label="Stage" *matCellDef="let order"> {{order.stage.name}} </td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="assets">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> No. of Items </th>
      <td mat-cell label="No. of Items" *matCellDef="let order"> {{order.assets?.length}} </td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="agreed_price">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Total Price </th>
      <td mat-cell label="Total Price" *matCellDef="let order"> £{{ order?.agreed_price | number: '1.2-3' }} </td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="destination">
      <th mat-header-cell *matHeaderCellDef mat-sort-header style="text-align: center">Destination</th>
      <td mat-cell label="Destination" *matCellDef="let order"> <img [matTooltip]="order?.destination + ' (click to copy)'" matTooltipPosition="above" (click)="copyToClipboard(order?.destination)" src="assets/img/location-question.png" style="width: 1.25em"></td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="createdOn">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Created On </th>
      <td mat-cell label="Created On" *matCellDef="let order"> {{ order?.createdOn | date: 'yy-MM-dd, HH:mm' }} </td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
      <td mat-cell label="Status" *matCellDef="let order"> <div class="circle" [ngClass]="calculateStatus(order?.assets)"></div> </td>
    </ng-container>

    <!-- Symbol Column -->
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> </th>
      <td mat-cell label="Actions" class="action-cell" *matCellDef="let order">
        <button nzTooltipTitle="Track" nzTooltipPlacement="top" nzShape="circle" nz-button nzType="link" nz-tooltip [routerLink]="['/dashboard', { outlets: { dashboard: ['track', order?.id] } }]"><fa-icon icon="truck-moving"></fa-icon></button>
        <button nzTooltipTitle="Pay" nzTooltipPlacement="top" nzShape="circle" nz-button nzType="link" nz-tooltip [routerLink]="['/dashboard', { outlets: { dashboard: ['payment', order?.id] } }]"><fa-icon icon="money-bill"></fa-icon></button>
        <button nzTooltipTitle="Provenance" nzTooltipPlacement="top" nzShape="circle" nz-button nzType="link" nz-tooltip [routerLink]="['/dashboard', { outlets: { dashboard: ['provenance', order?.id] } }]"><fa-icon icon="tree"></fa-icon></button>
        <ng-template [ngIf]="user?.subRole?.type !== 'USER'">
          <button nzTooltipTitle="Linked Orders" nzTooltipPlacement="top" nzShape="circle" nz-button nzType="link" nz-tooltip [routerLink]="['/dashboard', { outlets: { dashboard: ['linked-orders', order?.id] } }]"><fa-icon icon="link"></fa-icon></button>
        </ng-template>
        <!-- <ng-template [ngIf]="user?.role?.type === 'SUPPLIER'">
          <button nzTooltipTitle="Upload Compliance Documents" nzTooltipPlacement="top" nzShape="circle" nz-button nzType="link" nz-tooltip><fa-icon icon="file-upload" (click)="openUploadDocumentDialog(order)"></fa-icon></button>
        </ng-template>
        <ng-template [ngIf]="user?.subRole?.type !== 'USER' && user?.role?.type !== 'SUPPLIER'">
          <button nzTooltipTitle="Download Compliance Documents" nzTooltipPlacement="top" nzShape="circle" nz-button nzType="link" nz-tooltip><fa-icon icon="file-download" (click)="openUploadedDocumentsDialog(order)"></fa-icon></button>
        </ng-template> -->
      </td>
    </ng-container>
  
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
</div>
