import { Component, OnInit, OnDestroy, ViewContainerRef, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';

import { MatTableDataSource } from '@angular/material/table';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Clipboard } from '@angular/cdk/clipboard';

import { ProjectService } from '@plasma/services/project.plasma';
import { AgentsService } from '@plasma/services/agents.plasma';
import { AuthService } from '@plasma/services/auth.plasma';
import { OrderService } from '@plasma/services/order.plasma';
import { StageService } from '@plasma/services/stage.plasma';

import { IOrder } from '@plasma/models/order';
import { ISupplier } from '@plasma/models/supplier';
import { IAgent } from '@plasma/models/agent';

import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { NzTableFilterFn, NzTableFilterList, NzTableSortFn, NzTableSortOrder } from 'ng-zorro-antd/table';

import { LoadingService } from '@plasma/components/loading/loading.service';
import { EmailService } from '@plasma/services/email.plasma';

import { CreateProjectModalComponent } from '@plasma/components/modal/create-project-modal.component';
import { InviteProjectOwnerModalComponent } from '@plasma/components/modal/invite-project-owner-modal.component';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import * as moment from 'moment';

export interface PeriodicElement {
  contract_no: string;
  account_no: string;
  project_cost_centre_code: string;
  order_no: string;
  project: string;
  stage: string;
  assets: string;
  agreed_price: string;
  destination: string;
  createdOn: string;
  status: string;
  actions: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
  {
    contract_no: 'MA0001',
    account_no: 'GALL023',
    project_cost_centre_code: 'PCC01',
    order_no: 'PO01',
    project: 'School Construction',
    stage: 'Construction Material',
    assets: '1',
    agreed_price: '£5,000.00',
    destination: 'London',
    createdOn: '2022-08-16, 10:31 AM',
    status: '',
    actions: '',
  },
  {
    contract_no: 'MA0002',
    account_no: 'GALL02',
    project_cost_centre_code: 'PCC02',
    order_no: 'PO02',
    project: 'Bricks',
    stage: 'Bricks Acquisition',
    assets: '1',
    agreed_price: '£1,500.00',
    destination: 'London',
    createdOn: '2022-08-16, 10:33 AM',
    status: '',
    actions: '',
  }
];


@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit, OnDestroy {

  private destroy$ = new Subject<void>();

  projects: any[];
  user: IAgent;
  userList: IAgent[];
  showLoading = false;
  selectedTab = 0;
  orders = [];
  stages: any[];

  projectListOfFilters = [
    {text: 'School Construction', value: 'School Construction'},
    {text: 'Bricks', value: 'Bricks'}
  ];

  stageListOfFilters = [
    {text: 'Construction Material', value: 'Construction Material'},
    {text: 'Bricks Acquisition', value: 'Bricks Acquisition'}
  ];

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  displayedColumns: string[] = ['contract_no', 'account_no', 'project_cost_centre_code', 'order_no', 'project', 'stage', 'assets', 'agreed_price', 'destination', 'createdOn', 'status', 'actions'];
  dataSource = null;
  disableStage = true;

  filterProjectId = null;
  filterStageId = null;

  constructor(private projectService: ProjectService,
              private authService: AuthService,
              private agentService: AgentsService,
              private emailService: EmailService,
              private orderService: OrderService,
              private stageService: StageService,
              private message: NzMessageService,
              private modal: NzModalService,
              private clipboard: Clipboard,
              private viewContainerRef: ViewContainerRef,
              private router: Router,
              private loadingService: LoadingService) { }

  ngOnInit(): void {

    this.authService.getUser()
      .pipe(takeUntil(this.destroy$))
      .subscribe((user: IAgent) => {
        this.loadingService.start();
        this.user = user;
        if (user?.subRole?.type === 'USER') {
          this.loadProjects();
        } else {
          this.loadProjectOwnerProjects();
        }
        this.loadOrders();
        this.loadUsers();
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  loadProjects(): void {
    this.showLoading = true;
    this.projectService.getAll(this.user?.account?.id, this.user?.publicKey)
      .subscribe((projects) => {
        this.projects = projects;
        this.showLoading = false;
        this.loadingService.complete();
      }, (error) => {
        this.loadingService.complete();
        this.showLoading = false;
        console.error('Error', error);
        this.message.create('error', `Error: ${error.error}`);
    });
  }

  loadProjectOwnerProjects(): void {
    this.projectService.getAll(this.user?.account?.id, null, this.user?.publicKey)
      .pipe(takeUntil(this.destroy$))
      .subscribe((projects) => {
        this.projects = projects;
        console.log('projects', projects);
        this.loadingService.complete();
      }, (error) => {
        this.loadingService.complete();
        console.error('Error', error);
        this.message.create('error', `Error: ${error.error}`);
    });
  }

  loadUsers(): void {
    if (!this.user.account.id) {
      return;
    }

    this.agentService.getByAccountAndSubRole(this.user.account.id, 'PROJECT_OWNER')
      .subscribe((agents) => {
        agents.push(this.user);
        this.userList = agents;
        console.log('agents', this.userList);
      }, (error) => {
        console.error('Error', error.error.error);
    });
  }

  loadOrders(): void {
    this.orderService.getByAccount(this.user.account.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe((orders) => {
        this.orders = orders;
        console.log('orders', orders);
        this.dataSource = orders;
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
        console.log('orders', orders);
      }, (error) => {
        console.error('Error', error.error.error);
      });
  }

  loadStages(projectId) {
    this.stageService.getByProject(projectId)
      .subscribe((stages) => {
        this.stages = stages;
        console.log('stages', stages);
      }, (error) => {
        console.error('Error', error.error.error);
      });
  }

  delete(id) {
    this.projectService.delete(id)
      .subscribe((resp) => console.log('resp', resp),
                 (error) => console.error('Error', error));
  }

  createModal() {
    const modal = this.modal.create({
      nzTitle: 'Create Project',
      nzContent: CreateProjectModalComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzMaskClosable: true,
      nzClosable: true,
      nzWidth: 'max-content',
      nzComponentParams: {
        user: this.user,
        userList: this.userList
      },
      nzOnOk: () => new Promise(resolve => setTimeout(resolve, 1000)),
      nzFooter: [
        {
          label: 'Create Project',
          onClick: componentInstance => {
            componentInstance.createProject();
          }
        }
      ]
    });

    const instance = modal.getContentComponent();
    modal.afterClose.subscribe(result => this.createProject(result));
  }

  createProject(project: any) {
    console.log('project', project);
    if (!project) {
      return;
    }
    this.loadingService.start();
    this.projectService.create(project)
      .subscribe((resp) => {
        console.log('resp', resp);
        this.loadingService.complete();
        this.message.create('success', `Project was created successfully!`);
        this.loadProjectOwnerProjects();
      }, (error) => {
        this.loadingService.complete();
        console.error('Error', error);
        this.message.create('error', `Error ${error.error.error}`);
    });
  }

  createInviteProjectOwnerModal() {
    const modal = this.modal.create({
      nzTitle: 'Invite Project Owner',
      nzContent: InviteProjectOwnerModalComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzMaskClosable: true,
      nzClosable: true,
      nzBodyStyle: {padding: '10px'},
      nzComponentParams: {
        user: this.user,
      },
      nzOnOk: () => new Promise(resolve => setTimeout(resolve, 1000)),
      nzFooter: null
    });

    const instance = modal.getContentComponent();
    modal.afterClose.subscribe(result => this.inviteProjectOwner(result));
  }

  inviteProjectOwner(email: any) {
    if (!email) {
      return;
    }

    const body = {
      receiver: email.inviteUser,
      subject: `Invitation to Register`
    };

    this.loadingService.start();
    this.emailService.inviteProjectOwner(body)
      .subscribe((resp) => {
        this.loadingService.complete();
        this.message.create('success', `Invite to ${email.inviteUser} sent successfully!`);
      }, (error) => {
        this.loadingService.complete();
        console.error('Error', error);
        this.message.create('error', `Error ${error.error.error}`);
      });

  }
  

  calculateAssetStatus(deliveryDate, floatDays) {
    if (deliveryDate === 0) {
      return;
    }

    const criticalDate = moment(deliveryDate).add(floatDays, 'days');
    if (moment().isSameOrBefore(deliveryDate)) {
      return 0;
    } else if (moment().isAfter(deliveryDate) && moment().isSameOrBefore(criticalDate)) {
      return 1;
    } else if (moment().isAfter(criticalDate)) {
      return 2;
    }
  }

  calculateStatus(assets: any[]) {
    if (!assets) {
      return;
    }
    const statusVal = assets.map((asset) =>
      this.calculateAssetStatus(asset.planned_dates.planned_delivery_date, asset.float_days))
      .reduce((max, status) => (status > max ? status : max), 0);

    switch (statusVal) {
      case 0:
        return 'green';
        break;
      case 1:
        return 'yellow';
        break;
      case 2:
        return 'red';
        break;

      default:
        break;
    }
  }

  projectSort(a: any, b: any) {
    console.log('a', a);
    return a.project.name.localCompare(b.project.name);
  }

  projectFilter(name: string, item: any) {
    return item.project.name.indexOf(name) !== -1;
  }

  stageSort(a: any, b: any) {
    console.log('a', a);
    return a.stage.name.localCompare(b.stage.name);
  }

  stageFilter(name: string, item: any) {
    return item.stage.name.indexOf(name) !== -1;
  }

  applyFilter(event: Event) {
    console.log('applyFilter')
    const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();
    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    }
  }

  updateProject(event) {
    console.log('event', event);
    this.disableStage = false;
    this.filterProjectId = event.value;
    this.filterStageId = 'all';
    this.loadStages(event.value);
  }

  updateStage(event) {
    console.log('event', event);
    this.filterStageId = event.value;
  }

  resetOrders() {
    this.filterProjectId = null;
    this.filterStageId = null;
    this.filterOrders();
  }

  filterOrders() {
    console.log('filterProjectId', this.filterProjectId, 'filterStageId', this.filterStageId);
    if ((!this.filterProjectId && !this.filterStageId) || (this.filterProjectId === 'all')) {
      this.dataSource = this.orders;
      return;
    }

    let filteredOrders = this.orders.filter((order) => {
      if (this.filterStageId !== 'all') {
        if (order['project']['id'] === this.filterProjectId && order['stage']['id'] === this.filterStageId) {
          return order;
        }
      } else {
        if (order['project']['id'] === this.filterProjectId) {
          return order;
        }
      }

    });
    this.dataSource = filteredOrders;
  }

  copyToClipboard(text: string) {
    if (!text) {
      return;
    }
    this.clipboard.copy(text);
  }

  navigateToProjectStatus() {
    if (!this.filterProjectId || this.filterProjectId === 'all') {
      return;
    }
    this.router.navigate(
      ['/dashboard', { outlets: { dashboard: ['project-status'] } }], { queryParams: {projectId: this.filterProjectId} });
  }

}
