import { Component, OnInit } from '@angular/core';

import { AssetService } from '@plasma/services/asset.plasma';
import { AuthService } from '@plasma/services/auth.plasma';

import { IAsset } from '@plasma/models/asset';
import { IAgent } from '@plasma/models/agent';
// import { ISupplier } from '@plasma/models/supplier';

import { NzMessageService } from 'ng-zorro-antd/message';


@Component({
  selector: 'app-assets',
  templateUrl: './assets.component.html',
  styleUrls: ['./assets.component.scss']
})
export class AssetsComponent implements OnInit {

  assets: any[];
  showLoading: boolean = false;

  constructor(private assetService: AssetService,
    private authService: AuthService,
    private message: NzMessageService) { }

  ngOnInit(): void {

    this.authService.getUser()
      .subscribe((user: IAgent) => {
        this.showLoading = true;
        if (user.role.type === 'SUPPLIER') {
          this.assetService.getBySupplier(user.publicKey)
            .subscribe((assets) => {
              this.assets = assets;
              this.showLoading = false;
            }, (error) => {
              this.showLoading = false;
              console.error('Error', error);
              this.message.create('error', `Error: ${error.error}`);
            });
        } else {
          this.assetService.getAll()
            .subscribe((assets) => {
              this.assets = assets;
              this.showLoading = false;
            }, (error) => {
              this.showLoading = false;
              console.error('Error', error);
              this.message.create('error', `Error: ${error.error}`);
            });
        }
      });

  }

  delete(id) {
    this.assetService.delete(id)
      .subscribe((resp) => console.log('resp', resp),
                 (error) => console.error('Error', error));
  }

}
