import { AfterViewInit, Component, OnInit, OnDestroy, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { FormBuilder, FormControl, FormArray, FormGroup, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import { InventoryService } from '@plasma/services/inventory.plasma';
import { AuthService } from '@plasma/services/auth.plasma';
import { OrderService } from '@plasma/services/order.plasma';

import { IAgent } from '@plasma/models/agent';
import { IInventory } from '@plasma/models/inventory';

import { NzMessageService } from 'ng-zorro-antd/message';

import { LoadingService } from '@plasma/components/loading/loading.service';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import * as moment from 'moment';


@Component({
  selector: 'app-edit-inventory',
  templateUrl: './edit-inventory.component.html',
  styleUrls: ['./edit-inventory.component.scss']
})
export class EditInventoryComponent implements OnInit, OnDestroy {

  id: string;
  user: IAgent;
  inventory: FormGroup;
  private parentProductsValidators = null;
  private destroy$ = new Subject<void>();
  formattedaddress=" ";

  constructor(private fb: FormBuilder,
              private orderService: OrderService,  
              protected router: Router,
              protected activatedRoute: ActivatedRoute,
              private inventoryService: InventoryService,
              private authService: AuthService,
              private loadingService: LoadingService,
              private message: NzMessageService,
              private location: Location) { }

  ngOnInit(): void {
    this.id = this.activatedRoute.snapshot.paramMap.get('id');
    if (!this.id) {
      return;
    }

    this.authService.getUser()
      .pipe(takeUntil(this.destroy$))
      .subscribe((user: IAgent) => {
        this.user = user;
        this.loadingService.complete();
    }, error => { console.error(error); this.loadingService.complete(); });

    this.inventory = this.fb.group({
      account: [this.user.account.id, [Validators.required]],
      productName: [null, [Validators.required]],
      quantity: [null, [Validators.required]],
      company: [this.user.account.name, [Validators.required]],
      companyAddress: this.fb.group({
        address: [null, [Validators.required]],
        lat: [null, [Validators.required]],
        lng: [null, [Validators.required]]
      }),
      internalPartNumber: [null],
      itemCountForProductionRun: [null],
      originOfManufacturer: this.fb.group({
        address: [null, [Validators.required]],
        lat: [null, [Validators.required]],
        lng: [null, [Validators.required]]
      }),
      manufactureDate: [null],
      dimensionsOfProduct: [null],
      associatedCode: [null, [Validators.required]],
      sku: [null],
      inventoryType: [null],
      productionProcessDetails: [null, [Validators.required]],
      materialsUsed: [null, [Validators.required]],
      isoCertificationNumber: [null],
      qualityAssurance: [null],
      safetySheetHyperlinks: [null],
      websiteLinks: [null],
      applicableLaws: [null, [Validators.required]],
      parentProducts: [null, this.parentProductsValidators],
      isRawMaterial: [false]
    });

    this.onChanges();
    this.loadInventoryItem();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onChanges() {
    this.inventory.get('isRawMaterial').valueChanges
      .subscribe((value) => {
        if (value) {
          this.inventory.get('parentProducts').setValidators(this.parentProductsValidators);
        } else {
          this.inventory.get('parentProducts').setValidators(this.parentProductsValidators ? this.parentProductsValidators.concat(Validators.required) : [Validators.required]);
        }
      });
  }

  loadInventoryItem() {
    if (!this.id) {
      return;
    }

    this.inventoryService.get(this.id)
      .subscribe((inventory) => {
        console.log('invnetory', inventory);
        this.inventory.patchValue(inventory);
      }, (error) => {
        console.error('Error', error);
        this.message.create('error', `Error: ${error.error.error}`);
      });
  }

  onCompanyAddressChange(address) {
    const lat = address.geometry.location.lat();
    const lng = address.geometry.location.lng();
    this.inventory.get('companyAddress').get('lat').setValue(lat);
    this.inventory.get('companyAddress').get('lng').setValue(lng);
  }

  onOriginOfManufacturerChange(address) {
    const lat = address.geometry.location.lat();
    const lng = address.geometry.location.lng();
    this.inventory.get('originOfManufacturer').get('lat').setValue(lat);
    this.inventory.get('originOfManufacturer').get('lng').setValue(lng);
  }

  onSubmit({value, valid}: {value: any, valid: boolean}) {
    console.log('value', value);
    if (!valid) {
      return;
    }

    value['updatedOn'] = moment().valueOf();
    value['account'] = value['account']['id'];

    this.loadingService.start();
    this.inventoryService.update(this.id, value)
      .subscribe((resp) => {
        console.log('resp', resp);
        this.loadingService.complete();
        this.message.create('success', `Item was updated successfully!`);
      }, (error) => {
        this.loadingService.complete();
        console.error('Error', error);
        // this.message.create('error', `Error: ${error.error.error}`);
      });
  }

  onBack() {
    this.location.back();
  }

}
