<nz-page-header class="site-page-header" (nzBack)="onBack()" nzBackIcon>
  <nz-page-header-title style="color: #046888;"><strong>Requisition</strong></nz-page-header-title>
</nz-page-header>

<ng-container>
  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between center">
    <div fxLayoutAlign="center center" id="field-container">
      <mat-form-field appearance="fill" fxFlexFill>
        <mat-label>Contract No</mat-label>
        <input matInput readonly [ngModel]="order?.contract_no">
      </mat-form-field>
    </div>
    
    <div fxLayoutAlign="center center" id="field-container">
      <mat-form-field appearance="fill" fxFlexFill>
        <mat-label>Account No</mat-label>
        <input matInput readonly [ngModel]="order?.account_no">
      </mat-form-field>
    </div>
    
    <div fxLayoutAlign="center center" id="field-container">
      <mat-form-field appearance="fill" fxFlexFill>
        <mat-label>Project Cost Centre Code</mat-label>
        <input matInput readonly [ngModel]="order?.project_cost_centre_code">
      </mat-form-field>
    </div>
  </div>

  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between center">
    <div fxLayoutAlign="center center" id="field-container">
      <mat-form-field appearance="fill" fxFlexFill>
        <mat-label>Order No</mat-label>
        <input matInput readonly [ngModel]="order?.order_no">
      </mat-form-field>
    </div>
    
    <div fxLayoutAlign="center center" id="field-container">
      <mat-form-field appearance="fill" fxFlexFill>
        <mat-label>Confirmation Order</mat-label>
        <input matInput readonly [ngModel]="order?.confirmation_order">
      </mat-form-field>
    </div>

    <div fxLayoutAlign="center center" id="field-container">
      <mat-form-field appearance="fill" fxFlexFill>
        <mat-label>ID</mat-label>
        <input matInput readonly [ngModel]="order?.bim">
      </mat-form-field>
    </div>
  </div>

  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between center">
    <div fxLayoutAlign="center center" id="field-container">
      <mat-form-field appearance="fill" fxFlexFill>
        <mat-label>Supervisor</mat-label>
        <input matInput readonly [ngModel]="order?.supervisor?.name">
      </mat-form-field>
    </div>
    
    <div fxLayoutAlign="center center" id="field-container">
      <mat-form-field appearance="fill" fxFlexFill>
        <mat-label>Created On</mat-label>
        <input matInput readonly [value]="order?.createdOn | date: 'yyyy-MM-dd, h:mm a'">
      </mat-form-field>
    </div>
    
    <div fxLayoutAlign="center center" id="field-container">
      <mat-form-field appearance="fill" fxFlexFill>
        <mat-label>Updated On</mat-label>
        <input matInput readonly [ngModel]="order?.updatedOn | date: 'yyyy-MM-dd, h:mm a'">
      </mat-form-field>
    </div>
  </div>

  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between center">
    <div fxLayoutAlign="center center" id="field-container">
      <mat-form-field appearance="fill" fxFlexFill>
        <mat-label>Agreed Price</mat-label>
        <span matPrefix>&pound;</span>
        <input matInput readonly [ngModel]="order?.agreed_price | number: '1.0-3'">
      </mat-form-field>
    </div>
    
    <div fxLayoutAlign="center center" id="field-container">
      <mat-form-field appearance="fill" fxFlexFill>
        <mat-label>Destination</mat-label>
        <input matInput readonly [ngModel]="order?.destination">
      </mat-form-field>
    </div>

    <div fxLayoutAlign="center center" id="field-container">
      <mat-form-field appearance="fill" fxFlexFill>
        <mat-label>Payment Terms</mat-label>
        <input matInput readonly [ngModel]="order?.payment_terms">
      </mat-form-field>
    </div>
  </div>

  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between center">
    <div fxLayoutAlign="center center" id="field-container">
      <mat-form-field appearance="fill" fxFlexFill>
        <mat-label>Shipment Method Dates</mat-label>
        <input matInput readonly [ngModel]="order?.shipment_method_dates">
      </mat-form-field>
    </div>
    
    <div fxLayoutAlign="center center" id="field-container">
      <mat-form-field appearance="fill" fxFlexFill>
        <mat-label>Technical Details</mat-label>
        <input matInput readonly [ngModel]="order?.technical_details">
      </mat-form-field>
    </div>

    <div fxLayoutAlign="center center" id="field-container">
      <mat-form-field appearance="fill" fxFlexFill>
        <mat-label>Special Instructions</mat-label>
        <input matInput readonly [ngModel]="order?.special_instructions">
      </mat-form-field>
    </div>
  </div>

  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between center">
    <div fxLayoutAlign="center center" id="field-container">
      <mat-form-field appearance="fill" fxFlexFill>
        <mat-label>SKU</mat-label>
        <input matInput readonly [ngModel]="order?.sku">
      </mat-form-field>
    </div>

    <div fxLayoutAlign="center center" id="field-container">
      <mat-form-field appearance="fill" fxFlexFill>
        <mat-label>Description</mat-label>
        <input matInput readonly [ngModel]="order?.description">
      </mat-form-field>
    </div>

    <div fxLayoutAlign="center center" id="field-container">
      <mat-form-field appearance="fill" fxFlexFill>
        <mat-label>Billing Address</mat-label>
        <input matInput readonly [ngModel]="order?.billing_address">
      </mat-form-field>
    </div>
  </div>
</ng-container>

<div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between center">
  <div fxLayoutAlign="start center" id="field-container">
    <mat-checkbox
      labelPosition="before"
      [(ngModel)]="checked"
      (ngModelChange)="onChange($event)">
      Mark Complete
    </mat-checkbox>
  </div>
</div>
