import {
  Routes
} from '@angular/router';

// import { AccountComponent } from './account/account.component';
// import { ProfileComponent } from './profile/profile.component';
// import { OrdersComponent } from './orders/orders.component';
import { OrdersComponent } from './containers/orders/orders.component';
import { OrderComponent } from './containers/order/order.component';
import { ProfileComponent } from './containers/profile/profile.component';


export const routes: Routes = [
  {
    path: '',
    redirectTo: 'orders',
    pathMatch: 'full'
  },
  {
    path: 'orders',
    component: OrdersComponent,
    outlet: 'dashboard'
  },
  {
    path: 'order/:id',
    component: OrderComponent,
    outlet: 'dashboard'
  },
  {
    path: 'profile',
    component: ProfileComponent,
    outlet: 'dashboard'
  }
];

