<nz-layout class="app-layout">
  <nz-sider class="menu-sidebar"
            nzCollapsible
            nzWidth="256px"
            nzBreakpoint="md"
            [(nzCollapsed)]="isCollapsed"
            [nzTrigger]="null">
    <div class="sidebar-logo">
      <a href="https://ng.ant.design/" target="_blank">
        <img src="https://ng.ant.design/assets/img/logo.svg" alt="logo">
        <h1>PLASMA</h1>
      </a>
    </div>
    <ul nz-menu nzTheme="dark" nzMode="inline" [nzInlineCollapsed]="isCollapsed">
      <li nz-submenu nzOpen nzTitle="Dashboard" nzIcon="dashboard">
        <ul>
          <li nz-menu-item nzMatchRouter>
            <a routerLink="/dashboard">VINCI</a>
          </li>
          <li nz-menu-item nzMatchRouter>
            <a routerLink="/supplier-a">Supplier A</a>
          </li>
          <li nz-menu-item nzMatchRouter>
            <a routerLink="/supplier-b">Supplier B</a>
          </li>
          <li nz-menu-item nzMatchRouter>
            <a routerLink="/supplier-c">Supplier C</a>
          </li>
        </ul>
      </li>
      <!-- <li nz-submenu nzOpen nzTitle="Form" nzIcon="form">
        <ul>
          <li nz-menu-item nzMatchRouter>
            <a>Basic Form</a>
          </li>
        </ul>
      </li> -->
    </ul>
  </nz-sider>
  <nz-layout>
    <nz-header>
      <div class="app-header">
        <span class="header-trigger" (click)="isCollapsed = !isCollapsed">
            <i class="trigger"
               nz-icon
               [nzType]="isCollapsed ? 'menu-unfold' : 'menu-fold'"
            ></i>
        </span>
      </div>
    </nz-header>
    <nz-content>
      <div class="inner-content">
        <!-- <router-outlet name="dashboard" ></router-outlet> -->
        <h3>No Order!</h3>
      </div>
    </nz-content>
  </nz-layout>
</nz-layout>
