<nz-page-header class="site-page-header" (nzBack)="onBack()" nzBackIcon nzTitle="Create Order"></nz-page-header>
<nz-divider nzText="Order Info" nzOrientation="left"></nz-divider>
<!-- <div class="form-row">
  <div class="col-md-5">
    <div class="form-group row">
      <label for="projectId" class="col-sm-4 col-form-label">Project ID</label>
      <div class="col-sm-8">
        <input type="text" [ngModel]="project?.projectId" disabled class="form-control form-control-sm" id="projectId">
      </div>
    </div>
  </div>
</div> -->
<!-- <div fxLayout="row" fxLayoutAlign="start center">
  <div fxLayoutAlign="center center" id="field-container">
    <mat-form-field appearance="fill" fxFlexFill>
      <mat-label>Project ID</mat-label>
      <input type="text" matInput [ngModel]="project?.projectId" disabled>
    </mat-form-field>
  </div>
</div> -->
<form novalidate [formGroup]="order" (ngSubmit)="onSubmit(order)">
  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between center">
    <div fxLayoutAlign="center center" id="field-container">
      <mat-form-field appearance="fill" fxFlexFill>
        <mat-label>Contract No</mat-label>
        <input matInput required formControlName="contractNo">
        <mat-error *ngIf="order.get('contractNo').invalid">The input is not a valid Contract No!</mat-error>
      </mat-form-field>
    </div>
    
    <div fxLayoutAlign="center center" id="field-container">
      <mat-form-field appearance="fill" fxFlexFill>
        <mat-label>Account No</mat-label>
        <input matInput required formControlName="accountNo">
        <mat-error *ngIf="order.get('accountNo').invalid">The input is not a valid Account No!</mat-error>
      </mat-form-field>
    </div>
    
    <div fxLayoutAlign="center center" id="field-container">
      <mat-form-field appearance="fill" fxFlexFill>
        <mat-label>Project Cost Centre Code</mat-label>
        <input matInput required formControlName="projectCostCentreCode">
        <mat-error *ngIf="order.get('projectCostCentreCode').invalid">The input is not a valid Project Cost Centre Code!</mat-error>
      </mat-form-field>
    </div>
  </div>

  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between center">
    <div fxLayoutAlign="center center" id="field-container">
      <mat-form-field appearance="fill" fxFlexFill>
        <mat-label>Purchase Order Reference No</mat-label>
        <input matInput required formControlName="orderNo">
        <mat-error *ngIf="order.get('orderNo').invalid">The input is not a valid Order Reference No!</mat-error>
      </mat-form-field>
    </div>

    <div fxLayoutAlign="center center" id="field-container">
      <mat-form-field appearance="fill" fxFlexFill>
        <mat-label>Confirmation Order</mat-label>
        <input matInput required formControlName="confirmationOrder">
        <mat-error *ngIf="order.get('confirmationOrder').invalid">The input is not a valid Confirmation Order!</mat-error>
      </mat-form-field>
    </div>
    
    <div fxLayoutAlign="center center" id="field-container">
      <mat-checkbox formControlName="hasLinkedOrders">Link Orders</mat-checkbox>
      <!-- <mat-form-field appearance="fill" fxFlexFill>
        <mat-label>Link Orders</mat-label>
        
        <input matInput hidden>
      </mat-form-field> -->
    </div>
    
  </div>

  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between center">
    <div fxLayoutAlign="center center" id="field-container">
      <mat-form-field appearance="fill" fxFlexFill>
        <mat-label>Supplier</mat-label>
        <mat-select required formControlName="supplier">
          <mat-option value="PLEASE_SELECT">Please Select</mat-option>
          <mat-option *ngFor="let supplier of suppliers" [value]="supplier?.publicKey">
            {{supplier?.name}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="order.get('supplier').invalid">Please select a Supplier!</mat-error>
      </mat-form-field>
    </div>
    
    <div fxLayoutAlign="center center" id="field-container">
      <mat-form-field appearance="fill" fxFlexFill>
        <mat-label>ID</mat-label>
        <input matInput formControlName="bim">
        <mat-error *ngIf="order.get('bim').invalid">The input is not a valid ID!</mat-error>
      </mat-form-field>
    </div>
    
    <div fxLayoutAlign="center center" id="field-container">
      <mat-form-field appearance="fill" fxFlexFill>
        <mat-label>Project</mat-label>
        <mat-select formControlName="project">
          <mat-option *ngFor="let project of projects" [value]="project?.id">
            {{project?.name}}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="order.get('project').invalid">Please select a Project!</mat-error>
      </mat-form-field>
    </div>
    
  </div>

  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between center">
    <ng-template [ngIf]="order.get('project').value">
      <div fxLayoutAlign="center center" id="field-container">
        <mat-form-field appearance="fill" fxFlexFill>
          <mat-label>Stage</mat-label>
          <mat-select formControlName="stage">
            <mat-option *ngFor="let stage of stages" [value]="stage?.id">
              {{stage?.name}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="order.get('stage').invalid">Please select a Stage!</mat-error>
        </mat-form-field>
      </div>
    </ng-template>

    <ng-template [ngIf]="order.get('stage').value">
      <div fxLayoutAlign="center center" id="field-container">
        <mat-form-field appearance="fill" fxFlexFill>
          <mat-label>Phase</mat-label>
          <mat-select required formControlName="phase">
            <mat-option *ngFor="let phase of phases" [value]="phase?.id">
              {{phase?.name}}
            </mat-option>
          </mat-select>
          <mat-error *ngIf="order.get('phase').invalid">Please select a Phase!</mat-error>
        </mat-form-field>
      </div>
    </ng-template>
  </div>

  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between center">
    <div fxLayoutAlign="center center" id="field-container">
      <mat-form-field appearance="fill" fxFlexFill>
        <mat-label>Total Price</mat-label>
        <input matInput formControlName="agreedPrice" readonly>
        <mat-error *ngIf="order.get('agreedPrice').invalid">The input is not a valid Price!</mat-error>
      </mat-form-field>
    </div>
    
    <ng-template [ngIf]="order.get('hasLinkedOrders').value">
      <div fxLayoutAlign="center center" id="field-container">
        <mat-form-field appearance="fill" fxFlexFill>
          <mat-label>Linked Orders</mat-label>
          <mat-chip-list #orderList aria-label="Linked Order selection" formControlName="linkedOrders">
            <mat-chip
              *ngFor="let orde of selectedOrders"
              (removed)="remove(orde)">
              {{orde.phase !== null ? (orde.order_no + '-' + orde.phase.name) : orde.order_no}}
              <button matChipRemove>
                <mat-icon>cancel</mat-icon>
              </button>
            </mat-chip>
            <input
              placeholder="Link Order"
              #linkedOrderInput
              [formControl]="linkedOrderCtrl"
              [matAutocomplete]="auto"
              [matChipInputFor]="orderList"
              [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
              (matChipInputTokenEnd)="linkOrder($event)">
          </mat-chip-list>
          <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selected($event)">
            <mat-option *ngFor="let ord of filteredOrders | async" [value]="ord.id">
              {{ord.order_no + '-' + ord.phase.name}}
            </mat-option>
          </mat-autocomplete>
          <mat-error *ngIf="order.get('linkedOrders').invalid">Please select an Order!</mat-error>
        </mat-form-field>
      </div>
    </ng-template>

    <div fxLayoutAlign="center center" id="field-container">
      <mat-checkbox formControlName="internalOrder">Internal</mat-checkbox>
    </div>
  </div>

  <nz-divider nzText="Items" nzOrientation="left"></nz-divider>

  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="end center">
      <div fxLayoutAlign="center center" id="field-container">
        <button mat-raised-button fxFlexFill style="color: white; background-color: #2196f3;" (click)="addItem($event)">Add Item</button>
      </div>
  </div>

  <ng-container formArrayName="assets" *ngFor="let item of order.get('assets')['controls']; let i = index;">
    <ng-container [formGroupName]="i">
      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between center">
        <div fxLayoutAlign="center center" id="field-container">
          <mat-form-field appearance="fill" fxFlexFill>
            <mat-label>Item Type</mat-label>
            <input matInput required formControlName="assetType">
            <!-- <mat-error *ngIf="order.get('assets')[i].get('assetType').invalid">Please enter an Item Type!</mat-error> -->
          </mat-form-field>
        </div>
        
        <div fxLayoutAlign="center center">
          <mat-checkbox formControlName="multipleItems" id="{{'multipleItems_'+i}}">Multiple Items</mat-checkbox>
        </div>

        <div fxLayoutAlign="center center" id="field-container">
          <!-- <button type="button" class="btn btn-primary btn-sm">Small button</button> -->
          <button nz-button nzType="primary" nzSize="small" nzShape="circle" (click)="removeItem($event, i)"><i nz-icon nzType="minus" nzTheme="outline"></i></button>
        </div>
      </div>

      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between center">
        <div fxLayoutAlign="center center" id="field-container">
          <mat-form-field appearance="fill" fxFlexFill>
            <mat-label>Quantity</mat-label>
            <input matInput required formControlName="quantity">
            <!-- <mat-error *ngIf="order.get('projectCostCentreCode').invalid">The input is not a valid Project Cost Centre Code!</mat-error> -->
          </mat-form-field>
        </div>


        <div fxLayoutAlign="center center" id="field-container">
          <mat-form-field appearance="fill" fxFlexFill>
            <mat-label>Price</mat-label>
            <input matInput required formControlName="price">
            <!-- <mat-error *ngIf="order.get('contractNo').invalid">The input is not a valid Contract No!</mat-error> -->
          </mat-form-field>
        </div>
        
        <ng-container formGroupName="plannedDates">
          <div fxLayoutAlign="center center" id="field-container">
            <mat-form-field appearance="fill" fxFlexFill>
              <mat-label>Delivery Date</mat-label>
              <input matInput required [matDatepicker]="plannedDeliveryDate" formControlName="plannedDeliveryDate">
              <mat-datepicker-toggle matSuffix [for]="plannedDeliveryDate"></mat-datepicker-toggle>
              <mat-datepicker #plannedDeliveryDate></mat-datepicker>
              <!-- <mat-error *ngIf="order.get('accountNo').invalid">The input is not a valid Account No!</mat-error> -->
            </mat-form-field>
          </div>
        </ng-container>
      </div>

      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between center">
        <div fxLayoutAlign="center center" id="field-container">
          <mat-form-field appearance="fill" fxFlexFill>
            <mat-label>UOQ</mat-label>
            <input matInput formControlName="uoq">
            <!-- <mat-error *ngIf="order.get('contractNo').invalid">The input is not a valid Contract No!</mat-error> -->
          </mat-form-field>
        </div>
        
        <div fxLayoutAlign="center center" id="field-container">
          <mat-form-field appearance="fill" fxFlexFill>
            <mat-label>Unit</mat-label>
            <input matInput formControlName="unit">
            <!-- <mat-error *ngIf="order.get('accountNo').invalid">The input is not a valid Account No!</mat-error> -->
          </mat-form-field>
        </div>
        
        <div fxLayoutAlign="center center" id="field-container">
          <mat-form-field appearance="fill" fxFlexFill>
            <mat-label>Discount</mat-label>
            <input type="number" matInput formControlName="discount">
            <!-- <mat-error *ngIf="order.get('projectCostCentreCode').invalid">The input is not a valid Project Cost Centre Code!</mat-error> -->
          </mat-form-field>
        </div>
      </div>

      <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between center">
        <div fxLayoutAlign="center center" id="field-container">
          <mat-form-field appearance="fill" fxFlexFill>
            <mat-label>Description</mat-label>
            <textarea matInput formControlName="description" rows="1"></textarea>
            <!-- <mat-error *ngIf="order.get('contractNo').invalid">The input is not a valid Contract No!</mat-error> -->
          </mat-form-field>
        </div>
        
        <div fxLayoutAlign="start center" id="field-container">
          <mat-form-field appearance="fill" fxFlexFill>
            <mat-label>Float Days</mat-label>
            <input type="number" min="0" matInput formControlName="floatDays">
            <!-- <mat-error *ngIf="order.get('accountNo').invalid">The input is not a valid Account No!</mat-error> -->
          </mat-form-field>
        </div>
      </div>

    </ng-container>
  </ng-container>

  <nz-divider></nz-divider>

  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between center">
    <div fxLayoutAlign="center center" id="field-container">
      <mat-form-field appearance="fill" fxFlexFill>
        <mat-label>Delivery Location</mat-label>
        <input matInput required placesAutocomplete formControlName="deliveryLocation" [matAutocomplete]="deliveryLocation">
        <mat-autocomplete #deliveryLocation="matAutocomplete">
          <mat-option *ngIf="areDeliveryLocationLoading" class="is-loading">Loading...</mat-option>
          <ng-container *ngIf="!areDeliveryLocationLoading">
            <mat-option *ngFor="let address of filteredDeliveryLocation" (onSelectionChange)="onDeliveryLocationSelected($event)" [value]="address.description">
              <span>{{address?.description}}</span>
            </mat-option>
          </ng-container>
        </mat-autocomplete>
        <mat-error *ngIf="order.get('deliveryLocation').invalid">The input is not a valid Delivery Location!</mat-error>
      </mat-form-field>
    </div>
    
    <div fxLayoutAlign="center center" id="field-container">
      <mat-form-field appearance="fill" fxFlexFill>
        <mat-label>Date of Completion</mat-label>
        <input matInput required [matDatepicker]="completionDate" formControlName="completionDate">
        <mat-datepicker-toggle matSuffix [for]="completionDate"></mat-datepicker-toggle>
        <mat-datepicker #completionDate></mat-datepicker>
        <mat-error *ngIf="order.get('completionDate').invalid">Please select Completion Date!</mat-error>
      </mat-form-field>
    </div>
    
    <div fxLayoutAlign="center center" id="field-container">
      <mat-form-field appearance="fill" fxFlexFill>
        <mat-label>Description</mat-label>
        <textarea matInput rows="1" formControlName="description"></textarea>
        <mat-error *ngIf="order.get('description').invalid">The input is not a valid Project Cost Centre Code!</mat-error>
      </mat-form-field>
    </div>
  </div>
  <!-- <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between end">
    <div fxLayoutAlign="center center" id="field-container"> -->
      <!-- <mat-form-field appearance="fill" fxFlexFill> -->
        <!-- <geoapify-geocoder-autocomplete></geoapify-geocoder-autocomplete> -->
        <!-- <app-auto-complete formControlName="deliveryLocation" label="Delivery Location" style="width:100%;"></app-auto-complete> -->
        <!-- <mat-error *ngIf="order.get('deliveryLocation').invalid">The input is not a valid Delivery Location!</mat-error> -->
      <!-- </mat-form-field> -->
    <!-- </div>
  </div> -->

  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between center">
    <div fxLayoutAlign="center center" id="field-container">
      <mat-form-field appearance="fill" fxFlexFill>
        <mat-label>Payment Terms</mat-label>
        <textarea matInput rows="1" formControlName="paymentTerms"></textarea>
        <mat-error *ngIf="order.get('paymentTerms').invalid">The input is not a valid Payment Terms!</mat-error>
      </mat-form-field>
    </div>
    
    <div fxLayoutAlign="center center" id="field-container">
      <mat-form-field appearance="fill" fxFlexFill>
        <mat-label>Shipment Method/Dates</mat-label>
        <textarea matInput rows="1" formControlName="shipmentMethodDates"></textarea>
        <mat-error *ngIf="order.get('shipmentMethodDates').invalid">The input is not a valid Shipment Method/Dates!</mat-error>
      </mat-form-field>
    </div>
    
    <div fxLayoutAlign="center center" id="field-container">
      <mat-form-field appearance="fill" fxFlexFill>
        <mat-label>Technical Details</mat-label>
        <textarea matInput rows="1" formControlName="technicalDetails"></textarea>
        <mat-error *ngIf="order.get('technicalDetails').invalid">The input is not a valid Technical Details!</mat-error>
      </mat-form-field>
    </div>
  </div>

  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between center">
    <div fxLayoutAlign="center center" id="field-container">
      <mat-form-field appearance="fill" fxFlexFill>
        <mat-label>Special Instructions</mat-label>
        <textarea matInput rows="1" formControlName="specialInstructions"></textarea>
        <mat-error *ngIf="order.get('specialInstructions').invalid">The input is not a valid Special Instructions!</mat-error>
      </mat-form-field>
    </div>
    
    <div fxLayoutAlign="center center" id="field-container">
      <mat-form-field appearance="fill" fxFlexFill>
        <mat-label>Any discounts to be applied</mat-label>
        <input type="number" matInput formControlName="discounts">
        <mat-error *ngIf="order.get('discounts').invalid">The input is not a valid input!</mat-error>
      </mat-form-field>
    </div>
    
    <div fxLayoutAlign="center center" id="field-container">
      <mat-form-field appearance="fill" fxFlexFill>
        <mat-label>Model Number/SKU</mat-label>
        <input matInput formControlName="sku">
        <mat-error *ngIf="order.get('sku').invalid">The input is not a valid Model Number/SKU!</mat-error>
      </mat-form-field>
    </div>
  </div>

  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between center">
    <div fxLayoutAlign="center center" id="field-container">
      <mat-form-field appearance="fill" fxFlexFill>
        <mat-label>Company Billing Address</mat-label>
        <textarea matInput rows="1" required formControlName="billingAddress"></textarea>
        <mat-error *ngIf="order.get('billingAddress').invalid">The input is not a valid Company Billing Address!</mat-error>
      </mat-form-field>
    </div>

    <div fxLayoutAlign="center center" id="field-container">
      <mat-checkbox formControlName="review">Review</mat-checkbox>
    </div>
  </div>

  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="end center">
    <div fxLayoutAlign="center center" id="field-container">
      <button type="submit" mat-raised-button fxFlexFill [disabled]="order.invalid" style="color: white; background-color: #2196f3;">Create Order</button>
    </div>
  </div>

  <!-- <div class="form-row">
    <div class="col-md-5">
      <div class="form-group row">
        <label for="contractNo" class="col-sm-4 col-form-label required">Contract No</label>
        <div class="col-sm-8">
          <input type="text" formControlName="contractNo" class="form-control form-control-sm" [ngClass]="{ 'is-invalid': (order.get('contractNo').dirty || order.get('contractNo').touched) && order.get('contractNo').errors }" id="contractNo">
          <div class="invalid-feedback" *ngIf="(order.get('contractNo').dirty || order.get('contractNo').touched) && order.get('contractNo').hasError('required')">
            The input is not valid Contract No!
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-5">
      <div class="form-group row">
        <label for="accountNo" class="col-sm-4 col-form-label required">Account No</label>
        <div class="col-sm-8">
          <input type="text" formControlName="accountNo" class="form-control form-control-sm" [ngClass]="{ 'is-invalid': (order.get('accountNo').dirty || order.get('accountNo').touched) && order.get('accountNo').errors }" id="accountNo">
          <div class="invalid-feedback" *ngIf="(order.get('accountNo').dirty || order.get('accountNo').touched) && order.get('accountNo').hasError('required')">
            The input is not valid Account No!
          </div>
        </div>
      </div>
    </div>
  </div> -->

  <!-- <div class="form-row">
    <div class="col-md-5">
      <div class="form-group row">
        <label for="projectCostCentreCode" class="col-sm-4 col-form-label required">Project Cost Centre Code</label>
        <div class="col-sm-8">
          <input type="text" formControlName="projectCostCentreCode" class="form-control form-control-sm" [ngClass]="{ 'is-invalid': (order.get('projectCostCentreCode').dirty || order.get('projectCostCentreCode').touched) && order.get('projectCostCentreCode').errors }" id="projectCostCentreCode">
          <div class="invalid-feedback" *ngIf="(order.get('projectCostCentreCode').dirty || order.get('projectCostCentreCode').touched) && order.get('projectCostCentreCode').hasError('required')">
            The input is not valid Project Cost Centre Code!
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-5">
      <div class="form-group row">
        <label for="orderNo" class="col-sm-4 col-form-label required">Purchase Order Reference No</label>
        <div class="col-sm-8">
          <input type="text" formControlName="orderNo" class="form-control form-control-sm" [ngClass]="{ 'is-invalid': (order.get('orderNo').dirty || order.get('orderNo').touched) && order.get('orderNo').errors }" id="orderNo">
          <div class="invalid-feedback" *ngIf="(order.get('orderNo').dirty || order.get('orderNo').touched) && order.get('orderNo').hasError('required')">
            The input is not valid Order Reference No!
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-2">
      <div class="form-group row">
        <div class="custom-control custom-checkbox">
          <div class="col-sm-12">
            <input type="checkbox" btnCheckbox class="custom-control-input" formControlName="hasLinkedOrders" id="hasLinkedOrders">
            <label class="custom-control-label" for="hasLinkedOrders">Link Orders</label>
          </div>
        </div>
      </div>
    </div>
  </div> -->

  <!-- <div class="form-row">
    <div class="col-md-5">
      <div class="form-group row">
        <label for="confirmationOrder" class="col-sm-4 col-form-label required">Confirmation Order</label>
        <div class="col-sm-8">
          <input type="text" formControlName="confirmationOrder" class="form-control form-control-sm" [ngClass]="{ 'is-invalid': (order.get('confirmationOrder').dirty || order.get('confirmationOrder').touched) && order.get('confirmationOrder').errors }" id="confirmationOrder">
          <div class="invalid-feedback" *ngIf="(order.get('confirmationOrder').dirty || order.get('confirmationOrder').touched) && order.get('confirmationOrder').hasError('required')">
            The input is not valid Confirmation Order!
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-5">
      <div class="form-group row">
        <label for="supplier" class="col-sm-4 col-form-label required">Supplier</label>
        <div class="col-sm-8">
          <select formControlName="supplier" class="form-control form-control-sm custom-select custom-select-sm" [ngClass]="{ 'is-invalid': (order.get('supplier').dirty || order.get('supplier').touched) && order.get('supplier').errors }" id="supplier">
            <option selected value="PLEASE_SELECT">Please Select</option>
            <option *ngFor="let supplier of suppliers" [value]="supplier?.publicKey">{{supplier?.name}}</option>
          </select>
          <div class="invalid-feedback" *ngIf="(order.get('supplier').dirty || order.get('supplier').touched) && order.get('supplier').hasError('required')">
            Please select a supplier!
          </div>
        </div>
      </div>
    </div>
  </div> -->

  <!-- <div class="form-row">
    <div class="col-md-5">
      <div class="form-group row">
        <label for="bim" class="col-sm-4 col-form-label required">BIM</label>
        <div class="col-sm-8">
          <input type="text" formControlName="bim" class="form-control form-control-sm" [ngClass]="{ 'is-invalid': (order.get('bim').dirty || order.get('bim').touched) && order.get('bim').errors }" id="bim">
          <div class="invalid-feedback" *ngIf="(order.get('bim').dirty || order.get('bim').touched) && order.get('bim').hasError('required')">
            The input is not valid BIM!
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-5">
      <div class="form-group row">
        <label for="phase" class="col-sm-4 col-form-label required">Phase</label>
        <div class="col-sm-8">
          <select formControlName="phase" class="form-control form-control-sm custom-select custom-select-sm" [ngClass]="{ 'is-invalid': (order.get('phase').dirty || order.get('phase').touched) && order.get('phase').errors }" id="phase">
            <option *ngFor="let phase of phases" [value]="phase?.id">{{phase?.name}}</option>
          </select>
          <div class="invalid-feedback" *ngIf="(order.get('phase').dirty || order.get('phase').touched) && order.get('phase').hasError('required')">
            Please select a Phase!
          </div>
        </div>
      </div>
    </div>
  </div> -->

  <!-- <div class="form-row">
    <div class="col-md-5">
      <div class="form-group row">
        <label for="agreedPrice" class="col-sm-4 col-form-label required">Total Price</label>
        <div class="col-sm-8 input-group input-group-sm">
          <div class="input-group-prepend">
            <div class="input-group-text input-group-height">£</div>
          </div>
          <input type="text" disabled formControlName="agreedPrice" class="form-control form-control-sm" [ngClass]="{ 'is-invalid': (order.get('agreedPrice').dirty || order.get('agreedPrice').touched) && order.get('agreedPrice').errors }" id="agreedPrice">
          <div class="invalid-feedback" *ngIf="(order.get('agreedPrice').dirty || order.get('agreedPrice').touched) && order.get('agreedPrice').hasError('required')">
            The input is not valid Price!
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-5">
      <div class="form-group row">
        <div class="custom-control custom-checkbox">
          <div class="col-sm-8">
            <input type="checkbox" btnCheckbox class="custom-control-input" formControlName="internalOrder" id="internalOrder">
            <label class="custom-control-label" for="internalOrder">Internal</label>
          </div>
        </div>
      </div>
    </div>
  </div> -->

  <!-- <ng-template [ngIf]="order.get('hasLinkedOrders').value">
    <div class="form-row">
      <div class="col-md-5">
        <div class="form-group row">
          <label for="linkedOrders" class="col-sm-4 col-form-label">Linked Orders</label>
          <div class="col-sm-8">
            <nz-select nzMode="multiple" formControlName="linkedOrders">
              <nz-option *ngFor="let order of orders" [nzLabel]="order.order_no+'-'+order.phase.name" [nzValue]="order.id" [nzHide]="!isNotSelected(order)"></nz-option>
            </nz-select>
            <div class="invalid-feedback" *ngIf="(order.get('linkedOrders').dirty || order.get('linkedOrders').touched) && order.get('linkedOrders').hasError('required')">
              Please select an Order!
            </div> -->

            <!-- <select formControlName="linkedOrders" class="form-control form-control-sm custom-select custom-select-sm" [ngClass]="{ 'is-invalid': (order.get('linkedOrders').dirty || order.get('linkedOrders').touched) && order.get('linkedOrders').errors }" id="linkedOrders">
              <option *ngFor="let order of orders" [value]="order?.id">{{order?.order_no}}</option>
            </select> -->

          <!-- </div>
        </div>
      </div>
    </div>
  </ng-template> -->

  <!-- <nz-divider nzText="Items" nzOrientation="left"></nz-divider>

  <div nz-row nzJustify="end" style="width: 100%;">
    <button nz-button nzType="primary" (click)="addItem($event)" nzSize="small">
      <i nz-icon nzType="plus"></i>Add Item</button>
  </div> -->

  <!-- <ng-container formArrayName="assets" *ngFor="let item of order.get('assets')['controls']; let i = index;">
    <ng-container [formGroupName]="i">

      <div class="form-row">
        <div class="col-md-5">
          <div class="form-group row">
            <label for="assetType" class="col-sm-4 col-form-label required">Item Type</label>
            <div class="col-sm-8">
              <input type="text" formControlName="assetType" class="form-control form-control-sm" id="assetType" />
            </div>
          </div>
        </div>
        <div class="col-md-5">
          <div class="form-group">
            <div class="custom-control custom-checkbox">
              <div class="col-sm-8">
                <input type="checkbox" btnCheckbox class="custom-control-input" formControlName="multipleItems" id="{{'multipleItems_'+i}}">
                <label class="custom-control-label" for="{{'multipleItems_'+i}}">Multiple Items</label>
              </div>
            </div>
          </div>
        </div>

        <div class="col-md-1"> -->
          <!-- <button type="button" class="btn btn-primary btn-sm">Small button</button> -->
          <!-- <button nz-button nzType="primary" nzSize="small" nzShape="circle" (click)="removeItem($event, i)"><i nz-icon nzType="minus" nzTheme="outline"></i></button>
        </div>
      </div>

      <div class="form-row">
        <div class="col-md-5">
          <div class="form-group row">
            <label for="quantity" class="col-sm-4 col-form-label required">Quantity</label>
            <div class="col-sm-8">
              <input type="text" formControlName="quantity" class="form-control form-control-sm" id="quantity">
            </div>
          </div>
        </div>
      </div> -->

      <!-- <div class="form-row">
        <div class="col-md-5">
          <div class="form-group row">
            <label for="price" class="col-sm-4 col-form-label required">Price</label>
            <div class="col-sm-8 input-group input-group-sm">
              <div class="input-group-prepend">
                <div class="input-group-text input-group-height input-group-height">£</div>
              </div>
              <input type="text" formControlName="price" class="form-control form-control-sm" id="price">
            </div>
          </div>
        </div>
        <div class="col-md-5" formGroupName="plannedDates">
          <div class="form-group row">
            <label for="plannedDeliveryDate" class="col-sm-4 col-form-label required">Delivery Date</label>
            <div class="col-sm-8">
              <input class="form-control form-control-sm" #datepickerYMD="bsDatepicker" bsDatepicker formControlName="plannedDeliveryDate" id="plannedDeliveryDate" [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD', containerClass: 'theme-dark-blue' }"> -->
              <!-- <div class="invalid-feedback" *ngIf="order.get('assets')['controls'][i].get('plannedDates').get('plannedDeliveryDate').hasError('required') && order.get('assets')['controls'][i].get('plannedDates').get('plannedDeliveryDate').touched">
                Please select Delivery Date!
              </div> -->
            <!-- </div>
          </div>
        </div>
      </div>

      <div class="form-row">
        <div class="col-md-5">
          <div class="form-group row">
            <label for="uoq" class="col-sm-4 col-form-label">UOQ</label>
            <div class="col-sm-8">
              <input type="text" formControlName="uoq" class="form-control form-control-sm" id="uoq">
            </div>
          </div>
        </div>
        <div class="col-md-5">
          <div class="form-group row">
            <label for="unit" class="col-sm-4 col-form-label">Unit</label>
            <div class="col-sm-8">
              <input type="text" formControlName="unit" class="form-control form-control-sm" id="unit">
            </div>
          </div>
        </div>
      </div>

      <div class="form-row">
        <div class="col-md-5">
          <div class="form-group row">
            <label for="discount" class="col-sm-4 col-form-label">Discount</label>
            <div class="col-sm-8">
              <input type="number" formControlName="discount" class="form-control form-control-sm" id="discount">
            </div>
          </div>
        </div>
        <div class="col-md-5">
          <div class="form-group row">
            <label for="description" class="col-sm-4 col-form-label">Description</label>
            <div class="col-sm-8">
              <textarea class="form-control form-control-sm" formControlName="description" id="description" rows="2"></textarea>
            </div>
          </div>
        </div>
      </div> -->

      <!-- <div class="form-row">
        <div class="col-md-5">
          <div class="form-group row">
            <label for="floatDays" class="col-sm-4 col-form-label">Float Days</label>
            <div class="col-sm-8">
              <input type="number" min="0" formControlName="floatDays" class="form-control form-control-sm" id="floatDays">
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </ng-container> -->

  <!-- <nz-divider></nz-divider> -->

  <!-- <div class="form-row">
    <div class="col-md-5">
      <div class="form-group row">
        <label for="deliveryLocation" class="col-sm-4 col-form-label required">Delivery Location</label>
        <div class="col-sm-8">
          <input type="text" formControlName="deliveryLocation" class="form-control form-control-sm" [ngClass]="{ 'is-invalid': (order.get('deliveryLocation').dirty || order.get('deliveryLocation').touched) && order.get('deliveryLocation').errors }" id="deliveryLocation">
          <div class="invalid-feedback" *ngIf="(order.get('deliveryLocation').dirty || order.get('deliveryLocation').touched) && order.get('deliveryLocation').hasError('required')">
            The input is not a valid Delivery Location!
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-5">
      <div class="form-group row">
        <label for="completionDate" class="col-sm-4 col-form-label required">Date of Completion</label>
        <div class="col-sm-8">
          <input class="form-control form-control-sm" [ngClass]="{ 'is-invalid': (order.get('completionDate').dirty || order.get('completionDate').touched) && order.get('completionDate').errors }" #datepickerYMD="bsDatepicker" bsDatepicker formControlName="completionDate" id="completionDate" [bsConfig]="{ dateInputFormat: 'YYYY-MM-DD', containerClass: 'theme-dark-blue' }">
          <div class="invalid-feedback" *ngIf="(order.get('completionDate').dirty || order.get('completionDate').touched) && order.get('completionDate').hasError('required')">
            Please select Completion Date!
          </div>
        </div>
      </div>
    </div>
  </div> -->

  <!-- <div class="form-row">
    <div class="col-md-5">
      <div class="form-group row">
        <label for="description" class="col-sm-4 col-form-label">Description</label>
        <div class="col-sm-8">
          <textarea class="form-control form-control-sm" formControlName="description" id="description" rows="2"></textarea>
        </div>
      </div>
    </div>
    <div class="col-md-5">
      <div class="form-group row">
        <label for="paymentTerms" class="col-sm-4 col-form-label">Payment Terms</label>
        <div class="col-sm-8">
          <textarea class="form-control form-control-sm" formControlName="paymentTerms" id="paymentTerms" rows="2"></textarea>
        </div>
      </div>
    </div>
  </div>

  <div class="form-row">
    <div class="col-md-5">
      <div class="form-group row">
        <label for="shipmentMethodDates" class="col-sm-4 col-form-label">Shipment method/dates</label>
        <div class="col-sm-8">
          <textarea class="form-control form-control-sm" formControlName="shipmentMethodDates" id="shipmentMethodDates" rows="2"></textarea>
        </div>
      </div>
    </div>
    <div class="col-md-5">
      <div class="form-group row">
        <label for="technicalDetails" class="col-sm-4 col-form-label">Technical Details</label>
        <div class="col-sm-8">
          <textarea class="form-control form-control-sm" formControlName="technicalDetails" id="technicalDetails" rows="2"></textarea>
        </div>
      </div>
    </div>
  </div> -->


  <!-- <div class="form-row">
    <div class="col-md-5">
      <div class="form-group row">
        <label for="specialInstructions" class="col-sm-4 col-form-label">Special Instructions</label>
        <div class="col-sm-8">
          <textarea class="form-control form-control-sm" formControlName="specialInstructions" id="specialInstructions" rows="2"></textarea>
        </div>
      </div>
    </div>
    <div class="col-md-5">
      <div class="form-group row">
        <label for="discounts" class="col-sm-4 col-form-label">Any discounts to be applied</label>
        <div class="col-sm-8">
          <input type="number" class="form-control form-control-sm" formControlName="discounts" id="discounts">
        </div>
      </div>
    </div>
  </div> -->

  <!-- <div class="form-row">
    <div class="col-md-5">
      <div class="form-group row">
        <label for="sku" class="col-sm-4 col-form-label">Model Number/SKU</label>
        <div class="col-sm-8">
          <input type="text" formControlName="sku" class="form-control form-control-sm" id="sku">
        </div>
      </div>
    </div>
    <div class="col-md-5">
      <div class="form-group row">
        <label for="billingAddress" class="col-sm-4 col-form-label required">Company billing address</label>
        <div class="col-sm-8">
          <textarea class="form-control form-control-sm" [ngClass]="{ 'is-invalid': (order.get('billingAddress').dirty || order.get('billingAddress').touched) && order.get('billingAddress').errors }" formControlName="billingAddress" id="billingAddress" rows="2"></textarea>
          <div class="invalid-feedback" *ngIf="(order.get('billingAddress').dirty || order.get('billingAddress').touched) && order.get('billingAddress').hasError('required')">
            Please enter Billing Address!
          </div>
        </div>
      </div>
    </div>
  </div> -->

  <!-- <div class="form-group"> -->
    <!-- <div class="form-check">
      <input class="form-check-input" type="checkbox" id="review">
      <label class="form-check-label" for="review">
        Review
      </label>
    </div> -->
    <!-- <nz-form-item>
      <nz-form-label nzFor="review">Review</nz-form-label>
      <nz-form-control [nzSm]="14" [nzXs]="24">
        <label nz-checkbox formControlName="review"></label>
      </nz-form-control>
    </nz-form-item>
  </div>
  <button type="submit" [disabled]="order.invalid" class="btn btn-primary btn-sm">Create Order</button> -->
  <!-- <button nz-button nzType="primary">Create Order</button> -->
<!-- </form> -->

<!-- <form nz-form [nzLayout]="'inline'" [formGroup]="validateForm" (ngSubmit)="submitForm(validateForm)">
  <nz-space nzDirection="vertical" style="width: 100%">
    <nz-space-item>
      <div nz-row>
        <div nz-col [nzSm]="11" [nzXs]="24">
          <nz-form-item>
            <nz-form-label nzRequired nzFor="contractNo">Contract No</nz-form-label>
            <nz-form-control nzOffset="1" [nzSm]="12" [nzXs]="24" nzErrorTip="The input is not valid Contract No!">
              <input nz-input formControlName="contractNo" id="contractNo" />
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSm]="12" [nzXs]="24">
          <nz-form-item>
            <nz-form-label nzRequired nzFor="accountNo">Account No</nz-form-label>
            <nz-form-control [nzSm]="12" [nzXs]="24" nzErrorTip="The input is not valid Account No!">
              <input nz-input formControlName="accountNo" id="accountNo" />
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
    </nz-space-item>
    <nz-space-item>
      <div nz-row>
        <div nz-col [nzSm]="11" [nzXs]="24">
          <nz-form-item>
            <nz-form-label  nzRequired nzFor="projectCostCentreCode">Project Cost Centre Code</nz-form-label>
            <nz-form-control [nzSm]="12" [nzXs]="24" nzErrorTip="The input is not valid Project Cost Centre Code!">
              <input nz-input formControlName="projectCostCentreCode" id="projectCostCentreCode" />
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSm]="12" [nzXs]="24">
          <nz-form-item>
            <nz-form-label nzRequired nzFor="orderNo">Purchase Order Reference No</nz-form-label>
            <nz-form-control [nzSm]="12" [nzXs]="24" nzErrorTip="The input is not valid Order Reference No!">
              <input nz-input formControlName="orderNo" id="orderNo" />
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
    </nz-space-item>
    <nz-space-item>
      <div nz-row>
        <div nz-col [nzSm]="11" [nzXs]="24">
          <nz-form-item>
            <nz-form-label nzRequired nzFor="confirmationOrder">Confirmation Order
          </nz-form-label>
            <nz-form-control [nzSm]="12" [nzXs]="24" nzErrorTip="The input is not valid Confirmation Order!">
              <input nz-input formControlName="confirmationOrder" id="confirmationOrder" />
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSm]="12" [nzXs]="24">
          <nz-form-item>
            <nz-form-label nzFor="supplier" nzRequired>Supplier</nz-form-label>
            <nz-form-control
              [nzValidateStatus]="validateForm.controls['supplier']"
              nzErrorTip="Please select your supplier!"
              [nzSm]="14" [nzXs]="24"
            >
              <nz-select formControlName="supplier">
                  <nz-option [nzLabel]="'Please Select'" [nzValue]="'PLEASE_SELECT'"></nz-option>
                  <nz-option *ngFor="let supp of suppliers" [nzLabel]="supp?.name" [nzValue]="supp?.publicKey"></nz-option>
              </nz-select>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSm]="8" [nzXs]="24">
          <nz-form-item>
            <nz-form-label nzRequired nzFor="quantity">Quantity</nz-form-label>
            <nz-form-control [nzSm]="12" [nzXs]="24" nzErrorTip="The input is not valid Quantity!">
              <input nz-input formControlName="quantity" id="quantity" />
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
    </nz-space-item>
    <nz-space-item>
      <div nz-row>
        <div nz-col [nzSm]="11" [nzXs]="24">
          <nz-form-item>
            <nz-form-label nzRequired nzFor="unitPrice">Unit Price</nz-form-label>
            <nz-form-control [nzSm]="12" [nzXs]="24" nzErrorTip="The input is not valid Price!">
              <nz-input-group nzPrefix="£">
                <input nz-input formControlName="unitPrice" id="unitPrice" />
              </nz-input-group>

            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSm]="12" [nzXs]="24">
          <nz-form-item>
            <nz-form-label nzRequired nzFor="agreedPrice">Agreed Price (Total)</nz-form-label>
            <nz-form-control [nzSm]="12" [nzXs]="24" nzErrorTip="The input is not valid Price!">
              <nz-input-group nzPrefix="£">
                <input nz-input formControlName="agreedPrice" id="agreedPrice" />
              </nz-input-group>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
    </nz-space-item>
    <nz-space-item>
      <div nz-row>
        <div nz-col [nzSm]="12" [nzXs]="24">
          <nz-form-item>
            <nz-form-label nzFor="items">Items</nz-form-label>
            <nz-form-control [nzSm]="12" [nzXs]="24">
              <input nz-input [disabled]="true" value="{{ validateForm.controls['assets']['length'] }} - &pound;{{ validateForm.controls['unitPrice'].value }}" id="items" />
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
    </nz-space-item>
  </nz-space>


  <nz-divider nzText="Items" nzOrientation="left"></nz-divider>
  <div nz-row nzJustify="end" style="width: 100%;">
    <button nz-button nzType="primary" (click)="addItem()" nzSize="small">
      <i nz-icon nzType="plus"></i>Add Item</button>
  </div>
    <nz-empty nzNotFoundContent="No Items!"></nz-empty>
  <ng-container formArrayName="assets" *ngFor="let item of validateForm.get('assets')['controls']; let i = index;">
    <ng-container [formGroupName]="i">
      <nz-space nzDirection="vertical" style="width: 100%">
        <nz-space-item>
          <div nz-row>
            <div nz-col [nzSm]="6" [nzXs]="24">
              <nz-form-item>
                <nz-form-label nzRequired nzFor="asset_type">Item Type</nz-form-label>
                <nz-form-control [nzSm]="12" [nzXs]="24" nzErrorTip="The input is not valid Item Type!">
                  <nz-select formControlName="asset_type" id="asset_type">
                    <nz-option [nzLabel]="'Concrete'" [nzValue]="'concrete'"></nz-option>
                    <nz-option [nzLabel]="'Limestone'" [nzValue]="'limestone'"></nz-option>
                    <nz-option [nzLabel]="'Reco Walls'" [nzValue]="'reco_walls'"></nz-option>
                    <nz-option [nzLabel]="'Door'" [nzValue]="'door'"></nz-option>
                  </nz-select>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSm]="5" [nzXs]="24">
              <nz-form-item>
                <nz-form-label nzRequired nzFor="quantity">Quantity</nz-form-label>
                <nz-form-control [nzSm]="12" [nzXs]="24" nzErrorTip="The input is not valid Quantity!">
                  <input nz-input formControlName="quantity" id="quantity" />
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSm]="5" [nzXs]="24">
              <nz-form-item>
                <nz-form-label nzRequired nzFor="price">Price</nz-form-label>
                <nz-form-control [nzSm]="12" [nzXs]="24" nzErrorTip="The input is not valid Price!">
                  <nz-input-group nzPrefix="£">
                    <input nz-input formControlName="price" id="price" />
                  </nz-input-group>

                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSm]="6" [nzXs]="24" formGroupName="planned_dates">
              <nz-form-item>
                <nz-form-label>Delivery Date</nz-form-label>
                <nz-form-control [nzSm]="12" [nzXs]="24" nzErrorTip="The input is not valid Delivery Date!">
                  <nz-date-picker formControlName="planned_delivery_date" id="planned_delivery_date"></nz-date-picker>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSm]="2" [nzXs]="24">
              <button nz-button nzType="primary" nzSize="small" nzShape="circle" (click)="removeItem(i)"><i nz-icon nzType="minus" nzTheme="outline"></i></button>
            </div>
          </div>
        </nz-space-item>
        <nz-space-item>
          <div nz-row>
            <div nz-col [nzSm]="6" [nzXs]="24">
              <nz-form-item>
                <nz-form-label nzFor="uoq">UOQ</nz-form-label>
                <nz-form-control [nzSm]="12" [nzXs]="24" nzErrorTip="The input is not valid UOQ!">
                  <input nz-input formControlName="uoq" id="uoq" />
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSm]="6" [nzXs]="24">
              <nz-form-item>
                <nz-form-label nzFor="unit">Unit</nz-form-label>
                <nz-form-control [nzSm]="12" [nzXs]="24" nzErrorTip="The input is not valid Unit!">
                  <input nz-input formControlName="unit" id="unit" />
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSm]="6" [nzXs]="24">
              <nz-form-item>
                <nz-form-label nzFor="unit">Discount</nz-form-label>
                <nz-form-control [nzSm]="12" [nzXs]="24">
                  <input nz-input formControlName="discount" id="discount" />
                  <nz-input-number formControlName="discount" id="discount" [nzMin]="0" [nzMax]="100" [nzStep]="1" [nzFormatter]="formatterPercent" [nzParser]="parserPercent"></nz-input-number>
                </nz-form-control>
              </nz-form-item>
            </div>
            <div nz-col [nzSm]="6" [nzXs]="24">
              <nz-form-item>
                <nz-form-label nzFor="unit">Description</nz-form-label>
                <nz-form-control [nzSm]="12" [nzXs]="24">
                  <textarea rows="4" nz-input formControlName="description" id="description"></textarea>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>
        </nz-space-item>
        <nz-space-item>
          <div nz-row>
            <div nz-col [nzSm]="8" [nzXs]="24">
              <nz-form-item *ngFor="let control of assetControls; let i = index">
                <nz-form-label *ngIf="i == 0" [nzFor]="control.controlInstance">Delivery Date for Items</nz-form-label>
                <nz-form-control [nzSm]="12" [nzXs]="24" [nzOffset]="i == 0 ? 0 : 8" nzErrorTip="The input is not valid Item!">
                  <nz-date-picker [formControlName]="i" [attr.id]="control.id"></nz-date-picker>
                </nz-form-control>
              </nz-form-item>
            </div>
          </div>
        </nz-space-item>
      </nz-space>
    </ng-container>
  </ng-container>

  <nz-divider></nz-divider>

  <nz-space nzDirection="vertical" style="width: 100%">
    <nz-space-item>
      <div nz-row>
        <div nz-col [nzSm]="11" [nzXs]="24">

        </div>
        <div nz-col [nzSm]="12" [nzXs]="24">

        </div>
      </div>
    </nz-space-item>
    <nz-space-item>
      <div nz-row>
        <div nz-col [nzSm]="11" [nzXs]="24">
          <nz-form-item>
            <nz-form-label nzFor="deliveryLocation">Delivery Location</nz-form-label>
            <nz-form-control [nzSm]="12" [nzXs]="24" nzErrorTip="The input is not a location!">
              <input nz-input formControlName="deliveryLocation" id="deliveryLocation" />
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSm]="12" [nzXs]="24">
          <nz-form-item>
            <nz-form-label nzFor="completionDate">Date of Completion</nz-form-label>
            <nz-form-control [nzSm]="12" [nzXs]="24" nzErrorTip="The input is not a valid date!">
              <nz-date-picker formControlName="completionDate" id="completionDate"></nz-date-picker>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
    </nz-space-item>
  </nz-space>

  <nz-space nzDirection="vertical" style="width: 100%">
    <nz-space-item>
      <div nz-row>
        <div nz-col [nzSm]="11" [nzXs]="24">

        </div>
        <div nz-col [nzSm]="12" [nzXs]="24">

        </div>
      </div>
    </nz-space-item>
    <nz-space-item>
      <div nz-row>
        <div nz-col [nzSm]="11" [nzXs]="24">
          <nz-form-item>
            <nz-form-label nzFor="description">Description</nz-form-label>
            <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="The input is empty!">
              <textarea rows="4" nz-input formControlName="description" id="description"></textarea>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSm]="12" [nzXs]="24">
          <nz-form-item>
            <nz-form-label nzFor="paymentTerms">Payment Terms</nz-form-label>
            <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="The input is empty!">
              <textarea rows="4" nz-input formControlName="paymentTerms" id="paymentTerms"></textarea>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
    </nz-space-item>
  </nz-space>

  <nz-space nzDirection="vertical" style="width: 100%">
    <nz-space-item>
      <div nz-row>
        <div nz-col [nzSm]="11" [nzXs]="24">
          <nz-form-item>
            <nz-form-label nzFor="shipmentMethodDates">Shipment method/dates </nz-form-label>
            <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="The input is empty!">
              <textarea rows="4" nz-input formControlName="shipmentMethodDates" id="shipmentMethodDates"></textarea>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSm]="12" [nzXs]="24">
          <nz-form-item>
            <nz-form-label nzFor="technicalDetails">Technical Details</nz-form-label>
            <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="The input is empty!">
              <textarea rows="4" nz-input formControlName="technicalDetails" id="technicalDetails"></textarea>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
    </nz-space-item>
  </nz-space>

  <nz-space nzDirection="vertical" style="width: 100%">
    <nz-space-item>
      <div nz-row>
        <div nz-col [nzSm]="11" [nzXs]="24">
          <nz-form-item>
            <nz-form-label nzFor="specialInstructions">Special Instructions</nz-form-label>
            <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="The input is empty!">
              <textarea rows="4" nz-input formControlName="specialInstructions" id="specialInstructions"></textarea>
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSm]="12" [nzXs]="24">
          <nz-form-item>
            <nz-form-label nzFor="discounts">Any discounts to be applied</nz-form-label>
            <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="The input is not a valid value!">
              <input nz-input formControlName="discounts" id="discounts" />
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
    </nz-space-item>
  </nz-space>

  <nz-space nzDirection="vertical" style="width: 100%">
    <nz-space-item>
      <div nz-row>
        <div nz-col [nzSm]="11" [nzXs]="24">
          <nz-form-item>
            <nz-form-label nzFor="sku">Model Number/SKU</nz-form-label>
            <nz-form-control [nzSm]="14" [nzXs]="24">
              <input nz-input formControlName="sku" id="sku" />
            </nz-form-control>
          </nz-form-item>
        </div>
        <div nz-col [nzSm]="12" [nzXs]="24">
          <nz-form-item>
            <nz-form-label nzRequired nzFor="billingAddress">Company billing address</nz-form-label>
            <nz-form-control [nzSm]="14" [nzXs]="24" nzErrorTip="Please enter billing address!">
              <textarea rows="4" nz-input formControlName="billingAddress" id="billingAddress"></textarea>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
    </nz-space-item>
  </nz-space>


  <nz-space nzDirection="vertical" style="width: 100%">
    <nz-space-item>
      <div nz-row>
        <div nz-col [nzSm]="24" [nzXs]="24">
          <nz-form-item>
            <nz-form-label nzFor="review">Review</nz-form-label>
            <nz-form-control [nzSm]="14" [nzXs]="24">
              <label nz-checkbox formControlName="review"></label>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
    </nz-space-item>
    <nz-space-item>
      <div nz-row>
        <div nz-col [nzXs]="24">
          <nz-form-item nz-row class="register-area">
            <nz-form-control [nzSpan]="14">
              <button nz-button nzType="primary">Create Order</button>
            </nz-form-control>
          </nz-form-item>
        </div>
      </div>
    </nz-space-item>
  </nz-space>
</form> -->
