import {
  IOrder
} from '../models/order';

import { v4 as uuidv4 } from 'uuid';


export const ORDERS: IOrder[] = [
  <IOrder> {
    id: uuidv4(),
    orderNo: '1',
    supplier: 'Supplier B',
    quantity: 5,
    unitPrice: 50,
    agreedPrice: 250,
    deliveryLocation: 'Embankment',
    completionDate: 1587942000000,
    description: 'URGENT: Concrete to be delivered',
    paymentTerms: '',
    discounts: '',
    sku: 'C-1',
    billingAddress: 'Billing Address'
  }
];
