import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';


export interface DialogData {
    animal: 'panda' | 'unicorn' | 'lion';
}

@Component({
  selector: 'app-state-dialog',
  templateUrl: './state-dialog.component.html',
  styleUrls: ['./state-dialog.component.scss']
})
export class StateDialogComponent {

    constructor(@Inject(MAT_DIALOG_DATA) public data: DialogData) {}
}
