import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';

import { getUrl } from './utils';
import { BasePlasmaService } from './base.plasma';


@Injectable({
  providedIn: 'root'
})
export class GeocodingService extends BasePlasmaService {

  protected url = 'https://api.maptiler.com/geocoding';

  constructor(protected http: HttpClient) {
    super(http);
  }

  geocode(address: string) {
    return this.makeGet(`${this.url}/${address}.json?key=7I73amnEEIBP7lFab7Ex `)
  }

  reverseGeocode(lat, lng) {
    return this.makeGet(`${this.url}/${lat},${lng}.json?key=7I73amnEEIBP7lFab7Ex `) as Observable<any>;
  }
}