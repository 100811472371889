import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';

import { getUrl } from './utils';
import { BasePlasmaService } from './base.plasma';


@Injectable({
  providedIn: 'root'
})
export class InventoryService extends BasePlasmaService {

  protected namespace = 'inventory';

  constructor(protected http: HttpClient) {
    super(http);
  }

  public create(inventory: any) {
    return this.makePost(getUrl(this.namespace), inventory) as Observable<any>;
  }

  public get(id: string) {
    return this.makeGet(`${getUrl(this.namespace)}/${id}`) as Observable<any>;
  }

  public getAll() {
    return this.makeGet(getUrl(this.namespace)) as Observable<any>;
  }

  public getByAccount(id) {
    return this.makeGet(`${getUrl(this.namespace)}?account=${id}`) as Observable<any>;
  }

  public update(id: string, inventory: any) {
    return this.makePatch(`${getUrl(this.namespace)}/${id}`, inventory) as Observable<any>;
  }

}
