import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import { OrderService } from '@plasma/services/order.plasma';
import { AssetService } from '@plasma/services/asset.plasma';
import { AuthService } from '@plasma/services/auth.plasma';

import { NzMessageService } from 'ng-zorro-antd/message';

import { COMMODITY_CODES } from '@data/commodity-codes.reference';
import { COUNTRIES } from '@data/countries.reference';


@Component({
  selector: 'app-pay-tariff',
  templateUrl: './pay-tariff.component.html',
  styleUrls: ['./pay-tariff.component.scss']
})
export class PayTariffComponent implements OnInit {

  id: string;
  order: any;
  tax = 0;
  commodityCodes = COMMODITY_CODES;
  countries = COUNTRIES;
  commodityNames = '';
  orderCommodityCodes = '';
  displayForm = true;

  constructor(private orderService: OrderService,
              private assetService: AssetService,
              public authService: AuthService,
              private message: NzMessageService,
              protected activatedRoute: ActivatedRoute,
              private location: Location) { }

  ngOnInit(): void {

    this.id = this.activatedRoute.snapshot.paramMap.get('id');
    if (!this.id) {
      return;
    }

    this.orderService.get(this.id)
      .subscribe((order) => {
        this.order = order;
        console.log('order', order);
        const assetTypes = [...new Set(order?.assets.map(asset => asset.asset_type))];
        if (order.is_tax_paid) {
          this.tax = order.tax;
          this.orderCommodityCodes = order.commodity_codes;
        } else {
          if (this.isTaxApplicable(order.destination.trim(), order.order_for.address.trim())) {
            this.tax = this.calculateTax(order?.agreed_price);
            this.orderCommodityCodes = this.getCommodityCodes(assetTypes).join(', ');
            this.displayForm = true;
          } else {
            this.displayForm = false;
          }
        }
        this.commodityNames = assetTypes.join(', ');
      }, (error) => {
        console.error('Error', error);
        this.message.create('error', `Error: ${error.error.error}`);
      });

  }

  isTaxApplicable(orderDestination, supplierAddress) {
    let orderDestinationCountry = '';
    let supplierAddressCountry = '';
    this.countries.map((country) => {

      if (orderDestination.toLowerCase().indexOf(country.toLowerCase()) !== -1) {
        orderDestinationCountry = country;
      }

      if (supplierAddress.toLowerCase().indexOf(country.toLowerCase()) !== -1) {
        supplierAddressCountry = country;
      }
    });

    if (orderDestinationCountry !== '' && supplierAddressCountry !== '' && orderDestinationCountry.toLowerCase() !== supplierAddressCountry.toLowerCase()) {
      return true;
    }

    return false;
  }

  calculateTax(agreedPrice: number) {
    if (!agreedPrice) {
      return 0;
    }

    const percentages = [0.05, 0.1, 0.2];
    const percentage = percentages[Math.floor(Math.random() * percentages.length)];
    return agreedPrice * percentage;
  }

  getCommodityCodes(assetTypes) {
    return assetTypes.map(assetType => {
      let commodityCode = this.commodityCodes[assetType.toLowerCase()];
      if (!commodityCode) {
        commodityCode = this.generateCommodityCode();
      }
      return commodityCode.match(/.{1,2}/g).join(' ');
    });
  }

  generateCommodityCode() {
    let commodityCode = (Math.floor(99 + (9999 - 99) * Math.random())).toString();
    if (commodityCode.length === 3) {
      commodityCode = '0'.concat(commodityCode);
    }
    return commodityCode;
  }

  authorizePayment(tax, commodityCodes) {
    this.orderService.payTax(this.id, tax, commodityCodes)
      .subscribe((resp) => {
        console.log('resp', resp);
        this.order.is_tax_paid = true;
        this.message.success(`Tax Paid!`);
      }, (error) => {
        console.error('Error', error);
        this.message.error(`Error ${error.error.error}`);
      });
  }

  onBack() {
    this.location.back();
  }

}
