import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';

import { Observable, Observer } from 'rxjs';

import { NzModalRef } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';
import { UploadChangeParam, UploadFile } from 'ng-zorro-antd/upload';

import { IAgent } from '@plasma/models/agent';

import { v4 as uuidv4 } from 'uuid';
import * as moment from 'moment';


@Component({
  selector: 'app-import-orders-modal',
  templateUrl: './import-orders-modal.component.html'
})
export class ImportOrdersModalComponent implements OnInit {

  @Input() project: any;
  @Input() user: IAgent;
  fileList: UploadFile[] = [];
  uploading = false;

  constructor(private modal: NzModalRef, private fb: FormBuilder, private message: NzMessageService) { }

  ngOnInit(): void {

  }

  beforeUpload = (file: UploadFile, _fileList: UploadFile[]) => {
    return new Observable((observer: Observer<boolean>) => {
      const isCsv = file.type === 'text/csv' || file.type === 'application/vnd.ms-excel';
      if (!isCsv) {
        this.message.error('You can only upload CSV file!');
        observer.complete();
        return;
      }
      /*const isLt2M = file.size! / 1024 / 1024 < 2;
      if (!isLt2M) {
        this.message.error('Image must smaller than 2MB!');
        observer.complete();
        return;
      }*/
      // observer.next(isCsv && isLt2M);
      observer.next(isCsv);
      observer.complete();
    });
  }

  handleChange({ file, fileList }: UploadChangeParam): void {
    const status = file.status;
    this.fileList = fileList.slice(-1);
    console.log(this.fileList);
    console.log(file);
    if (status !== 'uploading') {
      // console.log(file, fileList);
    }
    if (status === 'done') {
      this.message.success(`${file.name} file uploaded successfully.`);
    } else if (status === 'error') {
      this.message.error(`${file.name} file upload failed.`);
    }
  }

  handleUpload(e: any): void {
    e.preventDefault();
    const formData = new FormData();

    this.fileList.forEach((file: any) => {
      formData.append('documents', file);
    });
    // this.uploading = true;

    /*this.orderService.uploadDocuments(this.id, formData)
      .subscribe((resp) => {
        console.log('resp', resp);
        this.uploading = false;
        this.fileList = [];
        for (const document of resp?.links) {
          this.order?.documents?.push(document);
        }
        this.message.success(`Files uploaded successfully!`);
      }, (error) => {
        console.error('Error', error);
        this.message.error(`Error ${error.error.error}`);
      });*/
  }

  importOrders(): void {
    // console.log('value', value);
    // this.modal.destroy(value);
  }

}
