import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';


@Component({
  selector: 'app-supplier-a',
  templateUrl: './supplier-a.component.html',
  styleUrls: ['./supplier-a.component.scss']
})
export class SupplierAComponent implements OnInit {

  isCollapsed = false;

  constructor() { }

  ngOnInit(): void {

  }

}
