<nz-page-header class="site-page-header" (nzBack)="onBack()" nzBackIcon nzTitle="Inventory"></nz-page-header>
<button nz-button nzType="primary" [routerLink]="['/dashboard', { outlets: { dashboard: ['create-inventory'] } }]">Add Item</button>

<div class="mt-2">
    <!-- <mat-form-field>
        <mat-label>Filter</mat-label>
        <input matInput (keyup)="applyFilter($event)" placeholder="Ex. Mia">
    </mat-form-field> -->
      
    <div class="mat-elevation-z8">
      <table mat-table [dataSource]="dataSource" matSort>
  
        <ng-container matColumnDef="productName">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Product Name </th>
          <td mat-cell *matCellDef="let row" label="Product Name"> <a [routerLink]="['/dashboard', { outlets: { dashboard: ['inventory', row?.id] } }]">{{ row?.productName }}</a> </td>
        </ng-container>
    
        <ng-container matColumnDef="company">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Company </th>
          <td mat-cell *matCellDef="let row" label="Company"> {{row.company}} </td>
        </ng-container>
    
        <ng-container matColumnDef="companyAddress">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Company Address </th>
          <td mat-cell *matCellDef="let row" label="Company Address"> <img class="address-icon" [matTooltip]="row.companyAddress.address + ' (click to copy)'" matTooltipPosition="above" (click)="copyToClipboard(row.companyAddress.address)" src="assets/img/location-question.png"> </td>
        </ng-container>
    
        <ng-container matColumnDef="originOfManufacturer">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Origin of Manufacturer </th>
          <td mat-cell *matCellDef="let row" label="Origin of Manufacturer"> <a [routerLink]="['/dashboard', { outlets: { dashboard: ['map'] } }]" [queryParams]="{lat: row.originOfManufacturer.lat, lng: row.originOfManufacturer.lng}">{{ row.originOfManufacturer?.address }}</a> </td>
        </ng-container>
  
        <ng-container matColumnDef="associatedCode">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Associated Code </th>
          <td mat-cell *matCellDef="let row" label="Associated Code"> {{ row.associatedCode }} </td>
        </ng-container>
  
        <ng-container matColumnDef="productionProcessDetails">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Production Process Details </th>
          <td mat-cell *matCellDef="let row" label="Production Process Details"> {{ row.productionProcessDetails }} </td>
        </ng-container>
  
        <ng-container matColumnDef="materialsUsed">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Materials Used </th>
          <td mat-cell *matCellDef="let row" label="Materials Used"> {{ row.materialsUsed }} </td>
        </ng-container>
  
        <ng-container matColumnDef="applicableLaws">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Applicable Laws </th>
          <td mat-cell *matCellDef="let row" label="Applicable Laws"> {{ row.applicableLaws }} </td>
        </ng-container>
  
        <!-- <ng-container matColumnDef="parentProducts">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Parent Products </th>
          <td mat-cell *matCellDef="let row"> {{ row.parentProducts?.length }} </td>
        </ng-container> -->
  
        <ng-container matColumnDef="isRawMaterial">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Is Raw Material? </th>
          <td mat-cell *matCellDef="let row" label="Is Raw Material?"> {{ row.isRawMaterial ? 'Yes' : 'No' }} </td>
        </ng-container>
  
        <ng-container matColumnDef="createdOn">
          <th mat-header-cell *matHeaderCellDef mat-sort-header> Created On </th>
          <td mat-cell *matCellDef="let row" label="Created On"> {{ row.createdOn | date: 'yy-MM-dd, HH:mm' }} </td>
        </ng-container>
    
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;">
        </tr>
      </table>
      
        <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
    </div>      
</div>