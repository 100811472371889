import { Component, OnInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Clipboard } from '@angular/cdk/clipboard';
import { Location } from '@angular/common';

import { ProjectService } from '@plasma/services/project.plasma';
import { AuthService } from '@plasma/services/auth.plasma';
import { PhaseService } from '@plasma/services/phase.plasma';
import { OrderService } from '@plasma/services/order.plasma';
import { AgentsService } from '@plasma/services/agents.plasma';
import { EmailService } from '@plasma/services/email.plasma';

import { IAgent } from '@plasma/models/agent';
import { IProject } from '@plasma/models/project';

import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';

import { LoadingService } from '@plasma/components/loading/loading.service';

import { CreatePhaseModalComponent } from '@plasma/components/modal/create-phase-modal.component';
import { AssignUsersModalComponent } from '@plasma/components/modal/assign-users-modal.component';
import { ImportOrdersModalComponent } from '@plasma/components/modal/import-orders-modal.component';

import * as moment from 'moment';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';


@Component({
  selector: 'app-linked-orders',
  templateUrl: './linked-orders.component.html',
  styleUrls: ['./linked-orders.component.scss']
})
export class LinkedOrdersComponent implements OnInit, OnDestroy {

  id: string;
  user: IAgent;
  orders: any[];
  showLoading = false;
  displayedColumns: string[] = ['contract_no', 'account_no', 'project_cost_centre_code', 'order_no', 'phase', 'assets', 'agreed_price', 'destination', 'createdOn', 'status', 'actions'];
  dataSource = null;

  private destroy$ = new Subject<void>();

  constructor(private orderService: OrderService,
              private authService: AuthService,
              private agentService: AgentsService,
              private message: NzMessageService,
              private clipboard: Clipboard,
              protected activatedRoute: ActivatedRoute,
              private loadingService: LoadingService,
              private location: Location) { }

  ngOnInit(): void {

    this.id = this.activatedRoute.snapshot.paramMap.get('id');
    if (!this.id) {
      return;
    }

    this.authService.getUser()
      .pipe(takeUntil(this.destroy$))
      .subscribe((user: IAgent) => {
        console.log('user/agent', user);
        this.user = user;
        this.loadOrders();
    });

  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  loadOrders(): void {
    this.showLoading = true;
    this.orderService.getLinkedOrders(this.id)
      .pipe(takeUntil(this.destroy$))
      .subscribe((orders) => {
        this.orders = orders;
        this.dataSource = orders;
        this.showLoading = false;
      }, (error) => {
        this.showLoading = false;
        console.error('Error', error);
        this.message.create('error', `Error: ${error.error}`);
      });
  }

  calculateAssetStatus(deliveryDate, floatDays) {
    if (deliveryDate === 0) {
      return;
    }

    const criticalDate = moment(deliveryDate).add(floatDays, 'days');
    if (moment().isSameOrBefore(deliveryDate)) {
      return 0;
    } else if (moment().isAfter(deliveryDate) && moment().isSameOrBefore(criticalDate)) {
      return 1;
    } else if (moment().isAfter(criticalDate)) {
      return 2;
    }
  }

  calculateStatus(assets: any[]) {
    if (!assets) {
      return;
    }
    const statusVal = assets.map((asset) =>
      this.calculateAssetStatus(asset.planned_dates.planned_delivery_date, asset.float_days))
      .reduce((max, status) => (status > max ? status : max), 0);

    switch (statusVal) {
      case 0:
        return 'green';
        break;
      case 1:
        return 'yellow';
        break;
      case 2:
        return 'red';
        break;

      default:
        break;
    }
  }

  copyToClipboard(text: string) {
    if (!text) {
      return;
    }
    this.clipboard.copy(text);
  }

  onBack() {
    this.location.back();
  }

}
