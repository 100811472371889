import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Location } from '@angular/common';

import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import { Clipboard } from '@angular/cdk/clipboard';

import { InventoryDataSource } from './inventory.datasource';

import { InventoryService } from '@plasma/services/inventory.plasma';
import { AuthService } from '@plasma/services/auth.plasma';

import { IAgent } from '@plasma/models/agent';

import { NzMessageService } from 'ng-zorro-antd/message';

import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';


@Component({
  selector: 'app-inventory',
  templateUrl: './inventory.component.html',
  styleUrls: ['./inventory.component.scss']
})
export class InventoryComponent implements OnInit, OnDestroy {

  displayedColumns: string[] = ['productName', 'company', 'companyAddress', 'originOfManufacturer', 'associatedCode', 'productionProcessDetails', 'materialsUsed', 'applicableLaws', 'isRawMaterial', 'createdOn'];
  dataSource = null;
  user: IAgent;
  showLoading = false;

  private destroy$ = new Subject<void>();

  @ViewChild(MatPaginator, {static: true}) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  constructor(private inventoryService: InventoryService,
              private authService: AuthService,
              private clipboard: Clipboard,
              private message: NzMessageService,
              private location: Location) { }

  ngOnInit(): void {
    this.authService.getUser()
      .pipe(takeUntil(this.destroy$))
      .subscribe((user: IAgent) => {
        this.user = user;
        this.dataSource = new InventoryDataSource(this.inventoryService, user.account.id);
      }, error => { console.error(error); })

    /* this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort; */
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  applyFilter(event: Event) {
    /* const filterValue = (event.target as HTMLInputElement).value;
    this.dataSource.filter = filterValue.trim().toLowerCase();

    if (this.dataSource.paginator) {
      this.dataSource.paginator.firstPage();
    } */
  }

  copyToClipboard(text: string) {
    if (!text) {
      return;
    }
    this.clipboard.copy(text);
  }

  onBack() {
    this.location.back();
  }

}
