<!-- <div>
  <h2>{{ title }}</h2>
  <h4>{{ subtitle }}</h4>
  <p>
    <span>Get Modal instance in component</span>
    <button nz-button [nzType]="'primary'" (click)="destroyModal()">destroy modal in the component</button>
  </p>
</div> -->
<form novalidate [formGroup]="account">
  <div class="form-row">
    <div class="col-md-6">
      <div class="form-group row">
        <label for="name" class="col-sm-5 col-form-label required">Name</label>
        <div class="col-sm-7">
          <input type="text" formControlName="name" class="form-control form-control-sm" [ngClass]="{ 'is-invalid': (account.get('name').dirty || account.get('name').touched) && account.get('name').errors }" id="name">
          <div class="invalid-feedback" *ngIf="(account.get('name').dirty || account.get('name').touched) && account.get('name').hasError('required')">
            The input is not valid Name!
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group row">
        <label for="officeAddress" class="col-sm-5 col-form-label">Office Address</label>
        <div class="col-sm-7">
          <input type="text" formControlName="officeAddress" class="form-control form-control-sm" [ngClass]="{ 'is-invalid': (account.get('officeAddress').dirty || account.get('officeAddress').touched) && account.get('officeAddress').errors }" id="officeAddress">
          <div class="invalid-feedback" *ngIf="(account.get('officeAddress').dirty || account.get('officeAddress').touched) && account.get('officeAddress').hasError('required')">
            The input is not valid Office Address!
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="form-row">
    <div class="col-md-6">
      <div class="form-group row">
        <label for="billingAddress" class="col-sm-5 col-form-label">Billing Address</label>
        <div class="col-sm-7">
          <input type="text" formControlName="billingAddress" class="form-control form-control-sm" [ngClass]="{ 'is-invalid': (account.get('billingAddress').dirty || account.get('billingAddress').touched) && account.get('billingAddress').errors }" id="billingAddress">
          <div class="invalid-feedback" *ngIf="(account.get('billingAddress').dirty || account.get('billingAddress').touched) && account.get('billingAddress').hasError('required')">
            The input is not valid Billing Address!
          </div>
        </div>
      </div>
    </div>
    <div class="col-md-6">
      <div class="form-group row">
        <label for="phoneNo" class="col-sm-5 col-form-label">Phone No.</label>
        <div class="col-sm-7">
          <input type="text" formControlName="phoneNo" class="form-control form-control-sm" [ngClass]="{ 'is-invalid': (account.get('phoneNo').dirty || account.get('phoneNo').touched) && account.get('phoneNo').errors }" id="phoneNo">
          <div class="invalid-feedback" *ngIf="(account.get('phoneNo').dirty || account.get('phoneNo').touched) && account.get('phoneNo').hasError('required')">
            The input is not valid Phone No.!
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
