import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormArray, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { OrderService } from '@plasma/services/order.plasma';
import { AgentsService } from '@plasma/services/agents.plasma';
import { ProjectService } from '@plasma/services/project.plasma';
import { StageService } from '@plasma/services/stage.plasma';
import { PhaseService } from '@plasma/services/phase.plasma';
import { AuthService } from '@plasma/services/auth.plasma';

import { IOrder } from '@plasma/models/order';
import { IAgent } from '@plasma/models/agent';

import { v4 as uuidv4 } from 'uuid';

import { NzMessageService } from 'ng-zorro-antd/message';

import * as moment from 'moment';
import { LoadingService } from '@plasma/components/loading/loading.service';


@Component({
  selector: 'app-create-requisition',
  templateUrl: './create-requisition.component.html',
  styleUrls: ['./create-requisition.component.scss']
})
export class CreateRequisitionComponent implements OnInit {

  order: FormGroup;
  serviceProviders: any[];
  assets: FormArray;
  user: IAgent;
  projectId: string;
  project: any;
  stages: any[];
  phases: any[];

  constructor(private fb: FormBuilder,
              private activatedRoute: ActivatedRoute,
              private orderService: OrderService,
              private agentService: AgentsService,
              private projectService: ProjectService,
              private stageService: StageService,
              private phaseService: PhaseService,
              private authService: AuthService,
              private message: NzMessageService,
              private loadingService: LoadingService,
              private location: Location) { }

  ngOnInit(): void {

    this.projectId = this.activatedRoute.snapshot.queryParamMap.get('projectId');
    if (!this.projectId) {
      return;
    }

    this.agentService.getByRole('service_provider')
      .subscribe((agents) => {
        if (agents) {
          this.serviceProviders = agents;
        } else {
          console.error('No agents exist');
        }
      }, (error) => {
        console.error('Error', error.error);
        this.message.create('error', `Error: ${error.error}`);
    });

    this.projectService.get(this.projectId)
      .subscribe((project) => {
        console.log('project', project);
        this.project = project;
      }, (error) => {
        console.error(`Error ${error.error}`);
        this.message.create('error', `Error: ${error.error.error}`);
    });

    this.order = this.fb.group({
      stage: [null, [Validators.required]],
      phase: [null, [Validators.required]],
      isRequisition: [true, [Validators.required]],
      contractNo: ['MA0001', [Validators.required]],
      accountNo: ['GALL023', [Validators.required]],
      projectCostCentreCode: ['PCC01', [Validators.required]],
      orderNo: ['PO01', [Validators.required]],
      confirmationOrder: ['CO01', [Validators.required]],
      serviceProvider: [null, [Validators.required]],
      bim: [null],
      assets: this.fb.array([this.createAsset()]),
      agreedPrice: [0.0, [Validators.required]],
      internalOrder: [false],
      deliveryLocation: ['London', [Validators.required]],
      completionDate: [new Date(), [Validators.required]],
      description: [null],
      paymentTerms: [null],
      shipmentMethodDates: [null],
      technicalDetails: [null],
      specialInstructions: [null],
      sku: [null],
      billingAddress: [null, [Validators.required]],
      review: [true]
    });

    this.authService.getUser()
      .subscribe((user: IAgent) => {
        if (user) {
          this.user = user;
          console.log('user', user);
          this.order.get('billingAddress').patchValue(user.address);

          this.stageService.getAll(this.user?.account?.id, this.projectId)
            .subscribe((stages) => {
              this.stages = stages;
            }, (error) => {
              console.error(`Error ${error.error.error}`);
            });
        }
      });

    // this.loadProjectOrders();
    this.onChanges();
  }

  /* loadProjectOrders() {
    this.orderService.getByProject(this.projectId)
      .subscribe((orders) => {
        this.orders = orders;
      }, (error) => {
        console.error('Error', error);
        // this.message.create('error', `Error: ${error.error}`);
      });
  } */

  addItem(e): void {
    e.preventDefault();
    this.assets = this.order.get('assets') as FormArray;
    this.assets.push(this.createAsset());
  }

  createAsset(): FormGroup {

    let asset = this.fb.group({
      assetId: [uuidv4(), [Validators.required]],
      description: [null, Validators.required],
      quantity: [null, [Validators.required]],
      price: [null, { validators: [Validators.required], updateOn: 'blur'}],
      plannedDates: this.fb.group({
        plannedDeliveryDate: [new Date(), [Validators.required]]
      })
    });

    return asset;
  }

  removeItem(e, index): void {
    e.preventDefault();
    if (this.assets && (index > this.assets.length) || (index + 1) === this.assets.length) {
      return;
    }
    this.assets.removeAt(index);
  }

  onChanges() {
    this.order.get('assets').valueChanges
      .subscribe((assets) => {
        let totalCost: number = 0;
        for (const asset of assets) {
          if (asset.price > 0) {
            totalCost += +asset.price;
          }
        }
        this.order.get('agreedPrice').patchValue(totalCost);
      });

    this.order.get('stage').valueChanges
      .subscribe((stageId) => {
        this.getPhasesByStage(stageId);
      });

  }

  getPhasesByStage(stageId: string) {
    if (!stageId) {
      return;
    }

    this.phaseService.getAllByStage(stageId)
      .subscribe((phases) => {
        this.phases = phases;
      }, (error) => {
        console.error(`Error ${error.error.error}`);
    });
  }

  onSubmit({value, valid}: {value: IOrder, valid: boolean}): void {
    // date.setHours(time.getHours(), time.getMinutes(), time.getSeconds());

    if (valid) {

      if (value['completionDate']) {
        value['completionDate'] = moment(value['completionDate']).valueOf();
      }

      for (const asset of value['assets']) {
        if (asset['plannedDates']['plannedDeliveryDate']) {
          asset['plannedDates']['plannedDeliveryDate'] = moment(asset['plannedDates']['plannedDeliveryDate']).valueOf();
        }
      }

      value['account'] = this.user.account.id;
      value['project'] = this.projectId;
      value['createdOn'] = moment().valueOf();

      this.loadingService.start();
      this.orderService.createRequisition(value)
        .subscribe((resp) => {
          console.log('resp', resp);
          this.loadingService.complete();
          this.message.create('success', `Requisition was created successfully!`);
          this.onBack();
        }, (error) => {
          this.loadingService.complete();
          console.error('Error', error);
          this.message.create('error', `Error: ${error.error.error}`);
      });
    }

  }

  onBack() {
    this.location.back();
  }

}
