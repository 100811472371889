import { IValueOption } from '@plasma/common/value-option';

export const PLEASE_SELECT = 'Please Select';

// boolean
export const YES = 'Yes';
export const NO = 'No';
export const UNSURE = 'UNSURE';

export type YesNo = 'Yes' | 'No';

export const YesNoOptions: Array<IValueOption<boolean>> = [
  {
    title: YES,
    value: true
  },
  {
    title: NO,
    value: false
  }
];

// state
export const STATE_UNSPECIFIED = 'Unspecified';
export const STATE_DAMAGED = 'Damaged';
export const STATE_UNDAMAGED = 'Undamaged';
export const STATE_UNKNOWN = 'Unknown';

export type State = 'Damaged' | 'Undamaged' | 'Unknown';

export const StateOptions: Array<IValueOption<string>> = [
  {
    title: STATE_UNSPECIFIED,
    value: 'STATE_UNSPECIFIED'
  },
  {
    title: STATE_DAMAGED,
    value: 'STATE_DAMAGED'
  },
  {
    title: STATE_UNDAMAGED,
    value: 'STATE_UNDAMAGED'
  },
  {
    title: STATE_UNKNOWN,
    value: 'STATE_UNKNOWN'
  }
];


// stage
export const STAGE_UNSPECIFIED = 'Unspecified';
export const STAGE_PRODUCTION_STARTED = 'Production Started';
export const STAGE_READY_FOR_DISPATCH = 'Ready for Dispatch';
export const STAGE_DISPATCH_FROM_FACTORY = 'Dispatch from Factory';
export const STAGE_IN_TRANSIT = 'In Transit';
export const STAGE_IN_DEPOT = 'In Depot';
export const STAGE_OUT_FOR_DELIVERY = 'Out for Delivery';
export const STAGE_RECEIVED_ON_SITE = 'Received on Site';
export const STAGE_INSTALLED = 'Installed';

export type Stage = 'Production Started' | 'Ready for Dispatch'
  | 'Dispatch from Factory' | 'In Transit' | 'In Depot' | 'Out for Delivery'
  | 'Received on Site' | 'Installed';

export const StageOptions: Array<IValueOption<string>> = [
  {
    title: STAGE_UNSPECIFIED,
    value: 'STAGE_UNSPECIFIED'
  },
  {
    title: STAGE_PRODUCTION_STARTED,
    value: 'STAGE_PRODUCTION_STARTED'
  },
  {
    title: STAGE_READY_FOR_DISPATCH,
    value: 'STAGE_READY_FOR_DISPATCH'
  },
  {
    title: STAGE_DISPATCH_FROM_FACTORY,
    value: 'STAGE_DISPATCH_FROM_FACTORY'
  },
  {
    title: STAGE_IN_TRANSIT,
    value: 'STAGE_IN_TRANSIT'
  },
  {
    title: STAGE_IN_DEPOT,
    value: 'STAGE_IN_DEPOT'
  },
  {
    title: STAGE_OUT_FOR_DELIVERY,
    value: 'STAGE_OUT_FOR_DELIVERY'
  },
  {
    title: STAGE_RECEIVED_ON_SITE,
    value: 'STAGE_RECEIVED_ON_SITE'
  },
  {
    title: STAGE_INSTALLED,
    value: 'STAGE_INSTALLED'
  }
];


export const SUBROLE_ADMINISTRATOR = 'Administrator';
export const SUBROLE_PROJECT_OWNER = 'Project Owner';
export const SUBROLE_USER = 'User';

export type SubRole = 'Administrator' | 'Project Owner' | 'User';

export const SubRoleOptions: Array<IValueOption<string>> = [
  {
    title: SUBROLE_USER,
    value: 'USER'
  },
  {
    title: SUBROLE_PROJECT_OWNER,
    value: 'PROJECT_OWNER'
  },
  {
    title: SUBROLE_ADMINISTRATOR,
    value: 'ADMINISTRATOR'
  }
];


export const CONCRETE = 'Concrete';
export const LIMESTONE = 'Limestone';
export const RECO_WALLS = 'Reco Walls';
export const DOORS = 'Doors';

export type AssetType = 'concrete' | 'limestone' | 'reco_walls' | 'doors';
export const AssetTypeOptions: Array<IValueOption<AssetType>> = [
  {
    title: CONCRETE,
    value: 'concrete'
  },
  {
    title: LIMESTONE,
    value: 'limestone'
  },
  {
    title: RECO_WALLS,
    value: 'reco_walls'
  },
  {
    title: DOORS,
    value: 'doors'
  }
];


export const AIR = 'Air';
export const CYCLE = 'Cycle';
export const FOOT = 'Foot';
export const RAIL = 'Rail';
export const ROAD = 'Road (Car, Lorry)';
export const SEA = 'Sea';

export type ModeOfTransport = 'air' | 'cycle' | 'foot' | 'rail' | 'road' | 'sea';
export const ModeOfTransportOptions: Array<IValueOption<ModeOfTransport>> = [
  {
    title: PLEASE_SELECT,
    value: null
  },
  {
    title: AIR,
    value: 'air'
  },
  {
    title: CYCLE,
    value: 'cycle'
  },
  {
    title: FOOT,
    value: 'foot'
  },
  {
    title: RAIL,
    value: 'rail'
  },
  {
    title: ROAD,
    value: 'road'
  },
  {
    title: SEA,
    value: 'sea'
  }
];
