import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';

import { NgxEchartsModule } from 'ngx-echarts';

import { NzFormModule } from 'ng-zorro-antd/form';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzSpinModule } from 'ng-zorro-antd/spin';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete';
import { NzTabsModule } from 'ng-zorro-antd/tabs';
import { NzIconModule } from 'ng-zorro-antd/icon';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';
import { NzStatisticModule } from 'ng-zorro-antd/statistic';


import { InputComponent } from './input/input.component';
import { LoadingComponent } from './loading/loading.component';
import { CreateAccountModalComponent } from './modal/create-account-modal.component';
import { CreateProjectModalComponent } from './modal/create-project-modal.component';
import { CreatePhaseModalComponent } from './modal/create-phase-modal.component';
import { AssignUsersModalComponent } from './modal/assign-users-modal.component';
import { ImportOrdersModalComponent } from './modal/import-orders-modal.component';
import { InviteProjectOwnerModalComponent } from './modal/invite-project-owner-modal.component';
import { StateDialogComponent } from './dialog/state-dialog.component';
import { HelpDialogComponent } from './dialog/help-dialog.component';
import { AnalyticsDialogComponent } from './dialog/analytics-dialog.component';
import { UploadedDocumentsDialogComponent } from './dialog/uploaded-documents.component';


import { StageSplitPipe } from './pipes/stage-split.pipe';
import { SplitPipe } from './pipes/split.pipe';
import { JoinPipe } from './pipes/join.pipe';

import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import {
  faTruckMoving,
  faTree,
  faFileAlt,
  faFileWord,
  faFilePdf,
  faMoneyBill,
  faLink,
  faListAlt,
  faCalendarAlt,
  faUserPlus,
  faPen,
  faEye,
  faBarcode,
  faQrcode,
  faFileUpload,
  faInfoCircle,
  faProjectDiagram,
  faFileDownload,
  faSignInAlt
} from '@fortawesome/free-solid-svg-icons';
import { UploadDocumentsDialogComponent } from './dialog/upload-documents-dialog.component';


const modules = [
  InputComponent,
  LoadingComponent,
  StageSplitPipe,
  SplitPipe,
  JoinPipe,
  CreateAccountModalComponent,
  CreateProjectModalComponent,
  CreatePhaseModalComponent,
  AssignUsersModalComponent,
  ImportOrdersModalComponent,
  InviteProjectOwnerModalComponent,
  StateDialogComponent,
  HelpDialogComponent,
  AnalyticsDialogComponent,
  UploadedDocumentsDialogComponent,
  UploadDocumentsDialogComponent
];

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    FontAwesomeModule,
    NgxEchartsModule,
    NzFormModule,
    NzButtonModule,
    NzInputModule,
    NzSpinModule,
    NzModalModule,
    NzSelectModule,
    NzAutocompleteModule,
    NzTabsModule,
    NzIconModule,
    NzUploadModule,
    NzCardModule,
    NzPageHeaderModule,
    NzStatisticModule,
    MatDialogModule,
    MatButtonModule
  ],
  entryComponents: [],
  providers: [],
  declarations: modules,
  exports: modules
})
export class ComponentsModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(
      faTruckMoving,
      faTree,
      faFileAlt,
      faFileWord,
      faFilePdf,
      faMoneyBill,
      faLink,
      faListAlt,
      faCalendarAlt,
      faUserPlus,
      faPen,
      faEye,
      faBarcode,
      faQrcode,
      faFileUpload,
      faInfoCircle,
      faProjectDiagram,
      faFileDownload,
      faSignInAlt);
  }
}
