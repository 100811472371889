import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';

import { getUrl } from './utils';
import { BasePlasmaService } from './base.plasma';


@Injectable({
  providedIn: 'root'
})
export class ProjectService extends BasePlasmaService {

  protected namespace = 'projects';

  constructor(protected http: HttpClient) {
    super(http);
  }

  public create(project: any) {
    return this.makePost(getUrl(this.namespace), project) as Observable<any>;
  }

  public getAll(account: string, assignedUsers: string = null, assignedProjectOwners: string = null) {
    let url = `${getUrl(this.namespace)}?account=${account}`;
    if (assignedUsers) {
      url = `${url}&assignedUsers=${assignedUsers}`;
    } else if (assignedProjectOwners) {
      url = `${url}&assignedProjectOwners=${assignedProjectOwners}`;
    }
    return this.makeGet(url) as Observable<any>;
    /* return of([{
      "account": "2da6aea5-548d-442c-b67c-a2fdf1d68458" ,
      "assignedProjectOwners": [
          "0374b672cf1d02e7c749d037fa0d1c6435d17c6170b50df878c993ab4e586d1da2"
      ] ,
      "assignedUsers": [ ],
      "createdBy": { } ,
      "createdOn": 1656338717255 ,
      "description": "Buy bricks to distribute" ,
      "id": "55439b0e-31a4-4300-902a-8d585d51b8d0" ,
      "name": "Bricks" ,
      "ownerId": "0374b672cf1d02e7c749d037fa0d1c6435d17c6170b50df878c993ab4e586d1da2" ,
      "ownerName": "European Building Materials Ltd" ,
      "projectId": "003" ,
      "stages": [
          "2a980456-a2e4-44d1-8828-2ea0bcdb48d0"
      ] ,
      "updatedBy": { } ,
      "updatedOn": 1656338717255,
      "orders": [
        {
          "account": "2da6aea5-548d-442c-b67c-a2fdf1d68458" ,
          "account_no": "GALL023" ,
          "actual_cost": 7800 ,
          "agreed_price": 7800 ,
          "assets": [
              {
                  "account": "" ,
                  "actual_dates": {
                      "actual_completion_date": 0 ,
                      "actual_delivery_date": 0 ,
                      "actual_installed_date": 0
                  } ,
                  "assetType": "Bricks" ,
                  "assets": [ ],
                  "barcode": "" ,
                  "createdBy": {
                      "account": {
                          "id": "2da6aea5-548d-442c-b67c-a2fdf1d68458" ,
                          "name": "European Building Materials Ltd"
                      } ,
                      "address": "Bourne Court, 10a Southend Rd, Woodford, Woodford Green IG8 8HD" ,
                      "can_assign_users": true ,
                      "can_authorise_payment": true ,
                      "can_check_provenance": true ,
                      "can_invite_project_owners": true ,
                      "can_invite_users": true ,
                      "can_manage_orders": true ,
                      "can_manage_project_owners": true ,
                      "can_manage_projects": true ,
                      "can_manage_users": true ,
                      "can_track_orders": true ,
                      "created_by": {
                          "name": "European Building Materials Ltd" ,
                          "public_key": "0374b672cf1d02e7c749d037fa0d1c6435d17c6170b50df878c993ab4e586d1da2"
                      } ,
                      "created_on": 1656336773 ,
                      "description": "Brick distributor" ,
                      "name": "European Building Materials Ltd" ,
                      "projects": [ ],
                      "public_key": "0374b672cf1d02e7c749d037fa0d1c6435d17c6170b50df878c993ab4e586d1da2" ,
                      "role": {
                          "type": "BUYER"
                      } ,
                      "sub_role": {
                          "type": "ADMINISTRATOR"
                      } ,
                      "updated_by": {
                          "name": "European Building Materials Ltd" ,
                          "public_key": "0374b672cf1d02e7c749d037fa0d1c6435d17c6170b50df878c993ab4e586d1da2"
                      } ,
                      "updated_on": 1656336773
                  } ,
                  "createdOn": 1656338982157 ,
                  "description": "" ,
                  "discount": 0 ,
                  "float_days": 0 ,
                  "id": "03c89f6e-9cd8-4f8b-aa0e-30333bd934eb" ,
                  "inUseForProvenance": false ,
                  "isAccepted": false ,
                  "isOrderAsset": false ,
                  "isRejected": false ,
                  "locations": [
                      {
                          "latitude": 0 ,
                          "longitude": 0 ,
                          "name": "London" ,
                          "timestamp": 1656338982157
                      }
                  ] ,
                  "manufacturer": {
                      "agent_id": "" ,
                      "name": "" ,
                      "timestamp": 0
                  } ,
                  "modeOfTransport": "" ,
                  "name": "" ,
                  "order": {
                      "id": "7c1fee67-5a39-4fda-bca6-0fca4d271615" ,
                      "orderNo": "PO10" ,
                      "timestamp": 1656338982157
                  } ,
                  "owners": [
                      {
                          "id": "0269959396044fa42fdc3bd4ec4188d2a430bd7974e8df28421fc89d0b0e91fa1c" ,
                          "name": "Ibstock Brick Supplier" ,
                          "timestamp": 1656338982157
                      }
                  ] ,
                  "planned_dates": {
                      "planned_completion_date": 1657407600000 ,
                      "planned_delivery_date": 1657407600000 ,
                      "planned_installed_date": 0
                  } ,
                  "postState": {
                      "documents": [ ],
                      "images": [ ],
                      "state": "STATE_UNSPECIFIED"
                  } ,
                  "preState": {
                      "documents": [ ],
                      "images": [ ],
                      "state": "STATE_UNSPECIFIED"
                  } ,
                  "price": "7800.00" ,
                  "provenances": [ ],
                  "quantity": 5000 ,
                  "stage": {
                      "documents": [ ],
                      "stage": "STAGE_UNSPECIFIED"
                  } ,
                  "supplier": {
                      "agent_id": "0269959396044fa42fdc3bd4ec4188d2a430bd7974e8df28421fc89d0b0e91fa1c" ,
                      "name": "Ibstock Brick Supplier" ,
                      "timestamp": 1656336499
                  } ,
                  "unit": "" ,
                  "uoq": "" ,
              }
          ] ,
          "billingAddress": "Bourne Court, 10a Southend Rd, Woodford, Woodford Green IG8 8HD" ,
          "bim": "6815" ,
          "commodity_codes": "" ,
          "confirmationOrder": "CO01" ,
          "contract_no": "MA0001" ,
          "cost_deduction": 0 ,
          "cost_paid": 0 ,
          "createdBy": { } ,
          "createdOn": 1656338982157 ,
          "description": "" ,
          "destination": "London" ,
          "discount": 0 ,
          "documents": [ ],
          "final": false ,
          "has_linked_orders": false ,
          "id": "7c1fee67-5a39-4fda-bca6-0fca4d271615" ,
          "internal_order": false ,
          "is_completed": false ,
          "is_payment_paid": false ,
          "is_requisition": false ,
          "is_tax_paid": false ,
          "linked_orders": [ ],
          "order_dates": {
              "order_completion-date": 1657407600000 ,
              "order_delivery_date": "" ,
              "order_install_date": ""
          } ,
          "order_for": {
              "agent_id": "0269959396044fa42fdc3bd4ec4188d2a430bd7974e8df28421fc89d0b0e91fa1c" ,
              "role_type": "SUPPLIER" ,
              "timestamp": 1656338982157
          } ,
          "order_no": "PO10" ,
          "order_stage": "" ,
          "order_status": "" ,
          "order_type": "" ,
          "owner": {
              "id": "0374b672cf1d02e7c749d037fa0d1c6435d17c6170b50df878c993ab4e586d1da2" ,
              "name": "European Building Materials Ltd" ,
              "timestamp": 1656338982157
          } ,
          "payment_status": "" ,
          "payment_terms": "" ,
          "phase": "a4e5e7d8-d155-4ab1-9d82-882d23694d4a" ,
          "project": "55439b0e-31a4-4300-902a-8d585d51b8d0" ,
          "project_cost_centre_code": "PCC01" ,
          "provenance_list": [ ],
          "quantity": 0 ,
          "review": true ,
          "shipment_method_dates": "" ,
          "sku": "" ,
          "special_instructions": "" ,
          "stage": "2a980456-a2e4-44d1-8828-2ea0bcdb48d0" ,
          "supervisor": {
              "id": "0374b672cf1d02e7c749d037fa0d1c6435d17c6170b50df878c993ab4e586d1da2" ,
              "name": "" ,
              "timestamp": 1656338982157
          } ,
          "task_id": "" ,
          "tax": 0 ,
          "technical_details": "" ,
          "updated_on": 1656338982157 ,
          "voided": false
      }]
    },
    {
      "account": "2da6aea5-548d-442c-b67c-a2fdf1d68458" ,
      "assignedProjectOwners": [
          "0374b672cf1d02e7c749d037fa0d1c6435d17c6170b50df878c993ab4e586d1da2"
      ] ,
      "assignedUsers": [ ],
      "createdBy": { } ,
      "createdOn": 1656338717255 ,
      "description": "Buy cement to distribute" ,
      "id": "55439b0e-31a4-4300-902a-8d585d51b8d0" ,
      "name": "Cement" ,
      "ownerId": "0374b672cf1d02e7c749d037fa0d1c6435d17c6170b50df878c993ab4e586d1da2" ,
      "ownerName": "European Building Materials Ltd" ,
      "projectId": "004" ,
      "stages": [
          "2a980456-a2e4-44d1-8828-2ea0bcdb48d0"
      ] ,
      "updatedBy": { } ,
      "updatedOn": 1656338717255,
      "orders": [
        {
          "account": "2da6aea5-548d-442c-b67c-a2fdf1d68458" ,
          "account_no": "GALL023" ,
          "actual_cost": 7800 ,
          "agreed_price": 7800 ,
          "assets": [
              {
                  "account": "" ,
                  "actual_dates": {
                      "actual_completion_date": 0 ,
                      "actual_delivery_date": 0 ,
                      "actual_installed_date": 0
                  } ,
                  "assetType": "Bricks" ,
                  "assets": [ ],
                  "barcode": "" ,
                  "createdBy": {
                      "account": {
                          "id": "2da6aea5-548d-442c-b67c-a2fdf1d68458" ,
                          "name": "European Building Materials Ltd"
                      } ,
                      "address": "Bourne Court, 10a Southend Rd, Woodford, Woodford Green IG8 8HD" ,
                      "can_assign_users": true ,
                      "can_authorise_payment": true ,
                      "can_check_provenance": true ,
                      "can_invite_project_owners": true ,
                      "can_invite_users": true ,
                      "can_manage_orders": true ,
                      "can_manage_project_owners": true ,
                      "can_manage_projects": true ,
                      "can_manage_users": true ,
                      "can_track_orders": true ,
                      "created_by": {
                          "name": "European Building Materials Ltd" ,
                          "public_key": "0374b672cf1d02e7c749d037fa0d1c6435d17c6170b50df878c993ab4e586d1da2"
                      } ,
                      "created_on": 1656336773 ,
                      "description": "Brick distributor" ,
                      "name": "European Building Materials Ltd" ,
                      "projects": [ ],
                      "public_key": "0374b672cf1d02e7c749d037fa0d1c6435d17c6170b50df878c993ab4e586d1da2" ,
                      "role": {
                          "type": "BUYER"
                      } ,
                      "sub_role": {
                          "type": "ADMINISTRATOR"
                      } ,
                      "updated_by": {
                          "name": "European Building Materials Ltd" ,
                          "public_key": "0374b672cf1d02e7c749d037fa0d1c6435d17c6170b50df878c993ab4e586d1da2"
                      } ,
                      "updated_on": 1656336773
                  } ,
                  "createdOn": 1656338982157 ,
                  "description": "" ,
                  "discount": 0 ,
                  "float_days": 0 ,
                  "id": "03c89f6e-9cd8-4f8b-aa0e-30333bd934eb" ,
                  "inUseForProvenance": false ,
                  "isAccepted": false ,
                  "isOrderAsset": false ,
                  "isRejected": false ,
                  "locations": [
                      {
                          "latitude": 0 ,
                          "longitude": 0 ,
                          "name": "London" ,
                          "timestamp": 1656338982157
                      }
                  ] ,
                  "manufacturer": {
                      "agent_id": "" ,
                      "name": "" ,
                      "timestamp": 0
                  } ,
                  "modeOfTransport": "" ,
                  "name": "" ,
                  "order": {
                      "id": "7c1fee67-5a39-4fda-bca6-0fca4d271615" ,
                      "orderNo": "PO10" ,
                      "timestamp": 1656338982157
                  } ,
                  "owners": [
                      {
                          "id": "0269959396044fa42fdc3bd4ec4188d2a430bd7974e8df28421fc89d0b0e91fa1c" ,
                          "name": "Ibstock Brick Supplier" ,
                          "timestamp": 1656338982157
                      }
                  ] ,
                  "planned_dates": {
                      "planned_completion_date": 1657407600000 ,
                      "planned_delivery_date": 1657407600000 ,
                      "planned_installed_date": 0
                  } ,
                  "postState": {
                      "documents": [ ],
                      "images": [ ],
                      "state": "STATE_UNSPECIFIED"
                  } ,
                  "preState": {
                      "documents": [ ],
                      "images": [ ],
                      "state": "STATE_UNSPECIFIED"
                  } ,
                  "price": "7800.00" ,
                  "provenances": [ ],
                  "quantity": 5000 ,
                  "stage": {
                      "documents": [ ],
                      "stage": "STAGE_UNSPECIFIED"
                  } ,
                  "supplier": {
                      "agent_id": "0269959396044fa42fdc3bd4ec4188d2a430bd7974e8df28421fc89d0b0e91fa1c" ,
                      "name": "Ibstock Brick Supplier" ,
                      "timestamp": 1656336499
                  } ,
                  "unit": "" ,
                  "uoq": "" ,
              }
          ] ,
          "billingAddress": "Bourne Court, 10a Southend Rd, Woodford, Woodford Green IG8 8HD" ,
          "bim": "6815" ,
          "commodity_codes": "" ,
          "confirmationOrder": "CO01" ,
          "contract_no": "MA0001" ,
          "cost_deduction": 0 ,
          "cost_paid": 0 ,
          "createdBy": { } ,
          "createdOn": 1656338982157 ,
          "description": "" ,
          "destination": "London" ,
          "discount": 0 ,
          "documents": [ ],
          "final": false ,
          "has_linked_orders": false ,
          "id": "7c1fee67-5a39-4fda-bca6-0fca4d271615" ,
          "internal_order": false ,
          "is_completed": false ,
          "is_payment_paid": false ,
          "is_requisition": false ,
          "is_tax_paid": false ,
          "linked_orders": [ ],
          "order_dates": {
              "order_completion-date": 1657407600000 ,
              "order_delivery_date": "" ,
              "order_install_date": ""
          } ,
          "order_for": {
              "agent_id": "0269959396044fa42fdc3bd4ec4188d2a430bd7974e8df28421fc89d0b0e91fa1c" ,
              "role_type": "SUPPLIER" ,
              "timestamp": 1656338982157
          } ,
          "order_no": "PO10" ,
          "order_stage": "" ,
          "order_status": "" ,
          "order_type": "" ,
          "owner": {
              "id": "0374b672cf1d02e7c749d037fa0d1c6435d17c6170b50df878c993ab4e586d1da2" ,
              "name": "European Building Materials Ltd" ,
              "timestamp": 1656338982157
          } ,
          "payment_status": "" ,
          "payment_terms": "" ,
          "phase": "a4e5e7d8-d155-4ab1-9d82-882d23694d4a" ,
          "project": "55439b0e-31a4-4300-902a-8d585d51b8d0" ,
          "project_cost_centre_code": "PCC01" ,
          "provenance_list": [ ],
          "quantity": 0 ,
          "review": true ,
          "shipment_method_dates": "" ,
          "sku": "" ,
          "special_instructions": "" ,
          "stage": "2a980456-a2e4-44d1-8828-2ea0bcdb48d0" ,
          "supervisor": {
              "id": "0374b672cf1d02e7c749d037fa0d1c6435d17c6170b50df878c993ab4e586d1da2" ,
              "name": "" ,
              "timestamp": 1656338982157
          } ,
          "task_id": "" ,
          "tax": 0 ,
          "technical_details": "" ,
          "updated_on": 1656338982157 ,
          "voided": false
      }]
    }]) */
  }

  public get(id) {
    return this.makeGet(`${getUrl(this.namespace)}/${id}`) as Observable<any>;
  }

  public getStatus(id) {
    return this.makeGet(`${getUrl(this.namespace)}/${id}/status`) as Observable<any>;
  }

  public getRejectedAndDamagedAssetStatus(id) {
    return this.makeGet(`${getUrl(this.namespace)}/${id}/status/assets`) as Observable<any>;
  }

  public update(project: any) {
    return this.makePatch(`${getUrl(this.namespace)}/${project.id}`, project) as Observable<any>;
  }

  public delete(id) {
    return this.makeDelete(`${getUrl(this.namespace)}/${id}`) as Observable<any>;
  }

}
