import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { IOrder } from '../../models/order';
import { ORDERS } from '../../data/order.reference';
import { v4 as uuidv4 } from 'uuid';


@Component({
  selector: 'app-supplier-c',
  templateUrl: './supplier-c.component.html',
  styleUrls: ['./supplier-c.component.scss']
})
export class SupplierCComponent implements OnInit {

  isCollapsed = false;
  public orders: IOrder[] = ORDERS;

  constructor() { }

  ngOnInit(): void {
    console.log('orders', this.orders);

  }

}
