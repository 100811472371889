import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.plasma';
import { AccountService } from '@plasma/services/account.plasma';
import { AgentsService } from '@plasma/services/agents.plasma';
import { PlacesService } from '@plasma/services/places.plasma';
import { IAgent } from '@plasma/models/agent';
import { IAccount } from '@plasma/models/account';

import { NzMessageService } from 'ng-zorro-antd/message';

import { Subject, throwError, of } from 'rxjs';
import { takeUntil, debounceTime, tap, switchMap, finalize, catchError } from 'rxjs/operators';


@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit {
  validateForm: FormGroup;
  accounts: IAccount[];

  passwordVisible = false;

  filteredAddresses: any;
  areAddressesLoading = false;

  constructor(private fb: FormBuilder,
    private authService: AuthService,
    private accountService: AccountService,
    private agentService: AgentsService,
    private placesService: PlacesService,
    private router: Router,
    private message: NzMessageService) {}

  ngOnInit(): void {
    this.validateForm = this.fb.group({
      account: ['CREATE_NEW', [Validators.required]],
      email: [null, [Validators.email, Validators.required]],
      password: [null, [Validators.required]],
      checkPassword: [null, [Validators.required, this.confirmationValidator]],
      name: [null, [Validators.required]],
      address: this.fb.group({
        address: [null, [Validators.required]],
        lat: [null, [Validators.required]],
        lng: [null, [Validators.required]]
      }),
      description: [null],
      role: ['BUYER', [Validators.required]]
    });

    this.accountService.getAll()
      .subscribe(resp => {
        console.log('resp', resp);
        this.accounts = resp;
      }, (error) => {
        console.error('Error', error);
        this.message.create('error', `Error: ${error.error.error}`);
      });
    this.onAddressChanges();
  }

  submitForm({value, valid}: {value: IAgent, valid: boolean}): void {
    // for (const i in this.validateForm.controls) {
    //   this.validateForm.controls[i].markAsDirty();
    //   this.validateForm.controls[i].updateValueAndValidity();
    // }

    if (valid) {
      this.authService.signup(value)
        .subscribe(resp => {
          const tempRole = resp?.agent?.role;
          resp.agent.role = {};
          resp.agent.role['type'] = tempRole;
          if (resp?.agent.account === 'CREATE_NEW') {
            resp.agent.account = {
              id: '',
              name: ''
            };
          }
          console.log('user creation resp', resp);
          this.agentService.get(resp.agent.publicKey)
            .subscribe((agent) => {
              if (agent) {
                resp.agent = agent;
                console.log('updated resp', resp);
                this.authService.setUser(resp);

                if (resp.agent.role.type === 'SUPPLIER') {
                  this.router.navigate(['/dashboard', {outlets: { dashboard: 'orders' }}]);

                } else if (agent.role.type === 'SERVICE_PROVIDER') {
                  this.router.navigate(['/service-provider', { outlets: { dashboard: 'orders' } }]);
                } else {
                  this.router.navigate(['/dashboard', {outlets: { dashboard: 'account' }}]);
                }
              }

            }, (error) => {
              console.error(`Error ${error.error.error}`);
          });

        }, (error) => {
          console.error('Error', error);
          this.message.create('error', `Error: ${error.error.error}`);
        });
    }
  }

  updateConfirmValidator(): void {
    /** wait for refresh value */
    Promise.resolve().then(() => this.validateForm.controls.checkPassword.updateValueAndValidity());
  }

  confirmationValidator = (control: FormControl): { [s: string]: boolean } => {
    if (!control.value) {
      return { required: true };
    } else if (control.value !== this.validateForm.controls.password.value) {
      return { confirm: true, error: true };
    }
    return {};
  }

  getCaptcha(e: MouseEvent): void {
    e.preventDefault();
  }

  onAddressSelection(event) {
    if (event.isUserInput) {
      const value = event.source.nzValue;
      if (value) {
        this.placesService.get(value.place_id)
          .subscribe((place) => {
            console.log('place', place);
            const address = place.result.formatted_address;
            const lat = place.result.geometry.location.lat;
            const lng = place.result.geometry.location.lng;
            this.validateForm.get('address').get('address').setValue(address);
            this.validateForm.get('address').get('lat').setValue(lat);
            this.validateForm.get('address').get('lng').setValue(lng);
          }, (error) => {
            console.error('Error', error);
          });
      }
    }
  }

  onInput(event: Event): void {
    const value = (event.target as HTMLInputElement).value;
    /* this.searchAddresses(value); */
  }

  compareFun = (o1: any, o2: any) => {
    console.log('o1', o1, 'o2', o2);
    if (o1 && o2) {
      return o1.description === o2.description;
    } else {
      return false;
    }
  }

  onAddressChanges() {
    this.validateForm.get('address').get('address').valueChanges
      .pipe(
        debounceTime(1000),
        tap(() => {
          this.filteredAddresses = [];
          this.areAddressesLoading = true;
        }),
        switchMap(value => {
            return this.placesService.search(value)
            .pipe(
              finalize(() => {
                this.areAddressesLoading = false;
              })
            )}
        ),
        catchError(error => throwError(error))
      )
      .subscribe((addresses: any) =>{
        if (!addresses) {
          this.filteredAddresses = [];
        } else {
          this.filteredAddresses = addresses;
        }
      }, (error) => {
        console.error('Error', error);
      });
  }

}
