import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';

import { getUrl } from './utils';
import { BasePlasmaService } from './base.plasma';

import { IAgent } from '@plasma/models/agent';


@Injectable({
  providedIn: 'root'
})
export class AgentsService extends BasePlasmaService {

  protected namespace = 'agents';

  constructor(protected http: HttpClient) {
    super(http);
  }

  public create(agent: IAgent) {
    return this.makePost(getUrl(this.namespace), agent) as Observable<any>;
  }

  public get(key: string) {
    return this.makeGet(`${getUrl(this.namespace)}/${key}`) as Observable<any>;
  }

  public getAll() {
    return this.makeGet(getUrl(this.namespace)) as Observable<any>;
  }

  public getByRole(role: string) {
    return this.makeGet(`${getUrl(this.namespace)}?role=${role.toUpperCase()}`) as Observable<any>;
  }

  public getByAccount(account: string) {
   return this.makeGet(`${getUrl(this.namespace)}?account=${account}`) as Observable<any>;
  }

  public getByAccountAndRole(account: string, role: string) {
    return this.makeGet(`${getUrl(this.namespace)}?account=${account}&role=${role}`) as Observable<any>;
  }

  public getByAccountAndSubRole(account: string, subRole: string) {
    return this.makeGet(`${getUrl(this.namespace)}?account=${account}&sub_role=${subRole}`) as Observable<any>;
  }

  public update(agent: IAgent) {
    return this.makePatch(getUrl(this.namespace), agent) as Observable<any>;
  }

  public updateAgent(key: string, agent: IAgent) {
   return this.makePatch(`${getUrl(this.namespace)}/${key}`, agent) as Observable<any>;
  }

  public invite(body: any) {
    return this.makePost(`${getUrl(this.namespace)}/invite`, body) as Observable<any>;
  }

}
