<table mat-table #orderTable [dataSource]="orders" class="mat-elevation-z8" matSort>
  <ng-container matColumnDef="contractNo">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Contract No. </th>
    <td mat-cell *matCellDef="let order"> {{order.contract_no}} </td>
  </ng-container>

  <ng-template [ngIf]="user?.role?.type !== 'SUPPLIER'">
    <ng-container matColumnDef="accountNo">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Account No </th>
      <td mat-cell *matCellDef="let order"> {{order.account_no}} </td>
    </ng-container>

    <ng-container matColumnDef="projectCostCentreCode">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Project Cost Centre Code </th>
      <td mat-cell *matCellDef="let order"> {{order.project_cost_centre_code}} </td>
    </ng-container>
  </ng-template>

  <ng-container matColumnDef="orderNo">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Order No </th>
    <td mat-cell *matCellDef="let order"> <a [routerLink]="['/service-provider', { outlets: { dashboard: ['order', order?.id] } }]">{{ order?.order_no }}</a> </td>
  </ng-container>

  <ng-container matColumnDef="phase">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Phase </th>
    <td mat-cell *matCellDef="let order"> {{order?.phase?.number}} </td>
  </ng-container>

  <ng-container matColumnDef="noOfItems">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> No. of Items </th>
    <td mat-cell *matCellDef="let order" style="text-align: center"> {{order.assets?.length}} </td>
  </ng-container>

  <ng-container matColumnDef="agreedPrice">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Total Price </th>
    <td mat-cell *matCellDef="let order"> £{{ order?.agreed_price | number: '1.2-3' }} </td>
  </ng-container>

  <ng-container matColumnDef="destination">
    <th mat-header-cell *matHeaderCellDef mat-sort-header style="text-align: center">Destination</th>
    <td mat-cell *matCellDef="let order" style="text-align: center"> <img [matTooltip]="order?.destination + ' (click to copy)'" matTooltipPosition="above" (click)="copyToClipboard(order?.destination)" src="assets/img/location-question.png" style="width: 1.25em"></td>
  </ng-container>

  <ng-container matColumnDef="createdOn">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Created On </th>
    <td mat-cell *matCellDef="let order"> {{ order?.createdOn | date: 'yy-MM-dd, HH:mm' }} </td>
  </ng-container>

  <ng-container matColumnDef="status">
    <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
    <td mat-cell *matCellDef="let order"> {{ order?.is_completed ? 'Completed': 'In Complete' }} </td>
  </ng-container>

  <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
  <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
</table>


<!-- <nz-table #orderTable [nzData]="orders" [nzLoading]="showLoading">
  <thead>
    <tr>
      <th style="background: #4c4c4c; color: white; box-shadow: 5px 0 5px -2px #4c4c4c; z-index: 10;"><strong>Contract No</strong></th>
      <ng-template [ngIf]="user?.role?.type !== 'SUPPLIER'">
        <th style="background: #00a2a4; color: white; box-shadow: 5px 0 5px -2px #00a2a4; z-index: 9;"><strong>Account No</strong></th>
        <th style="background: #b5c435; color: white; box-shadow: 5px 0 5px -2px #b5c435; z-index: 8;"><strong>Project Cost Centre Code</strong></th>
      </ng-template>
      <ng-template [ngIf]="user?.role?.type === 'SUPPLIER'">
        <th style="background: #00a2a4; color: white; box-shadow: 5px 0 5px -2px #00a2a4; z-index: 9;"><strong>Ordering Company</strong></th>
      </ng-template>
      <th style="background: #046888; color: white; box-shadow: 5px 0 5px -2px #046888; z-index: 7;"><strong>Order No</strong></th>
      <th style="background: #004b62; color: white; box-shadow: 5px 0 5px -2px #004b62; z-index: 6;"><strong>Phase</strong></th>
      <th style="background: #4c4c4c; color: white; box-shadow: 5px 0 5px -2px #4c4c4c; z-index: 5;"><strong>No. of Items</strong></th>
      <th style="background: #00a2a4; color: white; box-shadow: 5px 0 5px -2px #00a2a4; z-index: 4;"><strong>Total Price</strong></th>
      <th style="background: #b5c435; color: white; box-shadow: 5px 0 5px -2px #b5c435; z-index: 3;"><strong>Destination</strong></th>
      <th style="background: #046888; color: white; box-shadow: 5px 0 5px -2px #046888; z-index: 2;"><strong>Created On</strong></th>
      <th style="background: #004b62; color: white; box-shadow: 5px 0 5px -2px #004b62; z-index: 1;"><strong>Status</strong></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let order of orderTable.data let i = index;" [ngStyle]="{'background-color': (i % 2 == 0) ? '#f3f3f3' : '#ffffff' }">
      <td style="color: #4c4c4c;" [style.border-right]="'1px solid #d7d7d7'" [style.box-shadow]="(i+1)+'px 0 5px -2px #e2e2e2'">{{ order?.contract_no }}</td>
      <ng-template [ngIf]="user?.role?.type !== 'SUPPLIER'">
        <td style="color: #00a2a4;" [style.border-right]="'1px solid #d7d7d7'" [style.box-shadow]="(i+1)+'px 0 5px -2px #e2e2e2'">{{ order?.account_no }}</td>
        <td style="color: #b5c435;" [style.border-right]="'1px solid #d7d7d7'" [style.box-shadow]="(i+1)+'px 0 5px -2px #e2e2e2'">{{ order?.project_cost_centre_code }}</td>
      </ng-template>
      <ng-template [ngIf]="user?.role?.type === 'SUPPLIER'">
        <td style="color: #00a2a4;" [style.border-right]="'1px solid #d7d7d7'" [style.box-shadow]="(i+1)+'px 0 5px -2px #e2e2e2'">{{ order?.createdBy?.account?.name }}</td>
      </ng-template>
      <td style="color: #046888;" [style.border-right]="'1px solid #d7d7d7'" [style.box-shadow]="(i+1)+'px 0 5px -2px #e2e2e2'"><a [routerLink]="['/service-provider', { outlets: { dashboard: ['order', order?.id] } }]">{{ order?.order_no }}</a></td>
      <td style="color: #004b62;" [style.border-right]="'1px solid #d7d7d7'" [style.box-shadow]="(i+1)+'px 0 5px -2px #e2e2e2'">{{ order?.phase?.number }}</td>
      <td style="color: #4c4c4c;" [style.border-right]="'1px solid #d7d7d7'" [style.box-shadow]="(i+1)+'px 0 5px -2px #e2e2e2'">{{ order?.assets?.length }}</td>
      <td style="color: #00a2a4;" [style.border-right]="'1px solid #d7d7d7'" [style.box-shadow]="(i+1)+'px 0 5px -2px #e2e2e2'">£{{ order?.agreed_price | number: '1.2-3' }}</td>
      <td style="color: #b5c435;" [style.border-right]="'1px solid #d7d7d7'" [style.box-shadow]="(i+1)+'px 0 5px -2px #e2e2e2'">{{ order?.destination }}</td>
      <td style="color: #046888;" [style.border-right]="'1px solid #d7d7d7'" [style.box-shadow]="(i+1)+'px 0 5px -2px #e2e2e2'">{{ order?.createdOn | date: 'yyyy-MM-dd, h:mm a' }}</td>
      <td style="color: #004b62;" [style.border-right]="'1px solid #d7d7d7'" [style.box-shadow]="(i+1)+'px 0 5px -2px #e2e2e2'">{{ order?.is_completed ? 'Completed': 'In Complete' }}</td>
    </tr>
  </tbody>
</nz-table> -->
  