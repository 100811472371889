<nz-table #assetTable [nzData]="assets" [nzLoading]="showLoading">
  <thead>
    <tr>
      <th>Id</th>
      <th>Item Name</th>
      <th>Quantity</th>
      <th>Price</th>
      <th>Order No</th>
      <th>Supplier</th>
      <th>Delivery Date</th>
      <!-- <th>Action</th> -->
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let asset of assetTable.data">
      <td><a [routerLink]="[asset?.id]">{{ asset?.id }}</a></td>
      <td>{{ asset?.asset_type === 'reco_walls' ? 'Reco Walls' : asset?.asset_type | titlecase }}</td>
      <td>{{ asset?.quantity }}</td>
      <td>£{{ asset?.price | number:'1.0-3' }}</td>
      <td>{{ asset?.order?.order_no }}</td>
      <td>{{ asset?.supplier?.name }}</td>
      <td>{{ asset?.planned_dates?.planned_delivery_date | date: 'yyyy-MM-dd, h:mm a' }}</td>
      <!-- <td><button nzTooltipTitle="delete" nzTooltipPlacement="top" nzShape="circle" nz-button nzType="link" nz-tooltip (click)="delete(asset?.id)"><i nz-icon nzType="delete"></i></button></td> -->
      <!-- <td>{{ asset?.planned_dates?.planned_delivery_date }}</td> -->
      <!-- <td>
        <a>Action 一 {{ data.name }}</a>
        <nz-divider nzType="vertical"></nz-divider>
        <a>Delete</a>
      </td> -->
    </tr>
  </tbody>
</nz-table>
