import { Injectable } from '@angular/core';
import {
 CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot,
 CanActivateChild, Router
} from '@angular/router';

import { AuthService } from './auth.plasma';


@Injectable()
export class AuthGuardService implements CanActivate, CanActivateChild {

  constructor(
    private authService: AuthService,
    private router: Router
  ) {}

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.checkLogin(state.url);
  }

  public canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    return this.canActivate(route, state);
  }

  private checkLogin(url: string) {
    let result = true;
    if (!url.startsWith('/login') || !url.startsWith('/signup')) {
      result = this.authService.isLoggedIn;
    }

    if (!result) {
      this.router.navigate(['/login']);
    }

    return result;
  }

}
