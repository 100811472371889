import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';

import { getUrl } from './utils';
import { BasePlasmaService } from './base.plasma';


@Injectable({
  providedIn: 'root'
})
export class PhaseService extends BasePlasmaService {

  protected namespace = 'phases';

  constructor(protected http: HttpClient) {
    super(http);
  }

  public create(phase: any) {
    return this.makePost(getUrl(this.namespace), phase) as Observable<any>;
  }

  public getAll(account: string, project: string) {
    let url = `${getUrl(this.namespace)}`;
    if (account) {
      url = `${url}?account=${account}`;
    }
    if (project) {
      url = `${url}&project=${project}`;
    }
    return this.makeGet(url) as Observable<any>;
  }

  public getAllByStage(stage: string) {
    return this.makeGet(`${getUrl(this.namespace)}?stage=${stage}`) as Observable<any>;
  }

  public get(id) {
    return this.makeGet(`${getUrl(this.namespace)}/${id}`) as Observable<any>;
  }

  public update(phase: any) {
    return this.makePatch(getUrl(this.namespace), phase) as Observable<any>;
  }

  public delete(id) {
    return this.makeDelete(`${getUrl(this.namespace)}/${id}`) as Observable<any>;
  }

}
