import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormArray, FormGroup, Validators } from '@angular/forms';
import { NzModalRef } from 'ng-zorro-antd/modal';

import { IAgent } from '@plasma/models/agent';

import { v4 as uuidv4 } from 'uuid';
import * as moment from 'moment';


@Component({
  selector: 'app-create-project-modal',
  templateUrl: './create-project-modal.component.html'
})
export class CreateProjectModalComponent implements OnInit {

  @Input() user: IAgent;
  @Input() userList: IAgent[] = [];
  project: FormGroup;

  constructor(private modal: NzModalRef, private fb: FormBuilder) { }

  ngOnInit(): void {
    this.project = this.fb.group({
      id: [uuidv4(), [Validators.required]],
      account: [this.user?.account?.id, [Validators.required]],
      projectId: [null, [Validators.required]],
      name: [null, [Validators.required]],
      description: [null],
      noOfStages: [null, [Validators.required]],
      stages: this.fb.array([]),
      assignedProjectOwners: [[this.user?.publicKey], [Validators.required]],
      ownerId: [this.user?.publicKey],
      ownerName: [this.user?.name],
      createdOn: [null]
    });

    this.onStagesChange();
  }

  isNotSelected(value: IAgent): boolean {
    return this.project.get('assignedProjectOwners').value.indexOf(value) === -1;
  }

  onStagesChange() {
    this.project.get('noOfStages').valueChanges.subscribe((stages) => {
      if (!stages) {
        return;
      }
      this.createStageNameAndPhaseControl(stages);
    });
  }

  createStageNameAndPhaseControl(stages: number) {
    if (!stages) {
      return;
    }
    const stagesFormArray = this.project.get('stages') as FormArray;
    if (stagesFormArray.length > stages) {
      while (stagesFormArray.length > stages) {
        stagesFormArray.removeAt(stagesFormArray.length - 1);
      } 
    } else {
      while (stagesFormArray.length < stages) {
        stagesFormArray.push(this.createStageFormGroup());
      }
    }
    
    
  }

  createStageFormGroup() {
    return this.fb.group({
      name: [null, [Validators.required]],
      phases: [null, [Validators.required]],
      sequence: [null, [Validators.required]]
    });
  }

  createProject(): void {
    const {value, valid} = this.project;
    console.log('value', value);
    if (valid) {
      value['createdOn'] = moment().valueOf();
      this.modal.destroy(value);
    }
  }

}
