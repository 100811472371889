<div fxLayout="column" fxLayoutAlign="start center" class="item-container">
    <div fxLayout="column" fxLayoutAlign="center center" class="item-container">
        <div>
            <h3>Help</h3>
        </div>
        <ul>
            <li (click)="openDialog('registration')">
                <div class="icon-container" id="icon-blue"><fa-icon icon="user-plus" size="2x"></fa-icon></div>
                <h3>Registration</h3>
            </li>
            <li (click)="openDialog('project')">
                <div class="icon-container" id="icon-red"><fa-icon icon="pen" size="2x"></fa-icon></div>
                <h3>Create Project</h3>
            </li>
            <li (click)="openDialog('order')">
                <div class="icon-container" id="icon-yellow"><fa-icon icon="pen" size="2x"></fa-icon></div>
                <h3>Create Order</h3>
            </li>
            <li (click)="openDialog('viewOrder')">
                <div class="icon-container" id="icon-green"><fa-icon icon="eye" size="2x"></fa-icon></div>
                <h3>View Orders</h3>
            </li>
            <li (click)="openDialog('trackingIntelligence')">
                <div class="icon-container" id="icon-red"><fa-icon icon="truck-moving" size="2x"></fa-icon></div>
                <h3>Tracking Intelligence</h3>
            </li>
            <li (click)="openDialog('linkBarcode')">
                <div class="icon-container" id="icon-blue"><fa-icon icon="qrcode" size="2x"></fa-icon></div>
                <h3>Link Barcode/QR Code</h3>
            </li>
            <li (click)="openDialog('paymentAuthorization')">
                <div class="icon-container" id="icon-green"><fa-icon icon="money-bill" size="2x"></fa-icon></div>
                <h3>Payment Authorization</h3>
            </li>
            <li (click)="openDialog('provenance')">
                <div class="icon-container" id="icon-yellow"><fa-icon icon="tree" size="2x"></fa-icon></div>
                <h3>Provenance / Traceability</h3>
            </li>
            <li (click)="openDialog('uploadDocuments')">
                <div class="icon-container" id="icon-yellow"><fa-icon icon="file-upload" size="2x"></fa-icon></div>
                <h3>Upload Documents</h3>
            </li>
            <li (click)="openDialog('linkedOrders')">
                <div class="icon-container" id="icon-green"><fa-icon icon="link" size="2x"></fa-icon></div>
                <h3>Linked Orders</h3>
            </li>
            <li (click)="openDialog('projectStatus')">
                <div class="icon-container" id="icon-blue"><fa-icon icon="info-circle" size="2x"></fa-icon></div>
                <h3>Project Status</h3>
            </li>
            <li (click)="openDialog('suppliersStatus')">
                <div class="icon-container" id="icon-red"><fa-icon icon="info-circle" size="2x"></fa-icon></div>
                <h3>Suppliers Status</h3>
            </li>
            <li (click)="openDialog('inventory')">
                <div class="icon-container" id="icon-red"><fa-icon icon="warehouse" size="2x"></fa-icon></div>
                <h3>Inventory</h3>
            </li>
            <!-- <li>
                <a (click)="openDialog('suppliersNetwork')">
                    <div class="icon-container" id="icon-blue"><fa-icon icon="project-diagram" size="2x"></fa-icon></div>
                    <h3>Suppliers Network</h3>
                </a>
            </li> -->
        </ul>
    </div>
    
    <!--<div fxLayout="column" fxLayoutAlign="center center">
        <div>
            <h3 style="margin-top: 10px;">Mobile App</h3>
        </div>
        <ul>
            <li (click)="openDialog('installation', true)">
                <div class="icon-container" id="icon-red" style="margin: 1.75rem 0 1.25rem 0.95rem;"><fa-icon icon="file-download" size="2x"></fa-icon></div>
                <h3>Installation</h3>
            </li>
            <li (click)="openDialog('login', true)">
                <div class="icon-container" id="icon-blue" style="margin: 1.75rem 0 1.25rem 0.95rem;"><fa-icon icon="sign-in-alt" size="2x"></fa-icon></div>
                <h3>Log In</h3>
            </li>
            <li (click)="openDialog('scanBarcode', true)">
                <div class="icon-container" id="icon-yellow" style="margin: 1.75rem 0 1.25rem 0.95rem;"><fa-icon icon="barcode" size="2x"></fa-icon></div>
                <h3>Scan Barcode</h3>
            </li>
            <li (click)="openDialog('uploadPictures', true)">
                <div class="icon-container" id="icon-green" style="margin: 1.75rem 0 1.25rem 0.95rem;"><fa-icon icon="file-upload" size="2x"></fa-icon></div>
                <h3>Upload Pictures</h3>
            </li>
        </ul>
    </div>-->
</div>



