<nz-page-header class="site-page-header" (nzBack)="onBack()" nzBackIcon>
  <nz-page-header-title style="color: #046888;"><strong>Order</strong></nz-page-header-title>
</nz-page-header>
<!-- <div fxLayout="row" fxLayoutAlign="start center" style="background-color: #0d47a1; margin-bottom: 20px">
    
  <div fxLayoutAlign="center center" style="background-color: #2196f3; padding: 10px 10px 0 10px;">
    <button mat-icon-button class="example-icon" style="color: white;" aria-label="Example icon-button with menu icon" fxFlexFill>
      <fa-icon [icon]="['fas', 'list-alt']" size="2x"></fa-icon>
    </button>
  </div>
  
  <div fxLayoutAlign="center center" style="padding-left: 1em;">
    <span fxFlexFill><h3 class="mat-h2" style="margin-bottom: 0; color: white;">Order</h3></span>
    <span class="example-spacer"></span>
  </div>

</div> -->
<ng-container *ngIf="order">
  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between center">
    <div fxLayoutAlign="center center" id="field-container">
      <mat-form-field appearance="fill" fxFlexFill>
        <mat-label>Contract No</mat-label>
        <input matInput readonly [ngModel]="order?.contract_no">
      </mat-form-field>
    </div>
    
    <div fxLayoutAlign="center center" id="field-container">
      <mat-form-field appearance="fill" fxFlexFill>
        <mat-label>Account No</mat-label>
        <input matInput readonly [ngModel]="order?.account_no">
      </mat-form-field>
    </div>
    
    <div fxLayoutAlign="center center" id="field-container">
      <mat-form-field appearance="fill" fxFlexFill>
        <mat-label>Project Cost Centre Code</mat-label>
        <input matInput readonly [ngModel]="order?.project_cost_centre_code">
      </mat-form-field>
    </div>
  </div>

  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between center">
    <div fxLayoutAlign="center center" id="field-container">
      <mat-form-field appearance="fill" fxFlexFill>
        <mat-label>Order No</mat-label>
        <input matInput readonly [ngModel]="order?.order_no">
      </mat-form-field>
    </div>
    
    <div fxLayoutAlign="center center" id="field-container">
      <mat-form-field appearance="fill" fxFlexFill>
        <mat-label>Confirmation Order</mat-label>
        <input matInput readonly [ngModel]="order?.confirmation_order">
      </mat-form-field>
    </div>

    <div fxLayoutAlign="center center" id="field-container">
      <mat-form-field appearance="fill" fxFlexFill>
        <mat-label>ID</mat-label>
        <input matInput readonly [ngModel]="order?.bim">
      </mat-form-field>
    </div>
  </div>

  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between center">
    <div fxLayoutAlign="center center" id="field-container">
      <mat-form-field appearance="fill" fxFlexFill>
        <mat-label>Supervisor</mat-label>
        <input matInput readonly [ngModel]="order?.supervisor?.name">
      </mat-form-field>
    </div>
    
    <div fxLayoutAlign="center center" id="field-container">
      <mat-form-field appearance="fill" fxFlexFill>
        <mat-label>Created On</mat-label>
        <input matInput readonly [value]="order?.createdOn | date: 'yyyy-MM-dd, h:mm a'">
      </mat-form-field>
    </div>
    
    <div fxLayoutAlign="center center" id="field-container">
      <mat-form-field appearance="fill" fxFlexFill>
        <mat-label>Updated On</mat-label>
        <input matInput readonly [ngModel]="order?.updatedOn | date: 'yyyy-MM-dd, h:mm a'">
      </mat-form-field>
    </div>
  </div>

  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between center">
    <div fxLayoutAlign="center center" id="field-container">
      <mat-form-field appearance="fill" fxFlexFill>
        <mat-label>Agreed Price</mat-label>
        <span matPrefix>&pound;</span>
        <input matInput readonly [ngModel]="order?.agreed_price | number: '1.0-3'">
      </mat-form-field>
    </div>
    
    <div fxLayoutAlign="center center" id="field-container">
      <mat-form-field appearance="fill" fxFlexFill>
        <mat-label>Internal Order</mat-label>
        <input matInput readonly [value]="order?.internal_order ? 'Yes' : 'No'">
      </mat-form-field>
    </div>
    
    <div fxLayoutAlign="center center" id="field-container">
      <mat-form-field appearance="fill" fxFlexFill>
        <mat-label>Destination</mat-label>
        <input matInput readonly [ngModel]="order?.destination">
      </mat-form-field>
    </div>
  </div>

  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between center">
    <div fxLayoutAlign="center center" id="field-container">
      <mat-form-field appearance="fill" fxFlexFill>
        <mat-label>Payment Terms</mat-label>
        <input matInput readonly [ngModel]="order?.payment_terms">
      </mat-form-field>
    </div>
    
    <div fxLayoutAlign="center center" id="field-container">
      <mat-form-field appearance="fill" fxFlexFill>
        <mat-label>Shipment Method Dates</mat-label>
        <input matInput readonly [ngModel]="order?.shipment_method_dates">
      </mat-form-field>
    </div>
    
    <div fxLayoutAlign="center center" id="field-container">
      <mat-form-field appearance="fill" fxFlexFill>
        <mat-label>Technical Details</mat-label>
        <input matInput readonly [ngModel]="order?.technical_details">
      </mat-form-field>
    </div>
  </div>

  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between center">
    <div fxLayoutAlign="center center" id="field-container">
      <mat-form-field appearance="fill" fxFlexFill>
        <mat-label>Special Instructions</mat-label>
        <input matInput readonly [ngModel]="order?.special_instructions">
      </mat-form-field>
    </div>
    
    <div fxLayoutAlign="center center" id="field-container">
      <mat-form-field appearance="fill" fxFlexFill>
        <mat-label>Discount</mat-label>
        <input matInput readonly [value]="order?.discount !== 0 ? order?.discount : ''">
      </mat-form-field>
    </div>
    
    <div fxLayoutAlign="center center" id="field-container">
      <mat-form-field appearance="fill" fxFlexFill>
        <mat-label>SKU</mat-label>
        <input matInput readonly [ngModel]="order?.sku">
      </mat-form-field>
    </div>
  </div>

  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between center">
    <div fxLayoutAlign="center center" id="field-container">
      <mat-form-field appearance="fill" fxFlexFill>
        <mat-label>Description</mat-label>
        <input matInput readonly [ngModel]="order?.description">
      </mat-form-field>
    </div>
    
    <div fxLayoutAlign="center center" id="field-container">
      <mat-form-field appearance="fill" fxFlexFill>
        <mat-label>Billing Address</mat-label>
        <input matInput readonly [ngModel]="order?.billing_address">
      </mat-form-field>
    </div>

    <div fxLayoutAlign="center center" id="field-container">
      <ng-container *ngIf="order.is_requisition">
        <mat-form-field appearance="fill" fxFlexFill>
          <mat-label>Status</mat-label>
          <input matInput readonly [ngModel]="order?.is_completed ? 'Complete' : 'Incomplete'">
        </mat-form-field>
      </ng-container>
    </div>

  </div>
  
  <div class="form-row mt-3" *ngIf="order?.documents?.length > 0">
    <div class="col-md-12">
      <div class="form-group row document-row">
        <div class="col-md-12"></div>
        <div class="col-md-12">
          <div class="row documents">
            <div class="col-md-12 document-text" *ngFor="let document of order?.documents">
              <a nz-button download target="_blank" [href]="document" nzType="link">
                <fa-icon [icon]="['fas', (document?.split('.')[4] === 'docx' || document?.split('.')[4] === 'doc' || document?.split('.')[4] === 'dto') ? 'file-word' : (document?.split('.')[4] === 'pdf' ? 'file-pdf': 'file-alt')]" size="2x"></fa-icon>
              </a>
              <p style="color: black;">{{extractFilename(document)}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div fxLayout="column" fxLayout.lt-md="column" fxLayoutAlign="center center">
    <div fxLayoutAlign="center center" id="field-container" fxFlexFill>
      <nz-upload fxFlexFill nzType="drag" class="upload upload-list-inline" nzListType="picture" [(nzFileList)]="stageFiles" [nzBeforeUpload]="beforeUpload" [nzMultiple]="true">
        <p class="ant-upload-drag-icon">
          <i nz-icon nzType="inbox"></i>
        </p>
        <p class="ant-upload-text">Click or drag file to this area to upload</p>
        <p class="ant-upload-hint">
          Support for a single or bulk upload. Strictly prohibit from uploading company data or other band files
        </p>
      </nz-upload>
      
    </div>
    <div fxLayoutAlign="center center" id="field-container" style="margin: 10px 0;">
      <button
        fxFlexFill
        nz-button
        [nzType]="'primary'"
        [nzLoading]="uploading"
        (click)="handleUpload($event)"
        [disabled]="stageFiles.length == 0"
        style="margin-top: 16px"
      >
        {{ uploading ? 'Uploading' : 'Start Upload' }}
      </button>
    </div>

  </div>
</ng-container>
