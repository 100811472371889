<div class="clearfix">
  <nz-upload nzType="drag" nzAction="https://www.mocky.io/v2/5cc8019d300000980a055e76" [nzMultiple]="false" [nzFileList]="fileList" [nzBeforeUpload]="beforeUpload" nzListType="picture" (nzChange)="handleChange($event)"
  >
    <p class="ant-upload-drag-icon">
      <i nz-icon nzType="inbox"></i>
    </p>
    <p class="ant-upload-text">Click or drag file to this area to upload</p>
  </nz-upload>
  <button
    nz-button
    [nzType]="'primary'"
    [nzLoading]="uploading"
    (click)="handleUpload($event)"
    [disabled]="fileList.length == 0"
    style="margin-top: 16px"
  >
    {{ uploading ? 'Uploading' : 'Start Upload' }}
  </button>
</div>
