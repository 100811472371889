import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { AgentsService } from '@plasma/services/agents.plasma';
import { AuthService } from '@plasma/services/auth.plasma';

import { IAgent } from '@plasma/models/agent';
import { IUser } from '@plasma/models/user';

import { SubRoleOptions } from '@data/base.reference';

import { NzMessageService } from 'ng-zorro-antd/message';
import { LoadingService } from '@plasma/components/loading/loading.service';


@Component({
  selector: 'app-user',
  templateUrl: './user.component.html',
  styleUrls: ['./user.component.scss']
})
export class UserComponent implements OnInit {

  id: string; // publicKey of the User/Agent
  subRoleOptions = SubRoleOptions;
  user: FormGroup;

  constructor(protected activatedRoute: ActivatedRoute,
              private fb: FormBuilder,
              private authService: AuthService,
              private agentService: AgentsService,
              private message: NzMessageService,
              private location: Location,
              private loadingService: LoadingService) { }

  ngOnInit(): void {

    this.id = this.activatedRoute.snapshot.paramMap.get('id');
    if (!this.id) {
      return;
    }

    this.user = this.fb.group({
      name: [{value: null, disabled: true}, [Validators.required]],
      address: this.fb.group({
        address: [null, [Validators.required]],
        lat: [null, [Validators.required]],
        lng: [null, [Validators.required]]
      }),
      description: [null],
      role: this.fb.group({
        type: [{value: null, disabled: true}, [Validators.required]]
      }),
      subRole: this.fb.group({
        type: [{value: null}, [Validators.required]]
      }),
      canAssignUsers: [null],
      canAuthorisePayment: [null],
      canCheckProvenance: [null],
      canInviteProjectOwners: [null],
      canInviteUsers: [null],
      canManageOrders: [null],
      canManageProjectOwners: [null],
      canManageProjects: [null],
      canManageUsers: [null],
      canTrackOrders: [null],
    });

    this.loadUser();

  }

  loadUser() {
    this.agentService.get(this.id)
      .subscribe((user: IAgent) => {
        if (user) {
          console.log('user', user);
          this.user.patchValue(user);
        }
      }, (error) => {
        console.error('Error', error);
        this.message.create('error', `Error: ${error.error.error}`);
      });
  }

  onSubmit({valid, value}: {valid: boolean, value: IAgent}) {
    console.log('value', value);

    if (valid) {
      (value.subRole as any) = value.subRole.type;
      this.loadingService.start();
      this.agentService.updateAgent(this.id, value)
        .subscribe((resp: IAgent) => {
          console.log('resp', resp);
          this.loadingService.complete();
          this.message.create('success', `${this.user.getRawValue()['name']} was updated successfully!`);
        }, (error) => {
          this.loadingService.complete();
          console.error('Error', error.error);
          this.message.create('error', `Error: ${error.error.error}`);
        });
    }
  }

  onBack() {
    this.location.back();
  }

  onSubRoleChange(subRole: string) {
    switch (subRole) {
      case 'USER':
        this.user.get('canAssignUsers').setValue(false);
        this.user.get('canAuthorisePayment').setValue(false);
        this.user.get('canCheckProvenance').setValue(true);
        this.user.get('canInviteProjectOwners').setValue(false);
        this.user.get('canInviteUsers').setValue(false);
        this.user.get('canManageOrders').setValue(false);
        this.user.get('canManageProjectOwners').setValue(false);
        this.user.get('canManageProjects').setValue(false);
        this.user.get('canManageUsers').setValue(false);
        this.user.get('canTrackOrders').setValue(true);
        break;

      case 'PROJECT_OWNER':
        this.user.get('canAssignUsers').setValue(true);
        this.user.get('canAuthorisePayment').setValue(true);
        this.user.get('canCheckProvenance').setValue(true);
        this.user.get('canInviteProjectOwners').setValue(false);
        this.user.get('canInviteUsers').setValue(true);
        this.user.get('canManageOrders').setValue(true);
        this.user.get('canManageProjectOwners').setValue(false);
        this.user.get('canManageProjects').setValue(true);
        this.user.get('canManageUsers').setValue(false);
        this.user.get('canTrackOrders').setValue(true);
        break;

      case 'ADMINISTRATOR':
        this.user.get('canAssignUsers').setValue(true);
        this.user.get('canAuthorisePayment').setValue(true);
        this.user.get('canCheckProvenance').setValue(true);
        this.user.get('canInviteProjectOwners').setValue(true);
        this.user.get('canInviteUsers').setValue(true);
        this.user.get('canManageOrders').setValue(true);
        this.user.get('canManageProjectOwners').setValue(true);
        this.user.get('canManageProjects').setValue(true);
        this.user.get('canManageUsers').setValue(true);
        this.user.get('canTrackOrders').setValue(true);
        break;

      default:
        this.user.get('canAssignUsers').setValue(false);
        this.user.get('canAuthorisePayment').setValue(false);
        this.user.get('canCheckProvenance').setValue(false);
        this.user.get('canInviteProjectOwners').setValue(false);
        this.user.get('canInviteUsers').setValue(false);
        this.user.get('canManageOrders').setValue(false);
        this.user.get('canManageProjectOwners').setValue(false);
        this.user.get('canManageProjects').setValue(false);
        this.user.get('canManageUsers').setValue(false);
        this.user.get('canTrackOrders').setValue(false);
        break;
    }
  }

}
