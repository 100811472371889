import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NzModalRef } from 'ng-zorro-antd/modal';

import { IAgent } from '@plasma/models/agent';

import { v4 as uuidv4 } from 'uuid';
import * as moment from 'moment';


@Component({
  selector: 'app-assign-users-modal',
  templateUrl: './assign-users-modal.component.html',
  styleUrls: ['./assign-users-modal.component.scss']
})
export class AssignUsersModalComponent implements OnInit {

  @Input() project: any;
  @Input() user: IAgent;
  @Input() users: IAgent[];
  proj: FormGroup;
  invitation: FormGroup;

  constructor(private modal: NzModalRef, private fb: FormBuilder) { }

  ngOnInit(): void {
    this.proj = this.fb.group({
      assignedUsers: [[], [Validators.required]],
      updatedOn: [null]
    });

    this.invitation = this.fb.group({
      inviteUser: [null, [Validators.required, Validators.email]]
    });

    this.proj.get('assignedUsers').patchValue(this.project.assignedUsers.map(user => user.publicKey));
  }

  isNotSelected(value: IAgent): boolean {
    return this.proj.get('assignedUsers').value.indexOf(value) === -1;
  }

  assignUsers(): void {
    const {value, valid} = this.proj;
    if (valid) {
      value['updatedOn'] = moment().valueOf();
      console.log('value', value);
      this.modal.destroy(value);
    }
  }

  inviteUser(): void {
    const { value, valid } = this.invitation;
    if (valid) {
      console.log('value', value);
      this.modal.destroy(value);
    }
  }

}
