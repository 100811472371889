import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';


@Component({
  selector: 'app-supplier-b',
  templateUrl: './supplier-b.component.html',
  styleUrls: ['./supplier-b.component.scss']
})
export class SupplierBComponent implements OnInit {

  isCollapsed = false;

  constructor() { }

  ngOnInit(): void {

  }

}
