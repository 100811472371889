<ng-template [ngIf]="stage">
  <nz-page-header class="site-page-header" (nzBack)="onBack()" nzBackIcon nzTitle="{{stage?.name + ' Status'}}"></nz-page-header>

  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between center">
    <div fxLayout="column" fxLayoutAlign="center center" id="field-container">
        <h5>Orders Rejected and Damaged</h5>
        <div echarts [initOpts]="initOpts" [options]="rejectedDamagedAssetsChart" class="demo-chart"></div>
    </div>
    
    <div fxLayout="column" fxLayoutAlign="center center" id="field-container">
      <h5>Orders On Time, Under Float Days, and Delayed</h5>
      <div echarts [initOpts]="initOpts" [options]="stackedBarChart" class="demo-chart"></div>
      <!-- <div echarts [options]="nestedPieChart" class="demo-chart"></div> -->
    </div>
  </div>

  <ng-template [ngIf]="stage?.phases?.length > 0">
    <nz-row>
      <nz-col [nzSpan]="24">
        <div class="circle" [ngClass]="stage?.floatStatus === 0 ? 'green' : (stage?.floatStatus === 1 ? 'yellow' : 'red')"></div>
        <p style="font-size: 0.9rem"><strong>Status</strong> - Red means one or more orders are under critical date, yellow means one order is under critical date, green means all orders are within planned delivery date.</p>
      </nz-col>
    </nz-row>
  </ng-template>

  <!-- <ng-template [ngIf]="stage.floatStatus == 2">
    <nz-table #orderTable [nzData]="delayedOrders">
      <thead>
        <tr>
          <th>Contract No</th>
          <th>Account No</th>
          <th>Project Cost Centre Code</th>
          <th>Order No</th>
          <th>Phase</th>
          <th>No. of Items</th>
          <th>Total Price</th>
          <th>Destination</th>
          <th>Created On</th>
          <th>Status</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let order of orderTable.data">
          <td>{{ order?.contract_no }}</td>
          <td>{{ order?.account_no }}</td>
          <td>{{ order?.project_cost_centre_code }}</td>
          <td><a [routerLink]="['/dashboard', { outlets: { dashboard: ['order', order?.id] } }]">{{ order?.order_no }}</a></td>
          <td>{{ order?.phase?.name }}</td>
          <td>{{ order?.assets?.length }}</td>
          <td>£{{ order?.agreed_price | number: '1.2-3' }}</td>
          <td>{{ order?.destination }}</td>
          <td>{{ order?.createdOn | date: 'yyyy-MM-dd, h:mm a' }}</td>
          <td><div class="circle" [ngClass]="order?.floatStatus === 0 ? 'green' : (order?.floatStatus === 1 ? 'yellow' : 'red')"></div></td>
        </tr>
      </tbody>
    </nz-table>
  </ng-template> -->
</ng-template>
