import { Component, OnInit, ElementRef, ViewChild, OnDestroy } from '@angular/core';
import { FormBuilder, FormControl, FormArray, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatChipInputEvent } from '@angular/material/chips';
import { Observable } from 'rxjs';
import { map, startWith } from 'rxjs/operators';

import { OrderService } from '@plasma/services/order.plasma';
import { AgentsService } from '@plasma/services/agents.plasma';
import { ProjectService } from '@plasma/services/project.plasma';
import { StageService } from '@plasma/services/stage.plasma';
import { PhaseService } from '@plasma/services/phase.plasma';
import { AuthService } from '@plasma/services/auth.plasma';

import { IOrder } from '@plasma/models/order';
import { IAgent } from '@plasma/models/agent';
import { ISupplier } from '@plasma/models/supplier';
import { ORDERS } from '@data/order.reference';
import { SUPPLIERS } from '@data/supplier.reference';
import { AssetType, AssetTypeOptions } from '@data/base.reference';

import { v4 as uuidv4 } from 'uuid';

import { NzMessageService } from 'ng-zorro-antd/message';

import * as moment from 'moment';
import { LoadingService } from '@plasma/components/loading/loading.service';

import { Subject } from 'rxjs';
import { takeUntil, debounceTime, tap, switchMap, finalize, take } from 'rxjs/operators';
import { PlacesService } from '@plasma/services/places.plasma';


@Component({
  selector: 'app-create-order',
  templateUrl: './create-order.component.html',
  styleUrls: ['./create-order.component.scss']
})
export class CreateOrderComponent implements OnInit, OnDestroy {

  order: FormGroup;
  suppliers: any[];
  assets: FormArray;
  user: IAgent;
  projectId: string;
  stages: any[];
  phases: any[];
  assetTypeOptions = AssetTypeOptions;
  orders: any[];

  // Chips Autocomplete - Linked Orders
  separatorKeysCodes: number[] = [ENTER, COMMA];
  linkedOrderCtrl = new FormControl();
  filteredOrders: Observable<string[]>;
  selectedOrders: any[] = [];

  projects: any = [];

  @ViewChild('linkedOrderInput') linkedOrderInput: ElementRef<HTMLInputElement>;

  filteredDeliveryLocation: any;
  areDeliveryLocationLoading = false;

  private destroy$ = new Subject<void>();

  constructor(private fb: FormBuilder,
              private activatedRoute: ActivatedRoute,
              private orderService: OrderService,
              private agentService: AgentsService,
              private projectService: ProjectService,
              private stageService: StageService,
              private phaseService: PhaseService,
              private authService: AuthService,
              private message: NzMessageService,
              private loadingService: LoadingService,
              private placesService: PlacesService, 
              private location: Location) { }

  ngOnInit(): void {

    this.agentService.getByRole('supplier')
      .subscribe((agents) => {
        if (agents) {
          console.log('suppliers', agents);
          this.suppliers = agents;
        } else {
          console.error('No agents exist');
        }
      }, (error) => {
        console.error('Error', error.error);
        this.message.create('error', `Error: ${error.error}`);
    });


    this.order = this.fb.group({
      project: [null],
      stage: [null],
      phase: [null],
      contractNo: ['MA0001', [Validators.required]],
      accountNo: ['GALL023', [Validators.required]],
      projectCostCentreCode: ['PCC01', [Validators.required]],
      orderNo: ['PO01', [Validators.required]],
      confirmationOrder: ['CO01', [Validators.required]],
      supplier: [null, [Validators.required]],
      bim: [null],
      assets: this.fb.array([this.createAsset()]),
      agreedPrice: [0.0, [Validators.required]],
      internalOrder: [false],
      hasLinkedOrders: [false],
      linkedOrders: [[]],
      deliveryLocation: ['London, United Kingdom', [Validators.required]],
      completionDate: [new Date(), [Validators.required]],
      description: [null],
      paymentTerms: [null],
      shipmentMethodDates: [null],
      technicalDetails: [null],
      specialInstructions: [null],
      discounts: [null],
      sku: [null],
      billingAddress: [null, [Validators.required]],
      review: [true]
    });

    this.authService.getUser()
      .pipe(takeUntil(this.destroy$))
      .subscribe((user: IAgent) => {
        if (user) {
          console.log('user', user);
          this.user = user;
          this.order.get('billingAddress').patchValue(user.address.address);
          this.order.get('deliveryLocation').patchValue(user.address.address);

          this.projectService.getAll(this.user?.account?.id)
            .subscribe((projects) => {
              this.projects = projects;
            }, (error) => {
              console.error(`Error ${error.error}`);
              this.message.create('error', `Error: ${error.error.error}`);
          });
        }
      });

    this.filteredOrders = this.linkedOrderCtrl.valueChanges.pipe(
      startWith(null),
      map((order: any | null) => (order ? this._filter(order) : this.orders.slice())),
    );

    this.loadProjectOrders();
    this.onChanges();
    this.onDeliveryLocationChange();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  loadProjectOrders() {
    this.orderService.getByAccount(this.user.account.id)
      .subscribe((orders) => {
        this.orders = orders;
      }, (error) => {
        console.error('Error', error);
        // this.message.create('error', `Error: ${error.error}`);
      });
  }

  isNotSelected(value): boolean {
    return this.order.get('linkedOrders').value.indexOf(value) === -1;
  }

  /* Chips Autocomplete Begins */

  linkOrder(event: MatChipInputEvent) {
    console.log('linkOrder', event.value);
    const value = (event.value || '').trim();
    console.log('linkOrder', value);

    // Add our order
    if (value) {
      this.selectedOrders.push(value);
      this.order.get('linkedOrders').value.push((value as any).id);
    }

    // Clear the input value
    // event.chipInput!.clear();
    if (event.input) {
      event.input.value = null;
    }

    this.linkedOrderCtrl.setValue(null);
  }

  remove(order): void {
    console.log('order remove', order);
    const index = this.selectedOrders.indexOf(order);
    const linkedOrdersindex = this.order.get('linkedOrders').value.indexOf(order.id);
    console.log('selectedOrders', this.selectedOrders);
    console.log('index', index);
    // console.log('index', this.selectedOrders.map((value, i) => {
    //   if (value.id === order.id) {
    //     return true
    //   }
    // }));

    if (index >= 0) {
      this.order.get('linkedOrders').value.splice(linkedOrdersindex, 1);
      this.selectedOrders.splice(index, 1);
      console.log('after remove', this.selectedOrders);
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    console.log('order selected', event.option.value);
    const selectedOrder = this.orders.filter(value => value.id === event.option.value);
    this.order.get('linkedOrders').value.push(selectedOrder[0].id);
    this.selectedOrders.push(selectedOrder[0]);
    this.linkedOrderInput.nativeElement.value = '';
    this.linkedOrderCtrl.setValue(null);
  }

  private _filter(value): string[] {

    return this.orders.filter(order => order.id === value);
  }

  /* Chips Autocomplete Ends */

  addItem(e): void {
    e.preventDefault();
    this.assets = this.order.get('assets') as FormArray;
    this.assets.push(this.createAsset());
  }

  createAsset(): FormGroup {

    let asset = this.fb.group({
      assetId: [uuidv4(), [Validators.required]],
      assetType: [null, [Validators.required]],
      multipleItems: [false],
      description: [null],
      floatDays: [null],
      uoq: [null],
      quantity: [null, [Validators.required]],
      unit: [null],
      price: [null, { validators: [Validators.required], updateOn: 'blur'}],
      discount: [null],
      plannedDates: this.fb.group({
        plannedDeliveryDate: [new Date(), [Validators.required]]
      })
    });

    return asset;
  }

  removeItem(e, index): void {
    e.preventDefault();
    if (this.assets && (index > this.assets.length) || (index + 1) === this.assets.length) {
      return;
    }
    this.assets.removeAt(index);
  }

  onChanges() {
    this.order.get('assets').valueChanges
      .subscribe((assets) => {
        let totalCost: number = 0;
        for (const asset of assets) {
          if (asset.price > 0) {
            totalCost += +asset.price;
          }
        }
        this.order.get('agreedPrice').patchValue(totalCost);
      });

    this.order.get('stage').valueChanges
      .subscribe((stageId) => {
        this.getPhasesByStage(stageId);
      });
    
    this.order.get('project').valueChanges
      .subscribe((projectId) => {
        this.getStagesByProject(projectId);
      });

  }

  getPhasesByStage(stageId: string) {
    if (!stageId) {
      return;
    }

    this.phaseService.getAllByStage(stageId)
      .subscribe((phases) => {
        this.phases = phases;
      }, (error) => {
        console.error(`Error ${error.error.error}`);
    });
  }

  getStagesByProject(projectId: string) {
    if (!projectId) {
      return;
    }

    this.stageService.getAll(this.user?.account?.id, projectId)
      .subscribe((stages) => {
        this.stages = stages;
      }, (error) => {
        console.error(`Error ${error.error.error}`);
      });
  }

  onSubmit({value, valid}: {value: IOrder, valid: boolean}): void {
    // date.setHours(time.getHours(), time.getMinutes(), time.getSeconds());

    if (valid) {

      if (value['completionDate']) {
        value['completionDate'] = moment(value['completionDate']).valueOf();
      }

      for (const asset of value['assets']) {
        if (asset['plannedDates']['plannedDeliveryDate']) {
          asset['plannedDates']['plannedDeliveryDate'] = moment(asset['plannedDates']['plannedDeliveryDate']).valueOf();
        }
        asset['account'] = this.user.account.id;
      }

      value['account'] = this.user.account.id;
      value['createdOn'] = moment().valueOf();

      if (!value['hasLinkedOrders']) {
        value['linkedOrders'] = null;
      }

      this.loadingService.start();
      this.orderService.create(value)
        .subscribe((resp) => {
          console.log('resp', resp);
          this.loadingService.complete();
          this.message.create('success', `Order was created successfully!`);
          this.onBack();
        }, (error) => {
          this.loadingService.complete();
          console.error('Error', error);
          // this.message.create('error', `Error: ${error.error.error}`);
      });
    }

  }

  onDeliveryLocationChange() {
    this.order.get('deliveryLocation').valueChanges
      .pipe(
        debounceTime(550),
        tap(() => {
          this.filteredDeliveryLocation = [];
          this.areDeliveryLocationLoading = true;
        }),
        switchMap(value => this.placesService.search(value)
          .pipe(
            finalize(() => {
              this.areDeliveryLocationLoading = false;
            })
          )
        )
      )
      .subscribe((addresses: any) => {
        if (!addresses) {
          this.filteredDeliveryLocation = [];
        } else {
          this.filteredDeliveryLocation = addresses;
        }
      }, (error) => {
        console.error('Error', error);
      });
  }

  onDeliveryLocationSelected(event) {
    if (event.isUserInput) {
      const value = event.source.value;
      console.log('event', event);
      /* this.placesService.get(value.place) */
    }
  }

  onBack() {
    this.location.back();
  }

}
