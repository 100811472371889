import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import { InventoryService } from '@plasma/services/inventory.plasma';
import { AuthService } from '@plasma/services/auth.plasma';

import { IOrder } from '@plasma/models/order';
import { ISupplier } from '@plasma/models/supplier';
import { IAgent } from '@plasma/models/agent';

import { NzMessageService } from 'ng-zorro-antd/message';


@Component({
  selector: 'app-provenance-detail',
  templateUrl: './provenance-detail.component.html',
  styleUrls: ['./provenance-detail.component.scss']
})
export class ProvenanceDetailComponent implements OnInit {

  id: string;
  provenance: any;

  constructor(private inventoryService: InventoryService,
              private message: NzMessageService,
              protected activatedRoute: ActivatedRoute,
              private location: Location) { }

  ngOnInit(): void {

    this.id = this.activatedRoute.snapshot.paramMap.get('id');
    if (!this.id) {
      return;
    }

    this.inventoryService.get(this.id)
      .subscribe((provenance) => {
        this.provenance = provenance;
      }, (error) => {
        console.error('Error', error);
        this.message.create('error', `Error: ${error.error.error}`);
      });

  }

  getAssetTypeId(assets: any[]) {
    if (!assets) {
      return '';
    }
    return assets.map((asset) => `${asset?.assetType} - ${asset?.id}`).join(', ');
  }

  onBack() {
    this.location.back();
  }

}
