import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NzModalRef } from 'ng-zorro-antd/modal';

import { IAgent } from '@plasma/models/agent';

import { v4 as uuidv4 } from 'uuid';
import * as moment from 'moment';


@Component({
  selector: 'app-invite-project-owner-modal',
  templateUrl: './invite-project-owner-modal.component.html',
  styleUrls: ['./invite-project-owner-modal.component.scss']
})
export class InviteProjectOwnerModalComponent implements OnInit {

  @Input() user: IAgent;
  invitation: FormGroup;

  constructor(private modal: NzModalRef, private fb: FormBuilder) { }

  ngOnInit(): void {

    this.invitation = this.fb.group({
      inviteUser: [null, [Validators.required, Validators.email]]
    });

  }

  inviteUser(): void {
    const { value, valid } = this.invitation;
    if (valid) {
      console.log('value', value);
      this.modal.destroy(value);
    }
  }

}
