import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { NzMessageService, UploadChangeParam, UploadFile } from 'ng-zorro-antd';
import { OrderService } from '@plasma/services/order.plasma';


@Component({
  selector: 'app-upload-documents-dialog',
  templateUrl: './upload-documents-dialog.component.html',
  styleUrls: ['./upload-documents-dialog.component.scss']
})
export class UploadDocumentsDialogComponent {

    complianceEvidenceFiles: UploadFile[] = []
    complianceUploading = false;

    constructor(@Inject(MAT_DIALOG_DATA) public data: any, public dialogRef: MatDialogRef<UploadDocumentsDialogComponent>, private message: NzMessageService, private orderService: OrderService) {}

    extractExtension(s) {
      return s.split(/.([^.]+)$/)[1];
    }

    extractFilename(link) {
      return decodeURI(link).split(/^.*[\\\/](.+)/)[1];
    }

    beforeUpload = (file: UploadFile): boolean => {
      this.complianceEvidenceFiles = this.complianceEvidenceFiles.concat(file);
      return false;
    }

    handleUpload(e: any): void {
      e.preventDefault();
      console.log('handle Upload');
      const formData = new FormData();
  
      this.complianceEvidenceFiles.forEach((file: any) => {
        formData.append('complianceEvidence', file);
      });
  
      console.log('orderId', this.data.id);
      
      this.complianceUploading = true;
  
      this.orderService.uploadComplianceEvidence(this.data.id, formData)
        .subscribe((resp) => {
          console.log('resp', resp);
          this.complianceUploading = false;
          this.complianceEvidenceFiles = [];
          for (const link of resp?.links) {
            this.data?.links.push(link);
            // this.complianceEvidenceFiles.push(link);
          }
  
          console.log('after resp this.complianceEvidenceFiles', this.complianceEvidenceFiles);
  
          this.message.success('Files uploaded successfully!');
        }, (error) => {
          console.error('Error', error);
          this.message.error(`Error ${error.error.error}`);
        });
  
    }
}
