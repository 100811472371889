export function stamp(model: any): any {
  return {
    ...model,
    timestamp: new Date().getTime()
  };
}


export function getUrl(domain: string, version: number = 1): string {
  // return `http://localhost:8040/${domain}`;
  return `https://api.trackchain.net/${domain}`;
}
