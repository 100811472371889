import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';

import { getUrl } from './utils';
import { BasePlasmaService } from './base.plasma';

import { IAccount } from '@plasma/models/account';


@Injectable({
  providedIn: 'root'
})
export class AccountService extends BasePlasmaService {

  protected namespace = 'accounts';

  constructor(protected http: HttpClient) {
    super(http);
  }

  public create(account: IAccount) {
    return this.makePost(getUrl(this.namespace), account) as Observable<any>;
  }

  public get(key: string) {
    return this.makeGet(`${getUrl(this.namespace)}/${key}`) as Observable<any>;
  }

  public getAll() {
    return this.makeGet(getUrl(this.namespace)) as Observable<any>;
  }

  public update(account: IAccount) {
    return this.makePatch(getUrl(this.namespace), account) as Observable<any>;
  }

  public getSuppliersStatus(key: string) {
    return this.makeGet(`${getUrl(this.namespace)}/${key}/suppliers/status`) as Observable<any>;
  }

  public uploadComplianceEvidence(key: string, formData: FormData) {
    return this.makePost(`${getUrl(this.namespace)}/${key}/uploadComplianceEvidence`, formData) as Observable<any>;
  }

}
