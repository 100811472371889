import { Component, OnInit } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';

import { OrderService } from '@plasma/services/order.plasma';
import { AuthService } from '@plasma/services/auth.plasma';

import { IOrder } from '@plasma/models/order';
import { ISupplier } from '@plasma/models/supplier';
import { IAgent } from '@plasma/models/agent';

import { NzMessageService } from 'ng-zorro-antd/message';

import { MatDialog } from '@angular/material/dialog';

import { LoadingService } from '@plasma/components/loading/loading.service';
import { UploadDocumentsDialogComponent } from '@plasma/components/dialog/upload-documents-dialog.component';

import * as moment from 'moment';
import { UploadedDocumentsDialogComponent } from '@plasma/components/dialog/uploaded-documents.component';



@Component({
  selector: 'app-orders',
  templateUrl: './orders.component.html',
  styleUrls: ['./orders.component.scss']
})
export class OrdersComponent implements OnInit {

  orders: any[];
  user: IAgent;
  showLoading: boolean = false;

  displayedColumns: string[] = ['contractNo', 'orderingCompany', 'orderNo', 'phase', 'noOfItems', 'agreedPrice', 'destination', 'createdOn', 'status', 'actions'];
  dataSource = null;

  constructor(private orderService: OrderService,
              private authService: AuthService,
              private clipboard: Clipboard,
              public dialog: MatDialog,
              private message: NzMessageService) { }

  ngOnInit(): void {

    this.authService.getUser()
      .subscribe((user: IAgent) => {
        this.user = user;
        this.showLoading = true;
        if (user.role.type === 'SUPPLIER') {
          this.orderService.getBySupplier(user.publicKey)
            .subscribe((orders) => {
              this.orders = orders;
              this.dataSource = orders;
              this.showLoading = false;
            }, (error) => {
              this.showLoading = false;
              console.error('Error', error);
              this.message.create('error', `Error: ${error.error}`);
            });
        } else {
          this.orderService.getByUser(user.publicKey)
            .subscribe((orders) => {
              this.orders = orders;
              this.dataSource = orders;
              this.showLoading = false;
            }, (error) => {
              this.showLoading = false;
              console.error('Error', error);
              this.message.create('error', `Error: ${error.error}`);
            });
        }
      });

  }

  delete(id) {
    this.orderService.delete(id)
      .subscribe((resp) => console.log('resp', resp),
                 (error) => console.error('Error', error));
  }

  calculateAssetStatus(deliveryDate, floatDays) {
    if (deliveryDate === 0) {
      return;
    }

    const criticalDate = moment(deliveryDate).add(floatDays, 'days');
    if (moment().isSameOrBefore(deliveryDate)) {
      return 0;
    } else if (moment().isAfter(deliveryDate) && moment().isSameOrBefore(criticalDate)) {
      return 1;
    } else if (moment().isAfter(criticalDate)) {
      return 2;
    }
  }

  calculateStatus(assets: any[]) {
    if (!assets) {
      return;
    }
    const statusVal = assets.map((asset) =>
      this.calculateAssetStatus(asset.planned_dates.planned_delivery_date, asset.float_days))
      .reduce((max, status) => (status > max ? status : max), 0);

    switch (statusVal) {
      case 0:
        return 'green';
        break;
      case 1:
        return 'yellow';
        break;
      case 2:
        return 'red';
        break;

      default:
        break;
    }
  }

  openUploadDocumentDialog(order) {
    const dialogRef = this.dialog.open(UploadDocumentsDialogComponent, {
      data: {
        title: `Compliance Evidence - ${order?.order_no}`,
        id: order?.id,
        links: order?.complianceEvidence
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  openUploadedDocumentsDialog(order) {
    const dialogRef = this.dialog.open(UploadedDocumentsDialogComponent, {
      data: {
        title: `Compliance Evidence - ${order?.order_no}`,
        links: order?.documents
      }
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

  copyToClipboard(text: string) {
    if (!text) {
      return;
    }
    this.clipboard.copy(text);
  }

}
