import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from '../../services/auth.plasma';
import { AccountService } from '@plasma/services/account.plasma';
import { AgentsService } from '@plasma/services/agents.plasma';
import { IAgent } from '@plasma/models/agent';
import { IAccount } from '@plasma/models/account';

import { NzMessageService } from 'ng-zorro-antd/message';


@Component({
  selector: 'app-invitation-sign-up',
  templateUrl: './invitation-sign-up.component.html',
  styleUrls: ['./invitation-sign-up.component.scss']
})
export class InvitationSignUpComponent implements OnInit {

  accountId: string;
  role: string;
  email: string;
  projectId: string;
  validateForm: FormGroup;
  accounts: IAccount[];

  constructor(private fb: FormBuilder,
    private authService: AuthService,
    private accountService: AccountService,
    private agentService: AgentsService,
    private route: ActivatedRoute,
    private router: Router,
    private message: NzMessageService) {}

  ngOnInit(): void {
    this.accountId = this.route.snapshot.paramMap.get('id');
    this.email = this.route.snapshot.queryParamMap.get('email');
    this.role = this.route.snapshot.queryParamMap.get('role');
    this.projectId = this.route.snapshot.queryParamMap.get('project');
    this.validateForm = this.fb.group({
      account: [this.accountId, [Validators.required]],
      email: [this.email, [Validators.email, Validators.required]],
      password: [null, [Validators.required]],
      checkPassword: [null, [Validators.required, this.confirmationValidator]],
      name: [null, [Validators.required]],
      address: [null],
      description: [null],
      role: [this.role, [Validators.required]]
    });

    this.accountService.get(this.accountId)
      .subscribe(resp => {
        console.log('resp', resp);
        this.accounts = [resp];
      }, (error) => {
        console.error('Error', error);
        this.message.create('error', `Error: ${error.error.error}`);
      });
  }

  submitForm({value, valid}: {value: IAgent, valid: boolean}): void {
    // for (const i in this.validateForm.controls) {
    //   this.validateForm.controls[i].markAsDirty();
    //   this.validateForm.controls[i].updateValueAndValidity();
    // }

    if (valid) {
      this.authService.signup(value)
        .subscribe(resp => {
          const tempRole = resp?.agent?.role;
          resp.agent.role = {};
          resp.agent.role['type'] = tempRole;
          if (resp?.agent.account === 'CREATE_NEW') {
            resp.agent.account = {
              id: '',
              name: ''
            };
          }
          console.log('user creation resp', resp);
          this.agentService.get(resp.agent.publicKey)
            .subscribe((agent) => {
              if (agent) {
                resp.agent = agent;
                console.log('updated resp', resp);
                this.authService.setUser(resp);

                if (resp.agent.role.type === 'SUPPLIER') {
                  this.router.navigate(['/dashboard', {outlets: { dashboard: 'orders' }}]);

                } else {
                  this.router.navigate(['/dashboard', {outlets: { dashboard: 'account' }}]);
                }
              }

            }, (error) => {
              console.error(`Error ${error.error.error}`);
          });

        }, (error) => {
          console.error('Error', error);
          this.message.create('error', `Error: ${error.error.error}`);
        });
    }
  }

  updateConfirmValidator(): void {
    /** wait for refresh value */
    Promise.resolve().then(() => this.validateForm.controls.checkPassword.updateValueAndValidity());
  }

  confirmationValidator = (control: FormControl): { [s: string]: boolean } => {
    if (!control.value) {
      return { required: true };
    } else if (control.value !== this.validateForm.controls.password.value) {
      return { confirm: true, error: true };
    }
    return {};
  }

  getCaptcha(e: MouseEvent): void {
    e.preventDefault();
  }

}
