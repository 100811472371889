<nz-page-header class="site-page-header" (nzBack)="onBack()" nzBackIcon>
  <nz-page-header-title style="color: #046888;"><strong>Provenance</strong></nz-page-header-title>
</nz-page-header>

<ng-container>
  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between center">
    <div fxLayoutAlign="center center" id="field-container">
      <mat-form-field appearance="fill" fxFlexFill>
        <mat-label>Product Name</mat-label>
        <input matInput readonly [ngModel]="provenance?.productName">
      </mat-form-field>
    </div>
    
    <div fxLayoutAlign="center center" id="field-container">
      <mat-form-field appearance="fill" fxFlexFill>
        <mat-label>Company</mat-label>
        <input matInput readonly [ngModel]="provenance?.company">
      </mat-form-field>
    </div>
    
    <div fxLayoutAlign="center center" id="field-container">
      <mat-form-field appearance="fill" fxFlexFill>
        <mat-label>Company Address</mat-label>
        <input matInput readonly [ngModel]="provenance?.companyAddress?.address">
      </mat-form-field>
    </div>
  </div>

  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between center">
    <div fxLayoutAlign="center center" id="field-container">
      <mat-form-field appearance="fill" fxFlexFill>
        <mat-label>Internal Part Number</mat-label>
        <input matInput readonly [ngModel]="provenance?.internalPartNumber">
      </mat-form-field>
    </div>
    
    <div fxLayoutAlign="center center" id="field-container">
      <mat-form-field appearance="fill" fxFlexFill>
        <mat-label>Item Count for Production Run</mat-label>
        <input matInput readonly [ngModel]="provenance?.itemCountForProductionRun">
      </mat-form-field>
    </div>

    <div fxLayoutAlign="center center" id="field-container">
      <mat-form-field appearance="fill" fxFlexFill>
        <mat-label>Origin of Manufacturer</mat-label>
        <input matInput readonly [ngModel]="provenance?.originOfManufacturer?.address">
      </mat-form-field>
    </div>
  </div>

  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between center">
    <div fxLayoutAlign="center center" id="field-container">
      <mat-form-field appearance="fill" fxFlexFill>
        <mat-label>Manufacture Date</mat-label>
        <input matInput readonly [ngModel]="provenance?.manufactureDate | date: 'yyyy-MM-dd, h:mm a'">
      </mat-form-field>
    </div>
    
    <div fxLayoutAlign="center center" id="field-container">
      <mat-form-field appearance="fill" fxFlexFill>
        <mat-label>Dimensions of Product</mat-label>
        <input matInput readonly [ngModel]="provenance?.dimensionsOfProduct">
      </mat-form-field>
    </div>
    
    <div fxLayoutAlign="center center" id="field-container">
      <mat-form-field appearance="fill" fxFlexFill>
        <mat-label>Associated Code</mat-label>
        <input matInput readonly [ngModel]="provenance?.associatedCode">
      </mat-form-field>
    </div>
  </div>

  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between center">
    <div fxLayoutAlign="center center" id="field-container">
      <mat-form-field appearance="fill" fxFlexFill>
        <mat-label>Inventory Managerment Information (SKU)</mat-label>
        <input matInput readonly [ngModel]="provenance?.sku">
      </mat-form-field>
    </div>
    
    <div fxLayoutAlign="center center" id="field-container">
      <mat-form-field appearance="fill" fxFlexFill>
        <mat-label>Inventory Type</mat-label>
        <input matInput readonly [ngModel]="provenance?.inventoryType">
      </mat-form-field>
    </div>
    
    <div fxLayoutAlign="center center" id="field-container">
      <mat-form-field appearance="fill" fxFlexFill>
        <mat-label>Production Process Details</mat-label>
        <input matInput readonly [ngModel]="provenance?.productionProcessDetails">
      </mat-form-field>
    </div>
  </div>

  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between center">
    <div fxLayoutAlign="center center" id="field-container">
      <mat-form-field appearance="fill" fxFlexFill>
        <mat-label>Materials Used</mat-label>
        <input matInput readonly [ngModel]="provenance?.materialsUsed">
      </mat-form-field>
    </div>
    
    <div fxLayoutAlign="center center" id="field-container">
      <mat-form-field appearance="fill" fxFlexFill>
        <mat-label>ISO Certification Number</mat-label>
        <input matInput readonly [ngModel]="provenance?.isoCertificationNumber">
      </mat-form-field>
    </div>
    
    <div fxLayoutAlign="center center" id="field-container">
      <mat-form-field appearance="fill" fxFlexFill>
        <mat-label>Quality Assurance ID Numbers</mat-label>
        <input matInput readonly [ngModel]="provenance?.qualityAssurance">
      </mat-form-field>
    </div>
  </div>

  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between center">
    <div fxLayoutAlign="center center" id="field-container">
      <mat-form-field appearance="fill" fxFlexFill>
        <mat-label>Safety Sheet Hyperlinkss</mat-label>
        <input matInput readonly [ngModel]="provenance?.safetySheetHyperlinks">
      </mat-form-field>
    </div>
    
    <div fxLayoutAlign="center center" id="field-container">
      <mat-form-field appearance="fill" fxFlexFill>
        <mat-label>Website Links</mat-label>
        <input matInput readonly [ngModel]="provenance?.websiteLinks">
      </mat-form-field>
    </div>
    
    <div fxLayoutAlign="center center" id="field-container">
      <mat-form-field appearance="fill" fxFlexFill>
        <mat-label>Applicable Laws or Regulation Sections</mat-label>
        <input matInput readonly [ngModel]="provenance?.applicableLaws">
      </mat-form-field>
    </div>
  </div>

  <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between center">
    <div fxLayoutAlign="center center" id="field-container">
      <mat-form-field appearance="fill" fxFlexFill>
        <mat-label>Parent Product(s)</mat-label>
        <input matInput readonly [ngModel]="getAssetTypeId(provenance?.parentProducts)">
      </mat-form-field>
    </div>
    
    <div fxLayoutAlign="center center" id="field-container">
      <mat-checkbox disabled [ngModel]="provenance?.isRawMaterial">Raw Material</mat-checkbox>
    </div>

    <div fxLayoutAlign="center center" id="field-container">
    </div>

  </div>

</ng-container>
