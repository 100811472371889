import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
  name: 'split'
})
export class SplitPipe implements PipeTransform {
  public transform(value: any, separator: string | RegExp, limit?: number, index?: number): any {
    if (value) {
      return index ? value.split(separator, limit)[index] : value.split(separator, limit);
    }
    return null;
  }
}
