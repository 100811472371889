import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { AgentsService } from '@plasma/services/agents.plasma';
import { AuthService } from '@plasma/services/auth.plasma';
import { AccountService } from '@plasma/services/account.plasma';

import { IAgent } from '@plasma/models/agent';
import { IUser } from '@plasma/models/user';

import { NzMessageService } from 'ng-zorro-antd/message';
import { UploadChangeParam, UploadFile } from 'ng-zorro-antd/upload';


@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  user: FormGroup;
  complianceEvidenceFiles: UploadFile[] = []
  complianceUploading = false;

  constructor(private fb: FormBuilder,
              private authService: AuthService,
              private agentService: AgentsService,
              private accountService: AccountService,
              private message: NzMessageService) { }

  ngOnInit(): void {

    this.user = this.fb.group({
      account: this.fb.group({
        id: [{value: null, disabled: true}],
        complianceEvidence: [null]
      }),
      name: [{value: null, disabled: true}, [Validators.required]],
      email: [{value: null, disabled: true}, [Validators.required, Validators.email]],
      password: [null],
      address: [null],
      description: [null],
      complianceEvidence: [null],
      role: this.fb.group({
        type: [{value: null, disabled: true}, [Validators.required]]
      }),
      subRole: this.fb.group({
        type: [{value: null, disabled: true}, [Validators.required]]
      }),
      canAssignUsers: [{value: null, disabled: true}],
      canAuthorisePayment: [{value: null, disabled: true}],
      canCheckProvenance: [{value: null, disabled: true}],
      canInviteProjectOwners: [{value: null, disabled: true}],
      canInviteUsers: [{value: null, disabled: true}],
      canManageOrders: [{value: null, disabled: true}],
      canManageProjectOwners: [{value: null, disabled: true}],
      canManageProjects: [{value: null, disabled: true}],
      canManageUsers: [{value: null, disabled: true}],
      canTrackOrders: [{value: null, disabled: true}],
    });

    this.loadProfile();

  }

  loadProfile() {
    this.authService.getUser()
      .subscribe((user: IAgent) => {
        if (user) {
          console.log('user', user);
          this.user.patchValue(user);
        }
      }, (error) => {
        console.error('Error', error);
        this.message.create('error', `Error: ${error.error.error}`);
      });
  }

  onSubmit({valid, value}: {valid: boolean, value: IAgent}) {
    console.log('value', value);

    if (valid) {
      this.agentService.update(value)
        .subscribe((resp: IUser) => {
          console.log('resp', resp);
          this.authService.setUser(resp);
          this.message.create('success', `${resp.agent.name} profile was updated successfully!`);
        }, (error) => {
          console.error('Error', error.error);
          this.message.create('error', `Error: ${error.error.error}`);
        });
    }
  }

  extractExtension(s) {
    return s.split(/.([^.]+)$/)[1];
  }

  extractFilename(s) {
    return decodeURI(s).split(/^.*[\\\/](.+)/)[1];
  }

  beforeUpload = (file: UploadFile): boolean => {
    this.complianceEvidenceFiles = this.complianceEvidenceFiles.concat(file);
    return false;
  }

  handleUpload(e: any): void {
    e.preventDefault();
    console.log('handle Upload');
    const formData = new FormData();
    let accountId: string;

    this.complianceEvidenceFiles.forEach((file: any) => {
      formData.append('complianceEvidence', file);
    });

    accountId = (this.user.get('account') as FormGroup).controls.id.value;
    console.log('accountId', accountId);
    
    this.complianceUploading = true;

    this.accountService.uploadComplianceEvidence(accountId, formData)
      .subscribe((resp) => {
        console.log('resp', resp);
        this.complianceUploading = false;
        this.complianceEvidenceFiles = [];
        for (const link of resp?.links) {
          this.user?.get('account')?.get('complianceEvidence')?.value.push(link);
          // this.complianceEvidenceFiles.push(link);
        }

        console.log('after resp this.complianceEvidenceFiles', this.complianceEvidenceFiles);

        this.message.success('Files uploaded successfully!');
      }, (error) => {
        console.error('Error', error);
        this.message.error(`Error ${error.error.error}`);
      });

  }

}
