<div class="mat-elevation-z8">
  <table mat-table [dataSource]="dataSource" matSort>

    <!-- ID Column -->
    <ng-container matColumnDef="name">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Name </th>
      <td mat-cell label="Name" *matCellDef="let supplier"> {{ supplier?.name }} </td>
    </ng-container>

    <!-- Item Name Column -->
    <ng-container matColumnDef="role">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Role </th>
      <td mat-cell label="Role" *matCellDef="let supplier"> {{ supplier?.role?.type | titlecase }} </td>
    </ng-container>

    <!-- Location -->
    <ng-container matColumnDef="address">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Address </th>
      <td mat-cell label="Address" *matCellDef="let supplier"> {{ supplier?.address.address }} </td>
    </ng-container>

    <!-- Planned Completion Date Column -->
    <ng-container matColumnDef="description">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Description </th>
      <td mat-cell label="Description" *matCellDef="let supplier"> {{ supplier?.description }} </td>
    </ng-container>

    <!-- Planned Delivery Date Column -->
    <ng-container matColumnDef="createdOn">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Created On </th>
      <td mat-cell label="Created On" *matCellDef="let supplier"> {{ supplier?.created_on * 1000 | date: 'yy-MM-dd, HH:mm' }} </td>
    </ng-container>

    <!-- Planned Install Date Column -->
    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> </th>
      <td mat-cell label="Compliance" *matCellDef="let supplier">
        <ng-container
          *ngIf="supplier?.account?.isCompliant; then compliant; else nonCompliant">
        </ng-container>

        <ng-template #compliant>
          <td style="color: #00a2a4;">
            <a (click)="openComplianceDocumentsDialog(supplier)"><img width="50" src="assets/img/compliance.jpg" alt="Compliance"></a>
          </td>
        </ng-template>
        <ng-template #nonCompliant>
          <td style="color: #00a2a4;">
            <img width="50" src="assets/img/non compliance.jpg" alt="Non Compliance">
          </td>
        </ng-template>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let order; columns: displayedColumns;">
    </tr>
  </table>

  <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
</div>

<!-- <nz-table #supplierTable [nzData]="suppliers" [nzLoading]="showLoading">
  <thead>
    <tr>
      <th style="background: #4c4c4c; color: white; box-shadow: 5px 0 5px -2px #4c4c4c; z-index: 5;">Name</th>
      <th style="background: #00a2a4; color: white; box-shadow: 5px 0 5px -2px #00a2a4; z-index: 4;">Role</th>
      <th style="background: #b5c435; color: white; box-shadow: 5px 0 5px -2px #b5c435; z-index: 3;">Address</th>
      <th style="background: #046888; color: white; box-shadow: 5px 0 5px -2px #046888; z-index: 2;">Description</th>
      <th style="background: #4c6369; color: white; box-shadow: 5px 0 5px -2px #4c6369; z-index: 1;">Created on</th>
      <th style="background: #00a2a4; color: white;"></th>
      <th>Action</th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let supplier of supplierTable.data; let i = index;" [ngStyle]="{'background-color': (i % 2 == 0) ? '#f3f3f3' : '#ffffff' }">
      <td style="color: #4c4c4c;" [style.border-right]="'1px solid #d7d7d7'" [style.box-shadow]="(i+1)+'px 0 5px -2px #e2e2e2'">{{ supplier?.name }}</td>
      <td style="color: #00a2a4;" [style.border-right]="'1px solid #d7d7d7'" [style.box-shadow]="(i+1)+'px 0 5px -2px #e2e2e2'">{{ supplier?.role?.type | titlecase }}</td>
      <td style="color: #b5c435;" [style.border-right]="'1px solid #d7d7d7'" [style.box-shadow]="(i+1)+'px 0 5px -2px #e2e2e2'">{{ supplier?.address }}</td>
      <td style="color: #046888;" [style.border-right]="'1px solid #d7d7d7'" [style.box-shadow]="(i+1)+'px 0 5px -2px #e2e2e2'">{{ supplier?.description }}</td>
      <td style="color: #4c6369;" [style.border-right]="'1px solid #d7d7d7'" [style.box-shadow]="(i+1)+'px 0 5px -2px #e2e2e2'">{{ supplier?.created_on * 1000 | date: 'yyyy-MM-dd, h:mm a' }}</td>
      <ng-container
        *ngIf="supplier?.account?.isCompliant; then compliant; else nonCompliant">
      </ng-container>

      <ng-template #compliant>
        <td style="color: #00a2a4;">
          <a (click)="openComplianceDocumentsDialog(supplier)"><img width="50" src="assets/img/compliance.jpg" alt="Compliance"></a>
        </td>
      </ng-template>
      <ng-template #nonCompliant>
        <td style="color: #00a2a4;">
          <img width="50" src="assets/img/non compliance.jpg" alt="Non Compliance">
        </td>
      </ng-template>
      
      <td>
        <a>Action 一 {{ data.name }}</a>
        <nz-divider nzType="vertical"></nz-divider>
        <a>Delete</a>
      </td>
    </tr>
  </tbody>
</nz-table> -->
