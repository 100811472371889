import { Component, Input, Output, EventEmitter } from '@angular/core';


@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss']
})
export class InputComponent<T> {

  @Input()
  public title: string;

  @Input()
  public name: string;

  @Input()
  public value: T;

  @Input()
  public labelPosition: string | null;

  @Input()
  public labelSpan: number;

  @Input()
  public fieldSpan: number;

  @Input()
  public readonly: boolean;

  @Input()
  public disabled: boolean;

  @Output() public valueChange: EventEmitter<T> = new EventEmitter();


}
