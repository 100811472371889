import { Component, OnInit, OnDestroy, ViewContainerRef } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';

import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';

import { CreateAccountModalComponent } from '@plasma/components/modal/create-account-modal.component';
import { LoadingService } from '@plasma/components/loading/loading.service';

import { IAccount } from '@plasma/models/account';

import { AuthService } from '@plasma/services/auth.plasma';
import { AccountService } from '@plasma/services/account.plasma';
import { AgentsService } from '@plasma/services/agents.plasma';

import { Subject, pipe } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';


@Component({
  selector: 'app-service-provider-dashboard',
  templateUrl: './service-provider-dashboard.component.html',
  styleUrls: ['./service-provider-dashboard.component.scss']
})
export class ServiceProviderDashboardComponent implements OnInit, OnDestroy {

  private destroy$ = new Subject<void>();
  isCollapsed = false;
  user: any;

  constructor(public auth: AuthService,
              private accountService: AccountService,
              private agentService: AgentsService,
              public router: Router,
              private modal: NzModalService,
              private viewContainerRef: ViewContainerRef,
              private loadingService: LoadingService,
              private message: NzMessageService) { }

  ngOnInit(): void {

    this.auth.getUser()
      .pipe(take(1))
      .subscribe((user: any) => {
        console.log('user', user);
        this.user = user;
        if (this.user && this.user.account && (!this.user.account.id && 0 === this.user.account.id.length)) {
          // user not linked to an account
          this.createModal();
        }
      }, (error) => {
        console.error('Error', error);
      });
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  createModal(): void {
    const modal = this.modal.create({
      nzTitle: 'Create Account',
      nzContent: CreateAccountModalComponent,
      nzViewContainerRef: this.viewContainerRef,
      nzComponentParams: {
        name: this.user?.name,
        address: this.user?.address?.address,
      },
      nzMaskClosable: false,
      nzClosable: false,
      nzWidth: 'max-content',
      nzOnOk: () => new Promise(resolve => setTimeout(resolve, 1000)),
      nzFooter: [
        {
          label: 'Create Account',
          onClick: componentInstance => {
            componentInstance.createAccount();
          }
        }
      ]
    });
    const instance = modal.getContentComponent();
    modal.afterOpen.subscribe(() => console.log('[afterOpen] emitted!'));
    // Return a result when closed
    modal.afterClose.subscribe(result => this.createAccount(result));
  }

  createAccount(account: IAccount) {
    this.loadingService.start();
    this.accountService.create(account)
      .subscribe((resp) => {
        this.agentService.get(this.user.publicKey)
          .subscribe((updatedAgent) => {
            console.log('updatedAgent---', updatedAgent);
            this.user = updatedAgent;
            this.auth.setAgent(updatedAgent);
            this.loadingService.complete();
            this.message.create('success', `Account was created successfully!`);
          }, (error) => {
            console.error(`Error ${error.error.error}`);
        });
      }, (error) => {
        this.loadingService.complete();
        console.error('Error', error);
        this.message.create('error', `Error ${error.error.error}`);
    });
  }

  logout() {
    this.auth.clearAuthToken();
    this.router.navigate(['/login']);
  }

}
