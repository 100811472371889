<div nz-row>
  <div nz-col nzSpan="12" class="login-background">
    <div fxLayout="column" fxLayoutAlign="center center">
      <div fxLayoutAlign="center center" style="margin-top: 50vh;">
        <img src="assets/img/TrackChainLogo.png" alt="Track Chain!">
      </div>
      <div fxLayoutAlign="center center">
        <h2 nz-typography style="color: white;">Welcome to TrackChain</h2>
      </div>         
    </div>
  </div>
  <div nz-col nzSpan="24" [nzMd]="12">
    <form nz-form [formGroup]="validateForm" class="login-form" (ngSubmit)="submitForm(validateForm)">
      <h2 nz-typography class="welcome-heading">
        <img class="login-mobile-logo" src="assets/img/TrackChainLogo.png" alt="Track Chain!">
        Hello! Welcome.
      </h2>
      <span nz-typography>Registration.</span>
      <nz-form-item style="margin-top: 15px;">
        <nz-form-control nzErrorTip="The input is not valid E-mail!">
          <nz-input-group [nzPrefix]="envelopeIcon">
            <input type="text" nz-input formControlName="email" placeholder="Email" />
          </nz-input-group>
          <ng-template #envelopeIcon>
            <fa-icon [icon]="['far', 'envelope']"></fa-icon>
          </ng-template>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-control nzErrorTip="Please input your password!">
          <nz-input-group nzPrefixIcon="lock" [nzSuffix]="suffixTemplate">
            <input nz-input [type]="passwordVisible ? 'text' : 'password'" placeholder="Password" formControlName="password" (ngModelChange)="updateConfirmValidator()" />
          </nz-input-group>
          <ng-template #suffixTemplate>
            <i nz-icon [nzType]="passwordVisible ? 'eye-invisible' : 'eye'" (click)="passwordVisible = !passwordVisible"></i>
          </ng-template>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-control [nzErrorTip]="errorTpl">
          <nz-input-group nzPrefixIcon="lock">
            <input nz-input type="password" formControlName="checkPassword" placeholder="Confirm Password" />
          </nz-input-group>
          <ng-template #errorTpl let-control>
            <ng-container *ngIf="control.hasError('required')">
              Please confirm your password!
            </ng-container>
            <ng-container *ngIf="control.hasError('confirm')">
              Two passwords that you enter are inconsistent!
            </ng-container>
          </ng-template>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
       <nz-form-control nzErrorTip="Please input your name!">
         <nz-input-group nzPrefixIcon="user">
            <input nz-input placeholder="Full Name" formControlName="name" />
         </nz-input-group>
       </nz-form-control>
     </nz-form-item>
     <nz-form-item>
        <nz-form-control nzErrorTip="The input is not valid Account!">
          <nz-input-group [nzPrefix]="companyIcon">
            <nz-select nzBorderless id="account" formControlName="account" placeholder="Account">
              <nz-option nzLabel="Create New" nzValue="CREATE_NEW"></nz-option>
              <nz-option *ngFor="let account of accounts" [nzLabel]="account?.name" [nzValue]="account?.id"></nz-option>
            </nz-select>
          </nz-input-group>
          <ng-template #companyIcon>
            <fa-icon [icon]="['far', 'building']"></fa-icon>
          </ng-template> 
        </nz-form-control>
     </nz-form-item>
      <!-- <nz-form-item>
        <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="phoneNumber" nzRequired>Phone Number</nz-form-label>
        <nz-form-control
          [nzSm]="14"
          [nzXs]="24"
          [nzValidateStatus]="validateForm.controls['phoneNumber']"
          nzErrorTip="Please input your phone number!"
        >
          <nz-input-group [nzAddOnBefore]="addOnBeforeTemplate">
            <ng-template #addOnBeforeTemplate>
              <nz-select formControlName="phoneNumberPrefix" class="phone-select">
                <nz-option nzLabel="+44" nzValue="+44"></nz-option>
                <nz-option nzLabel="+43" nzValue="+43"></nz-option>
              </nz-select>
            </ng-template>
            <input formControlName="phoneNumber" id="'phoneNumber'" nz-input />
          </nz-input-group>
        </nz-form-control>
      </nz-form-item> -->
      <ng-container formGroupName="address">
        <nz-form-item>
          <nz-form-control nzErrorTip="Please input your Address!">
            <nz-input-group [nzPrefix]="mapMarkerIcon">
              <input nz-input placeholder="Address" [nzAutocomplete]="auto" formControlName="address" />
              <nz-autocomplete #auto>
                <nz-auto-option *ngIf="areAddressesLoading" class="is-loading">Loading...</nz-auto-option>
                <ng-container *ngIf="!areAddressesLoading">
                  <nz-auto-option *ngFor="let option of filteredAddresses" (selectionChange)="onAddressSelection($event)" [nzValue]="option" [nzLabel]="option?.description">
                    {{ option?.description }}
                  </nz-auto-option>
                </ng-container>
              </nz-autocomplete>
            </nz-input-group>
            <ng-template #mapMarkerIcon>
              <fa-icon icon="map-marker-alt"></fa-icon>
            </ng-template>
          </nz-form-control>
        </nz-form-item>
      </ng-container>
      <nz-form-item>
        <nz-form-control nzErrorTip="Please input description!">
          <nz-input-group nzPrefixIcon="file-text">
            <input nz-input placeholder="Description" formControlName="description" />
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
      <nz-form-item>
        <nz-form-control nzErrorTip="Please select role!">
          <nz-input-group nzPrefixIcon="user-switch">
            <nz-select nzBorderless id="role" placeholder="Role" formControlName="role">
              <nz-option nzLabel="Buyer" nzValue="BUYER"></nz-option>
              <nz-option nzLabel="Supplier" nzValue="SUPPLIER"></nz-option>
              <nz-option nzLabel="Service Provider" nzValue="SERVICE_PROVIDER"></nz-option>
            </nz-select>
          </nz-input-group>
        </nz-form-control>
      </nz-form-item>
      <!-- <nz-form-item>
        <nz-form-label [nzSm]="6" [nzXs]="24" nzFor="captcha" nzRequired>Captcha</nz-form-label>
        <nz-form-control
          [nzSm]="14"
          [nzXs]="24"
          nzErrorTip="Please input the captcha you got!"
          nzExtra="We must make sure that your are a human."
        >
          <div nz-row [nzGutter]="8">
            <div nz-col [nzSpan]="12">
              <input nz-input formControlName="captcha" id="captcha" />
            </div>
            <div nz-col [nzSpan]="12">
              <button nz-button (click)="getCaptcha($event)">Get captcha</button>
            </div>
          </div>
        </nz-form-control>
      </nz-form-item> -->
      <!-- <nz-form-item nz-row class="register-area">
        <nz-form-control [nzSpan]="14" [nzOffset]="6">
          <label nz-checkbox formControlName="agree">
            <span>I have read the <a>agreement</a></span>
          </label>
        </nz-form-control>
      </nz-form-item> -->
      <nz-form-item nz-row class="register-area">
        <nz-form-control [nzSpan]="14" [nzOffset]="6">
          <button nz-button nzType="primary">Register</button>
          Or <a routerLink="/login"> login! </a>
        </nz-form-control>
      </nz-form-item>
    </form>

    <!-- <nz-footer style="background: transparent;">
      <div class="text-right">
        <span class="text-muted">Powered by <span><img src="assets/img/TrackChainBlack1.png" alt="Track Chain!"></span></span>
      </div>
    </nz-footer> -->
  </div>
</div>
