import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import { ProjectService } from '@plasma/services/project.plasma';
import { AuthService } from '@plasma/services/auth.plasma';

import { IAgent } from '@plasma/models/agent';
import { IProject } from '@plasma/models/project';

import { NzMessageService } from 'ng-zorro-antd/message';


@Component({
  selector: 'app-map',
  templateUrl: './map.component.html',
  styleUrls: ['./map.component.scss']
})
export class MapComponent implements OnInit {

  user: IAgent;

  lat: any;
  lng: any;
  options: google.maps.MapOptions;
  markerOptions: google.maps.MarkerOptions = {draggable: false};
  markerPositions: google.maps.LatLngLiteral[] = [];

  constructor(private route: ActivatedRoute,
              private projectService: ProjectService,
              private authService: AuthService,
              private message: NzMessageService,
              private location: Location) { }

  ngOnInit(): void {

    this.lat = +this.route.snapshot.queryParamMap.get('lat');
    this.lng = +this.route.snapshot.queryParamMap.get('lng');
    if (!this.lat || !this.lng) {
      return;
    }
    console.log('lat', this.lat, 'lng', this.lng);

    this.options = {
      center: {lat: this.lat, lng: this.lng},
      zoom: 18
    };

    this.markerPositions.push({ lat: this.lat, lng: this.lng });

    this.authService.getUser()
      .subscribe((user: IAgent) => {
        console.log('user/agent', user);
        this.user = user;
    });

  }

  onBack() {
    this.location.back();
  }

}
