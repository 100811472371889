<h1 mat-dialog-title>{{data.title}}</h1>
<mat-dialog-content class="mat-typography">
  <ng-template [ngIf]="data?.links?.length > 0">
    <div class="form-row mt-3">
      <div class="col-md-12">
        <div class="form-group row">
          <label class="col-sm-4 col-form-label"></label>
          <div class="col-sm-8">
            <div class="row">
              <div class="col-sm-6 mb-3" *ngFor="let link of data?.links">
                <a nz-button download target="_blank" [href]="link" nzType="link">
                  <fa-icon [icon]="['fas', (link?.split('.')[4] === 'docx' || link?.split('.')[4] === 'doc' || link?.split('.')[4] === 'dto') ? 'file-word' : (link?.split('.')[4] === 'pdf' ? 'file-pdf': 'file-alt')]" size="3x"></fa-icon>
                </a>
                {{extractFilename(link)}}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-template>
  
  <div class="form-row">
    <div class="col-md-12">
      <div class="form-group row">
        <div class="col-sm-12">
          <nz-upload nzType="drag" class="upload upload-list-inline" nzListType="picture" [(nzFileList)]="complianceEvidenceFiles" [nzBeforeUpload]="beforeUpload" [nzMultiple]="true">
            <p class="ant-upload-drag-icon">
              <i nz-icon nzType="inbox"></i>
            </p>
            <p class="ant-upload-text">Click or drag file to this area to upload</p>
            <p class="ant-upload-hint">
              Support for a single or bulk upload.
            </p>
          </nz-upload>
          <button
              nz-button
              [nzType]="'primary'"
              [nzLoading]="complianceUploading"
              (click)="handleUpload($event)"
              [disabled]="complianceEvidenceFiles.length == 0"
              style="margin-top: 16px"
            >
              {{ complianceUploading ? 'Uploading' : 'Start Upload' }}
          </button>
        </div>
      </div>
    </div>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cancel</button>
</mat-dialog-actions>
