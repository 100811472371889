<ng-template [ngIf]="user?.role?.type === 'SUPPLIER'">
  <button nz-button nzType="primary" class="mb-2" [routerLink]="['/dashboard', { outlets: { dashboard: ['inventory'] } }]">Inventory</button>
</ng-template>

<div class="mt-2 mb-2">
  
  <table mat-table [dataSource]="dataSource" class="mat-elevation-z8" matSort>
  
    <ng-container matColumnDef="contractNo">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Contract No. </th>
      <td mat-cell label="Contract No." *matCellDef="let order"> {{order.contract_no}} </td>
    </ng-container>
  
    <ng-container matColumnDef="orderingCompany">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Ordering Company </th>
      <td mat-cell label="Ordering Company" *matCellDef="let order"> {{order?.createdBy?.account?.name}} </td>
    </ng-container>
  
    <ng-container matColumnDef="orderNo">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Order No. </th>
      <td mat-cell label="Order No." *matCellDef="let order"> <a [routerLink]="['/dashboard', { outlets: { dashboard: ['order', order?.id] } }]">{{ order?.order_no }}</a> </td>
    </ng-container>

    <ng-container matColumnDef="phase">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Phase </th>
      <td mat-cell label="Phase" *matCellDef="let order"> {{order?.phase?.number}} </td>
    </ng-container>

    <ng-container matColumnDef="noOfItems">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> No. of Items </th>
      <td mat-cell label="No. of Items" *matCellDef="let order"> {{order.assets?.length}} </td>
    </ng-container>

    <ng-container matColumnDef="agreedPrice">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Total Price </th>
      <td mat-cell label="Total Price" *matCellDef="let order"> £{{ order?.agreed_price | number: '1.2-3' }} </td>
    </ng-container>

    <ng-container matColumnDef="destination">
      <th mat-header-cell *matHeaderCellDef mat-sort-header>Destination</th>
      <td mat-cell label="Destination" *matCellDef="let order"> <img class="address-icon" [matTooltip]="order?.destination + ' (click to copy)'" matTooltipPosition="above" (click)="copyToClipboard(order?.destination)" src="assets/img/location-question.png"></td>
    </ng-container>

    <ng-container matColumnDef="createdOn">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Created On </th>
      <td mat-cell label="Created On" *matCellDef="let order"> {{ order?.createdOn | date: 'yy-MM-dd, HH:mm' }} </td>
    </ng-container>

    <ng-container matColumnDef="status">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> Status </th>
      <td mat-cell label="Status" *matCellDef="let order"> <div class="circle" [ngClass]="calculateStatus(order?.assets)"></div> </td>
    </ng-container>

    <ng-container matColumnDef="actions">
      <th mat-header-cell *matHeaderCellDef mat-sort-header> </th>
      <td label="Actions" class="action-cell" mat-cell *matCellDef="let order">
        <button nzTooltipTitle="Track" nzTooltipPlacement="top" nzShape="circle" nz-button nzType="link" nz-tooltip [routerLink]="['/dashboard', { outlets: { dashboard: ['track', order?.id] } }]"><fa-icon icon="truck-moving"></fa-icon></button>
        <button nzTooltipTitle="Pay" nzTooltipPlacement="top" nzShape="circle" nz-button nzType="link" nz-tooltip [routerLink]="['/dashboard', { outlets: { dashboard: ['payment', order?.id] } }]"><fa-icon icon="money-bill"></fa-icon></button>
        <button nzTooltipTitle="Provenance" nzTooltipPlacement="top" nzShape="circle" nz-button nzType="link" nz-tooltip [routerLink]="['/dashboard', { outlets: { dashboard: ['provenance', order?.id] } }]"><fa-icon icon="tree"></fa-icon></button>
        <ng-template [ngIf]="user?.subRole?.type !== 'USER'">
          <button nzTooltipTitle="Linked Orders" nzTooltipPlacement="top" nzShape="circle" nz-button nzType="link" nz-tooltip [routerLink]="['/dashboard', { outlets: { dashboard: ['linked-orders', order?.id] } }]"><fa-icon icon="link"></fa-icon></button>
        </ng-template>
        <ng-template [ngIf]="user?.role?.type === 'SUPPLIER'">
          <button nzTooltipTitle="Upload Compliance Documents" nzTooltipPlacement="top" nzShape="circle" nz-button nzType="link" nz-tooltip><fa-icon icon="file-upload" (click)="openUploadDocumentDialog(order)"></fa-icon></button>
        </ng-template>
        <ng-template [ngIf]="user?.subRole?.type !== 'USER' && user?.role?.type !== 'SUPPLIER'">
          <button nzTooltipTitle="Download Compliance Documents" nzTooltipPlacement="top" nzShape="circle" nz-button nzType="link" nz-tooltip><fa-icon icon="file-download" (click)="openUploadedDocumentsDialog(order)"></fa-icon></button>
        </ng-template>
      </td>
    </ng-container>
  
    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
  </table>
  <mat-paginator [pageSizeOptions]="[5, 10, 25, 100]"></mat-paginator>
</div>

<!-- <nz-table #orderTable [nzData]="orders" [nzLoading]="showLoading">
  <thead>
    <tr>
      <th style="background: #4c4c4c; color: white; box-shadow: 5px 0 5px -2px #4c4c4c; z-index: 10;"><strong>Contract No</strong></th>
      <ng-template [ngIf]="user?.role?.type !== 'SUPPLIER'">
        <th style="background: #00a2a4; color: white; box-shadow: 5px 0 5px -2px #00a2a4; z-index: 9;"><strong>Account No</strong></th>
        <th style="background: #b5c435; color: white; box-shadow: 5px 0 5px -2px #b5c435; z-index: 8;"><strong>Project Cost Centre Code</strong></th>
      </ng-template>
      <ng-template [ngIf]="user?.role?.type === 'SUPPLIER'">
        <th style="background: #00a2a4; color: white; box-shadow: 5px 0 5px -2px #00a2a4; z-index: 9;"><strong>Ordering Company</strong></th>
      </ng-template>
      <th style="background: #046888; color: white; box-shadow: 5px 0 5px -2px #046888; z-index: 7;"><strong>Order No</strong></th>
      <th style="background: #004b62; color: white; box-shadow: 5px 0 5px -2px #004b62; z-index: 6;"><strong>Phase</strong></th>
      <th style="background: #4c4c4c; color: white; box-shadow: 5px 0 5px -2px #4c4c4c; z-index: 5;"><strong>No. of Items</strong></th>
      <th style="background: #00a2a4; color: white; box-shadow: 5px 0 5px -2px #00a2a4; z-index: 4;"><strong>Total Price</strong></th>
      <th style="background: #b5c435; color: white; box-shadow: 5px 0 5px -2px #b5c435; z-index: 3;"><strong>Destination</strong></th>
      <th style="background: #046888; color: white; box-shadow: 5px 0 5px -2px #046888; z-index: 2;"><strong>Created On</strong></th>
      <th style="background: #004b62; color: white; box-shadow: 5px 0 5px -2px #004b62; z-index: 1;"><strong>Status</strong></th>
      <th style="background: #004b62; color: white;"></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let order of orderTable.data let i = index;" [ngStyle]="{'background-color': (i % 2 == 0) ? '#f3f3f3' : '#ffffff' }">
      <td style="color: #4c4c4c;" [style.border-right]="'1px solid #d7d7d7'" [style.box-shadow]="(i+1)+'px 0 5px -2px #e2e2e2'">{{ order?.contract_no }}</td>
      <ng-template [ngIf]="user?.role?.type !== 'SUPPLIER'">
        <td style="color: #00a2a4;" [style.border-right]="'1px solid #d7d7d7'" [style.box-shadow]="(i+1)+'px 0 5px -2px #e2e2e2'">{{ order?.account_no }}</td>
        <td style="color: #b5c435;" [style.border-right]="'1px solid #d7d7d7'" [style.box-shadow]="(i+1)+'px 0 5px -2px #e2e2e2'">{{ order?.project_cost_centre_code }}</td>
      </ng-template>
      <ng-template [ngIf]="user?.role?.type === 'SUPPLIER'">
        <td style="color: #00a2a4;" [style.border-right]="'1px solid #d7d7d7'" [style.box-shadow]="(i+1)+'px 0 5px -2px #e2e2e2'">{{ order?.createdBy?.account?.name }}</td>
      </ng-template>
      <td style="color: #046888;" [style.border-right]="'1px solid #d7d7d7'" [style.box-shadow]="(i+1)+'px 0 5px -2px #e2e2e2'"><a [routerLink]="['/dashboard', { outlets: { dashboard: ['order', order?.id] } }]">{{ order?.order_no }}</a></td>
      <td style="color: #004b62;" [style.border-right]="'1px solid #d7d7d7'" [style.box-shadow]="(i+1)+'px 0 5px -2px #e2e2e2'">{{ order?.phase?.number }}</td>
      <td style="color: #4c4c4c;" [style.border-right]="'1px solid #d7d7d7'" [style.box-shadow]="(i+1)+'px 0 5px -2px #e2e2e2'">{{ order?.assets?.length }}</td>
      <td style="color: #00a2a4;" [style.border-right]="'1px solid #d7d7d7'" [style.box-shadow]="(i+1)+'px 0 5px -2px #e2e2e2'">£{{ order?.agreed_price | number: '1.2-3' }}</td>
      <td style="color: #b5c435;" [style.border-right]="'1px solid #d7d7d7'" [style.box-shadow]="(i+1)+'px 0 5px -2px #e2e2e2'">{{ order?.destination }}</td>
      <td style="color: #046888;" [style.border-right]="'1px solid #d7d7d7'" [style.box-shadow]="(i+1)+'px 0 5px -2px #e2e2e2'">{{ order?.createdOn | date: 'yyyy-MM-dd, h:mm a' }}</td>
      <td style="color: #004b62;" [style.border-right]="'1px solid #d7d7d7'" [style.box-shadow]="(i+1)+'px 0 5px -2px #e2e2e2'"><div class="circle" [ngClass]="calculateStatus(order?.assets)"></div></td>
      <td><button nzTooltipTitle="delete" nzTooltipPlacement="top" nzShape="circle" nz-button nzType="link" nz-tooltip (click)="delete(order?.id)"><i nz-icon nzType="delete"></i></button></td>
      <td>
        <button nzTooltipTitle="Track" nzTooltipPlacement="top" nzShape="circle" nz-button nzType="link" nz-tooltip [routerLink]="['/dashboard', { outlets: { dashboard: ['track', order?.id] } }]"><fa-icon icon="truck-moving"></fa-icon></button>
        <button nzTooltipTitle="Pay" nzTooltipPlacement="top" nzShape="circle" nz-button nzType="link" nz-tooltip [routerLink]="['/dashboard', { outlets: { dashboard: ['payment', order?.id] } }]"><fa-icon icon="money-bill"></fa-icon></button>
        <button nzTooltipTitle="Provenance" nzTooltipPlacement="top" nzShape="circle" nz-button nzType="link" nz-tooltip [routerLink]="['/dashboard', { outlets: { dashboard: ['provenance', order?.id] } }]"><fa-icon icon="tree"></fa-icon></button>
        <ng-template [ngIf]="user?.subRole?.type !== 'USER'">
          <button nzTooltipTitle="Linked Orders" nzTooltipPlacement="top" nzShape="circle" nz-button nzType="link" nz-tooltip [routerLink]="['/dashboard', { outlets: { dashboard: ['linked-orders', order?.id] } }]"><fa-icon icon="link"></fa-icon></button>
        </ng-template>
        <ng-template [ngIf]="user?.role?.type === 'SUPPLIER'">
          <button nzTooltipTitle="Upload Compliance Documents" nzTooltipPlacement="top" nzShape="circle" nz-button nzType="link" nz-tooltip><fa-icon icon="file-upload" (click)="openUploadDocumentDialog(order)"></fa-icon></button>
        </ng-template>
        <ng-template [ngIf]="user?.subRole?.type !== 'USER' && user?.role?.type !== 'SUPPLIER'">
          <button nzTooltipTitle="Download Compliance Documents" nzTooltipPlacement="top" nzShape="circle" nz-button nzType="link" nz-tooltip><fa-icon icon="file-download" (click)="openUploadedDocumentsDialog(order)"></fa-icon></button>
        </ng-template>
        <ng-template [ngIf]="user?.role?.type === 'SUPPLIER'">
          <button nzTooltipTitle="Create Requisition" nzTooltipPlacement="top" nzShape="circle" nz-button nzType="link" nz-tooltip [routerLink]="['/dashboard', { outlets: { dashboard: ['create-requisition'] } }]" [queryParams]="{projectId: order?.project}"><fa-icon icon="plus-square"></fa-icon></button>
        </ng-template>
      </td>
    </tr>
  </tbody>
</nz-table> -->
