import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';

import { getUrl } from './utils';
import { BasePlasmaService } from './base.plasma';


@Injectable({
  providedIn: 'root'
})
export class AssetService extends BasePlasmaService {

  protected namespace = 'assets';

  constructor(protected http: HttpClient) {
    super(http);
  }

  public create(asset: any) {
    return this.makePost(getUrl(this.namespace), asset) as Observable<any>;
  }

  public getAll() {
    return this.makeGet(getUrl(this.namespace)) as Observable<any>;
  }

  public getBySupplier(key) {
    return this.makeGet(`${getUrl(this.namespace)}?supplier=${key}`) as Observable<any>;
  }

  public getByAccount(key) {
    return this.makeGet(`${getUrl(this.namespace)}?account=${key}`) as Observable<any>;
  }

  public get(id: string) {
    return this.makeGet(`${getUrl(this.namespace)}/${id}`) as Observable<any>;
  }

  public update(asset: any) {
    return this.makePatch(`${getUrl(this.namespace)}/${asset.id}`, asset) as Observable<any>;
  }

  public delete(id) {
    return this.makeDelete(`${getUrl(this.namespace)}/${id}`) as Observable<any>;
  }

  public uploadDocuments(id, key, state, formData) {
    return this.makePost(`${getUrl(this.namespace)}/${id}/${key}/${state}/documents`, formData) as Observable<any>;
  }

}
