import { Component, OnInit } from '@angular/core';

import { AgentsService } from '@plasma/services/agents.plasma';
import { AuthService } from '@plasma/services/auth.plasma';

import { IAgent } from '@plasma/models/agent';

import { NzMessageService } from 'ng-zorro-antd/message';


@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {

  user: IAgent;
  users: any[];
  showLoading = false;
  displayedColumns: string[] = ['name', 'role', 'subRole', 'address', 'description', 'createdOn'];
  dataSource = null;

  constructor(private agentService: AgentsService,
              private authService: AuthService,
              private message: NzMessageService) { }

  ngOnInit(): void {

    this.authService.getUser()
      .subscribe((user: IAgent) => {
        console.log('user', user);
        this.user = user;
        this.loadUsers();
      });
  }

  loadUsers(): void {
    this.showLoading = true;
    this.agentService.getByAccount(this.user.account.id)
      .subscribe((users) => {
        this.users = users;
        this.dataSource = users;
        this.showLoading = false;
      }, (error) => {
        this.showLoading = false;
        console.error('Error', error);
        this.message.create('error', `Error: ${error.error}`);
      });
  }

}
