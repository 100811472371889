<nz-layout class="app-layout">
  <nz-sider class="menu-sidebar"
            nzCollapsible
            nzWidth="256px"
            nzBreakpoint="md"
            [(nzCollapsed)]="isCollapsed"
            [nzTrigger]="null">
    <div class="sidebar-logo">
      <a href="https://ng.ant.design/" target="_blank">
        <img src="https://ng.ant.design/assets/img/logo.svg" alt="logo">
        <h1>PLASMA</h1>
      </a>
    </div>
    <ul nz-menu nzTheme="dark" nzMode="inline" [nzInlineCollapsed]="isCollapsed">
      <li nz-submenu nzOpen nzTitle="Dashboard" nzIcon="dashboard">
        <ul>
          <li nz-menu-item nzMatchRouter>
            <a routerLink="/dashboard">VINCI</a>
          </li>
          <li nz-menu-item nzMatchRouter>
            <a routerLink="/supplier-a">Supplier A</a>
          </li>
          <li nz-menu-item nzMatchRouter>
            <a routerLink="/supplier-b">Supplier B</a>
          </li>
          <li nz-menu-item nzMatchRouter>
            <a routerLink="/supplier-c">Supplier C</a>
          </li>
        </ul>
      </li>
      <!-- <li nz-submenu nzOpen nzTitle="Form" nzIcon="form">
        <ul>
          <li nz-menu-item nzMatchRouter>
            <a>Basic Form</a>
          </li>
        </ul>
      </li> -->
    </ul>
  </nz-sider>
  <nz-layout>
    <nz-header>
      <div class="app-header">
        <span class="header-trigger" (click)="isCollapsed = !isCollapsed">
            <i class="trigger"
               nz-icon
               [nzType]="isCollapsed ? 'menu-unfold' : 'menu-fold'"
            ></i>
        </span>
      </div>
    </nz-header>
    <nz-content>
      <div class="inner-content">
        <!-- <router-outlet name="dashboard" ></router-outlet> -->
        <h3>Current Orders!</h3>
        <nz-table #basicTable [nzData]="orders">
          <thead>
            <tr>
              <th>Order No</th>
              <th>Supplier</th>
              <th>Quantity</th>
              <th>Unit Price</th>
              <th>Agreed Price</th>
              <th>Delivery Location</th>
              <th>Completion Date</th>
              <th>Description</th>
              <!-- <th>Payment Terms</th>
              <th>Discounts</th> -->
              <th>SKU</th>
              <th>Billing Address</th>
              <!-- <th>Action</th> -->
            </tr>
          </thead>
          <tbody>
            <tr *ngFor="let data of basicTable.data">
              <td>{{ data.orderNo }}</td>
              <td>{{ data.supplier }}</td>
              <td>{{ data.quantity }}</td>
              <td>{{ data.unitPrice }}</td>
              <td>{{ data.agreedPrice }}</td>
              <td>{{ data.deliveryLocation }}</td>
              <td>{{ data.completionDate }}</td>
              <td>{{ data.description }}</td>
              <!-- <td>{{ data.paymentTerms }}</td>
              <td>{{ data.discounts }}</td> -->
              <td>{{ data.sku }}</td>
              <td>{{ data.billingAddress }}</td>
              <!-- <td>
                <a>Action 一 {{ data.orderNo }}</a>
                <nz-divider nzType="vertical"></nz-divider>
                <a>Delete</a>
              </td> -->
            </tr>
          </tbody>
        </nz-table>
      </div>
    </nz-content>
  </nz-layout>
</nz-layout>
