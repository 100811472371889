import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';

import { getUrl } from './utils';
import { BasePlasmaService } from './base.plasma';


@Injectable({
  providedIn: 'root'
})
export class PlacesService extends BasePlasmaService {

  protected namespace = 'places';

  constructor(protected http: HttpClient) {
    super(http);
  }

  public search(search: string) {
    if (!search || search.length <= 2) {
      return of([]);
    }
    return this.makeGet(`${getUrl(this.namespace)}/search/${search}`) as Observable<any>;
  }

  public get(id: string) {
    return this.makeGet(`${getUrl(this.namespace)}/${id}`) as Observable<any>;
  }

}
