import { AfterViewInit, Component, OnInit, OnDestroy, ViewChild, ElementRef, Renderer2 } from '@angular/core';
import { FormBuilder, FormControl, FormArray, FormGroup, Validators } from '@angular/forms';
import { Location } from '@angular/common';

import { InventoryService } from '@plasma/services/inventory.plasma';
import { AuthService } from '@plasma/services/auth.plasma';
import { OrderService } from '@plasma/services/order.plasma';
import { TradeService } from '@plasma/services/trade.plasma';
import { AssetService } from '@plasma/services/asset.plasma';
import { PlacesService } from '@plasma/services/places.plasma';

import { IAgent } from '@plasma/models/agent';
import { IInventory } from '@plasma/models/inventory';

import { NzMessageService } from 'ng-zorro-antd/message';

import { LoadingService } from '@plasma/components/loading/loading.service';

import { Subject } from 'rxjs';
import { takeUntil, debounceTime, tap, switchMap, finalize, take } from 'rxjs/operators';

import * as moment from 'moment';


@Component({
  selector: 'app-create-inventory',
  templateUrl: './create-inventory.component.html',
  styleUrls: ['./create-inventory.component.scss']
})
export class CreateInventoryComponent implements OnInit, OnDestroy {

  user: IAgent;
  inventory: FormGroup;
  orders: any;
  private parentProductsValidators = null;
  private destroy$ = new Subject<void>();
  formattedaddress = ' ';
  filteredCommodities: any;
  areCommodityCodesLoading = false;
  filteredAssets: any;

  filteredOriginOfManufacturer: any;
  areOriginOfManufacturerLoading = false;

  filteredCompanyAddress: any;
  areCompanyAddressLoading = false;

  constructor(private fb: FormBuilder,
              private orderService: OrderService,
              private inventoryService: InventoryService,
              private authService: AuthService,
              private tradeService: TradeService,
              private assetService: AssetService,
              private placesService: PlacesService,
              private loadingService: LoadingService,
              private message: NzMessageService,
              private location: Location) { }

  ngOnInit(): void {
    this.authService.getUser()
      .pipe(takeUntil(this.destroy$))
      .subscribe((user: IAgent) => {
        this.user = user;
        this.loadOrders();
        this.loadAssets();
        /* this.inventory.get('account').patchValue(user.account.id); */
        this.loadingService.complete();
    }, error => { console.error(error); this.loadingService.complete(); });

    this.inventory = this.fb.group({
      account: [this.user.account.id, [Validators.required]],
      productName: [null, [Validators.required]],
      assetID: [null],
      quantity: [null, [Validators.required]],
      company: [this.user.account.name, [Validators.required]],
      companyAddress: this.fb.group({
        address: [this.user.address.address, [Validators.required]],
        lat: [this.user.address.lat, [Validators.required]],
        lng: [this.user.address.lng, [Validators.required]]
      }),
      internalPartNumber: [null],
      itemCountForProductionRun: [null],
      originOfManufacturer: this.fb.group({
        address: [this.user.address.address, [Validators.required]],
        lat: [this.user.address.lat, [Validators.required]],
        lng: [this.user.address.lng, [Validators.required]]
      }),
      manufactureDate: [null],
      dimensionsOfProduct: [null],
      associatedCode: [null, [Validators.required]],
      sku: [null],
      inventoryType: [null],
      productionProcessDetails: [null, [Validators.required]],
      materialsUsed: [null, [Validators.required]],
      isoCertificationNumber: [null],
      qualityAssurance: [null],
      safetySheetHyperlinks: [null],
      websiteLinks: [null],
      applicableLaws: [null, [Validators.required]],
      isRawMaterial: [true]
    });

    this.onAssociatedCodeChanges();
    this.onCompanyAddressChanges();
    this.onOriginOfManufacturerChanges();
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  onAssociatedCodeChanges() {
    this.inventory.get('associatedCode').valueChanges
      .pipe(
        debounceTime(500),
        tap(() => {
          this.filteredCommodities = [];
          this.areCommodityCodesLoading = true;
        }),
        switchMap(value => this.tradeService.search(value)
          .pipe(
            finalize(() => {
              this.areCommodityCodesLoading = false;
            }),
          )
        )
      )
      .subscribe((commodities: any) => {
        if (!commodities.data) {
          this.filteredCommodities = [];
        } else {
          this.filteredCommodities = commodities?.data;
        }
      }, (error) => {
        console.error('Error', error);
      });
  }

  onCompanyAddressSelected(event) {
    console.log('event', event);
    if (event.isUserInput) {
      const value = event.source.value;
      console.log('value', value);
      this.placesService.get(value.place_id)
        .pipe(take(1))
        .subscribe(place => this.inventory.get('companyAddress').get('address').patchValue(place.result.formatted_address));
    }
  }


  onCompanyAddressChanges() {
    this.inventory.get('companyAddress').get('address').valueChanges
      .pipe(
        debounceTime(550),
        tap(() => {
          this.filteredCompanyAddress = [];
          this.areCompanyAddressLoading = true;
        }),
        switchMap(value => this.placesService.search(value)
          .pipe(
            finalize(() => {
              this.areCompanyAddressLoading = false;
            }),
          )
        )
      )
      .subscribe((addresses: any) => {
        if (!addresses) {
          this.filteredCompanyAddress = [];
        } else {
          this.filteredCompanyAddress = addresses;
        }
      }, (error) => {
        console.error('Error', error);
      });
  }

  onOriginOfManufacturerSelected(event) {
    console.log('event', event);
    if (event.isUserInput) {
      const value = event.source.value;
      console.log('value', value);
      this.placesService.get(value.place_id)
        .pipe(take(1))
        .subscribe(place => this.inventory.get('originOfManufacturer').get('address').patchValue(place.result.formatted_address));
    }
  }


  onOriginOfManufacturerChanges() {
    this.inventory.get('originOfManufacturer').get('address').valueChanges
      .pipe(
        debounceTime(550),
        tap(() => {
          this.filteredOriginOfManufacturer = [];
          this.areOriginOfManufacturerLoading = true;
        }),
        switchMap(value => this.placesService.search(value)
          .pipe(
            finalize(() => {
              this.areOriginOfManufacturerLoading = false;
            }),
          )
        )
      )
      .subscribe((addresses: any) => {
        if (!addresses) {
          this.filteredOriginOfManufacturer = [];
        } else {
          this.filteredOriginOfManufacturer = addresses;
        }
      }, (error) => {
        console.error('Error', error);
      });
  }

  loadOrders() {
    if (!this.user) {
      return;
    }

    this.orderService.getByAccount(this.user?.account?.id)
      .subscribe((orders) => {
        console.log('orders', orders);
        this.orders = orders;
      }, (error) => {
        console.error('Error', error);
        this.message.create('error', `Error: ${error.error.error}`);
      });
  }

  loadAssets() {
    if (!this.user) {
      return;
    }

    this.assetService.getBySupplier(this.user.publicKey)
      .subscribe(
        (assets) => this.filteredAssets = assets,
        (error) => console.error('Error', error));
  }

  onCompanyAddressChange(address) {
    const lat = address.geometry.location.lat();
    const lng = address.geometry.location.lng();
    this.inventory.get('companyAddress').get('lat').setValue(lat);
    this.inventory.get('companyAddress').get('lng').setValue(lng);
  }

  onOriginOfManufacturerChange(address) {
    const lat = address.geometry.location.lat();
    const lng = address.geometry.location.lng();
    this.inventory.get('originOfManufacturer').get('lat').setValue(lat);
    this.inventory.get('originOfManufacturer').get('lng').setValue(lng);
  }

  onOptionSelection(event) {
    const asset = this.filteredAssets.find(asset => asset.asset_type === event.option.value);
    if (asset) {
      this.inventory.get('assetID').setValue(asset.id);
    }
  }

  onSubmit({value, valid}: {value: any, valid: boolean}) {
    console.log('value', value);
    if (!valid) {
      return;
    }

    value['createdOn'] = moment().valueOf();

    console.log('inventory', value);

    this.loadingService.start();
    this.inventoryService.create(value)
      .subscribe((resp) => {
        console.log('resp', resp);
        this.loadingService.complete();
        this.message.create('success', `Item was added successfully!`);
        this.onBack();
      }, (error) => {
        this.loadingService.complete();
        console.error('Error', error);
        // this.message.create('error', `Error: ${error.error.error}`);
      });
  }

  onBack() {
    this.location.back();
  }

}
