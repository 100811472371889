import { Component, HostListener, Inject } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';


export interface DialogData {
    animal: 'panda' | 'unicorn' | 'lion';
}


@Component({
  selector: 'app-help-dialog',
  templateUrl: './help-dialog.component.html',
  styleUrls: ['./help-dialog.component.scss']
})
export class HelpDialogComponent {

    ngOnInit(): void {
      if (window.innerWidth > 900) {
        this.isMobile = false
      }
    }

    isMobile = true;

    //switches between mobile view and desktop responsively
    @HostListener('window:resize', [])
    onResize () {
      if (this.isMobile && window.innerWidth > 900) {
        this.isMobile = false
      }
      else if (!this.isMobile && window.innerWidth <= 899) {
        this.isMobile = true
      }
    }

    constructor(@Inject(MAT_DIALOG_DATA) public data: any) {}

    getImageLink (imgLink: string, isMobileTutorial: boolean) {

      if (this.isMobile && !isMobileTutorial) {
        return 'assets/img/mobile-'+imgLink
      }
      else {
        return 'assets/img/'+imgLink
      }
    }
}
