import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';

import { getUrl } from './utils';
import { BasePlasmaService } from './base.plasma';


@Injectable({
  providedIn: 'root'
})
export class EmailService extends BasePlasmaService {

  protected namespace = 'email';

  constructor(protected http: HttpClient) {
    super(http);
  }

  public invite(body: any) {
    return this.makePost(`${getUrl(this.namespace)}/invite`, body) as Observable<any>;
  }

  public inviteProjectOwner(body: any) {
    return this.makePost(`${getUrl(this.namespace)}/inviteProjectOwner`, body) as Observable<any>;
  }

}
