import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NzModalRef } from 'ng-zorro-antd/modal';

import { IAgent } from '@plasma/models/agent';

import { v4 as uuidv4 } from 'uuid';
import * as moment from 'moment';


@Component({
  selector: 'app-create-phase-modal',
  templateUrl: './create-phase-modal.component.html'
})
export class CreatePhaseModalComponent implements OnInit {

  @Input() user: IAgent;
  @Input() project: string;
  phase: FormGroup;

  constructor(private modal: NzModalRef, private fb: FormBuilder) { }

  ngOnInit(): void {
    this.phase = this.fb.group({
      id: [uuidv4(), [Validators.required]],
      account: [this.user?.account?.id, [Validators.required]],
      project: [this.project, [Validators.required]],
      name: [null, [Validators.required]],
      number: [null, [Validators.required]],
      createdOn: [null]
    });
  }

  createPhase(): void {
    const {value, valid} = this.phase;
    if (valid) {
      value['createdOn'] = moment().valueOf();
      this.modal.destroy(value);
    }
  }

}
