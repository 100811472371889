import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';

import { getUrl } from './utils';
import { BasePlasmaService } from './base.plasma';


@Injectable({
  providedIn: 'root'
})
export class TradeService extends BasePlasmaService {

  protected namespace = 'trade';

  constructor(protected http: HttpClient) {
    super(http);
  }

  public search(search: string) {
    return this.makeGet(`${getUrl(this.namespace)}/search/${search}`) as Observable<any>;
  }

}
