<nz-page-header class="site-page-header" (nzBack)="onBack()" nzBackIcon nzTitle="Add Inventory Item"></nz-page-header>
<form novalidate [formGroup]="inventory" (ngSubmit)="onSubmit(inventory)">
    <div [hidden]="true" fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between center">
      <div fxLayoutAlign="center center" id="field-container">
        <mat-form-field appearance="fill" fxFlexFill>
          <mat-label>Account</mat-label>
          <input matInput formControlName="account">
          <mat-error *ngIf="inventory.get('account').invalid">The input is not a valid Account!</mat-error>
        </mat-form-field>
      </div>
    </div>
    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between center">
      <div fxLayoutAlign="center center" id="field-container">
        <!-- <mat-form-field appearance="fill" fxFlexFill>
          <mat-label>Product Name</mat-label>
          <input required matInput formControlName="productName">
          <mat-error *ngIf="inventory.get('productName').invalid">The input is not a valid Product Name!</mat-error>
        </mat-form-field> -->
        <mat-form-field appearance="fill" fxFlexFill>
          <mat-label>Product Name</mat-label>
          <input type="text" required matInput aria-label="product name" [formControl]="inventory.get('productName')" [matAutocomplete]="product">
          <mat-autocomplete #product="matAutocomplete" (optionSelected)="onOptionSelection($event)">
            <mat-option *ngFor="let asset of filteredAssets" [value]="asset.asset_type">
              {{asset.asset_type}} ({{asset.id}})
            </mat-option>
          </mat-autocomplete>
          <mat-error *ngIf="inventory.get('productName').invalid">The input is not a valid Product Name!</mat-error>
        </mat-form-field>
      </div>
      
      <div fxLayoutAlign="center center" id="field-container">
        <mat-form-field appearance="fill" fxFlexFill>
          <mat-label>Company</mat-label>
          <input required matInput formControlName="company">
          <mat-error *ngIf="inventory.get('company').invalid">The input is not a valid Company!</mat-error>
        </mat-form-field>
      </div>
      
      <ng-container formGroupName="companyAddress">
        <div fxLayoutAlign="center center" id="field-container">
          <mat-form-field appearance="fill" fxFlexFill>
            <mat-label>Company Address</mat-label>
            <input required matInput formControlName="address" [matAutocomplete]="company">
            <mat-autocomplete #company="matAutocomplete">
              <mat-option *ngIf="areCompanyAddressLoading" class="is-loading">Loading...</mat-option>
              <ng-container *ngIf="!areCompanyAddressLoading">
                <mat-option *ngFor="let address of filteredCompanyAddress" (onSelectionChange)="onCompanyAddressSelected($event)" [value]="address">
                  <span>{{address?.description}}</span>
                </mat-option>
              </ng-container>
            </mat-autocomplete>
            <mat-error *ngIf="inventory.get('companyAddress').get('address').invalid">The input is not a valid Address!</mat-error>
          </mat-form-field>
        </div>
      </ng-container>
      
    </div>

    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between center">
      <div fxLayoutAlign="center center" id="field-container">
        <mat-form-field appearance="fill" fxFlexFill>
          <mat-label>Internal Part Number</mat-label>
          <input matInput formControlName="internalPartNumber">
          <mat-error *ngIf="inventory.get('internalPartNumber').invalid">The input is not a valid Internal Part Number!</mat-error>
        </mat-form-field>
      </div>
      
      <div fxLayoutAlign="center center" id="field-container">
        <mat-form-field appearance="fill" fxFlexFill>
          <mat-label>Item count for production run</mat-label>
          <input matInput formControlName="itemCountForProductionRun">
          <mat-error *ngIf="inventory.get('itemCountForProductionRun').invalid">The input is not a valid Item Count!</mat-error>
        </mat-form-field>
      </div>
      
      <ng-container formGroupName="originOfManufacturer">
        <div fxLayoutAlign="center center" id="field-container">
          <mat-form-field appearance="fill" fxFlexFill>
            <mat-label>Origin of Manufacturer</mat-label>
            <input type="text" required matInput formControlName="address" [matAutocomplete]="manufacturer">
            <mat-autocomplete #manufacturer="matAutocomplete">
              <mat-option *ngIf="areOriginOfManufacturerLoading" class="is-loading">Loading...</mat-option>
              <ng-container *ngIf="!areOriginOfManufacturerLoading">
                <mat-option *ngFor="let address of filteredOriginOfManufacturer" (onSelectionChange)="onOriginOfManufacturerSelected($event)" [value]="address">
                  <span>{{address?.description}}</span>
                </mat-option>
              </ng-container>
            </mat-autocomplete>
            <mat-error *ngIf="inventory.get('originOfManufacturer').invalid">The input is not a valid Origin of Manufacturer!</mat-error>
          </mat-form-field>
        </div>
      </ng-container>
    </div>

    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between center">
      <div fxLayoutAlign="center center" id="field-container">
        <mat-form-field appearance="fill" fxFlexFill>
          <mat-label>Manufacture Date</mat-label>
          <input matInput formControlName="manufactureDate" [matDatepicker]="manufactureDate">
          <mat-datepicker-toggle matSuffix [for]="manufactureDate"></mat-datepicker-toggle>
          <mat-datepicker #manufactureDate></mat-datepicker>
          <mat-error *ngIf="inventory.get('manufactureDate').invalid">The input is not a valid Manufacture Date!</mat-error>
        </mat-form-field>
      </div>
      
      <div fxLayoutAlign="center center" id="field-container">
        <mat-form-field appearance="fill" fxFlexFill>
          <mat-label>Dimensions of Product</mat-label>
          <input matInput formControlName="dimensionsOfProduct">
          <mat-error *ngIf="inventory.get('dimensionsOfProduct').invalid">The input is not a valid Dimension!</mat-error>
        </mat-form-field>
      </div>
      
      <div fxLayoutAlign="center center" id="field-container">
        <!-- <mat-form-field appearance="fill" fxFlexFill>
          <mat-label>Associated code; ASIN, EAN, UPC, ISBN</mat-label>
          <input required matInput formControlName="associatedCode">
          <mat-error *ngIf="inventory.get('associatedCode').invalid">The input is not a valid Associated Code!</mat-error>
        </mat-form-field> -->
        <mat-form-field appearance="fill" fxFlexFill>
          <mat-label>Associated code; ASIN, EAN, UPC, ISBN</mat-label>
          <input type="text" required matInput aria-label="code" [formControl]="inventory.get('associatedCode')" [matAutocomplete]="auto">
          <mat-autocomplete #auto="matAutocomplete">
            <mat-option *ngIf="areCommodityCodesLoading" class="is-loading">Loading...</mat-option>
            <ng-container *ngIf="!areCommodityCodesLoading">
              <mat-option *ngFor="let commodity of filteredCommodities" [value]="commodity?.attributes?.referenced_id">
                <span><b>{{commodity?.attributes?.title}}</b> ({{commodity?.attributes?.referenced_id}})</span>
              </mat-option>
            </ng-container>
          </mat-autocomplete>
          <mat-error *ngIf="inventory.get('associatedCode').invalid">The input is not a valid Associated Code!</mat-error>
        </mat-form-field>
      </div>
    </div>

    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between center">
      <div fxLayoutAlign="center center" id="field-container">
        <mat-form-field appearance="fill" fxFlexFill>
          <mat-label>Inventory Managerment Information (SKU)</mat-label>
          <input matInput formControlName="sku">
          <mat-error *ngIf="inventory.get('sku').invalid">The input is not a valid SKU!</mat-error>
        </mat-form-field>
      </div>
      
      <div fxLayoutAlign="center center" id="field-container">
        <mat-form-field appearance="fill" fxFlexFill>
          <mat-label>Inventory Type; Raw, WIP, Finished, MRO</mat-label>
          <input matInput formControlName="inventoryType">
          <mat-error *ngIf="inventory.get('inventoryType').invalid">The input is not a valid Inventory Type!</mat-error>
        </mat-form-field>
      </div>
      
      <div fxLayoutAlign="center center" id="field-container">
        <mat-form-field appearance="fill" fxFlexFill>
          <mat-label>Production Process Details</mat-label>
          <input required matInput formControlName="productionProcessDetails">
          <mat-error *ngIf="inventory.get('productionProcessDetails').invalid">The input is not a valid Production Process Detail!</mat-error>
        </mat-form-field>
      </div>
    </div>

    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between center">
      <div fxLayoutAlign="center center" id="field-container">
        <mat-form-field appearance="fill" fxFlexFill>
          <mat-label>Materials Used</mat-label>
          <input required matInput formControlName="materialsUsed">
          <mat-error *ngIf="inventory.get('materialsUsed').invalid">The input is not a valid Materials Used!</mat-error>
        </mat-form-field>
      </div>
      
      <div fxLayoutAlign="center center" id="field-container">
        <mat-form-field appearance="fill" fxFlexFill>
          <mat-label>ISO Certification Number</mat-label>
          <input matInput formControlName="isoCertificationNumber">
          <mat-error *ngIf="inventory.get('isoCertificationNumber').invalid">The input is not a valid ISO Certification Number!</mat-error>
        </mat-form-field>
      </div>
      
      <div fxLayoutAlign="center center" id="field-container">
        <mat-form-field appearance="fill" fxFlexFill>
          <mat-label>Quality Assurance ID Numbers</mat-label>
          <input matInput formControlName="qualityAssurance">
          <mat-error *ngIf="inventory.get('qualityAssurance').invalid">The input is not a valid Quality Assurance ID Numbers!</mat-error>
        </mat-form-field>
      </div>
    </div>

    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between center">
      <div fxLayoutAlign="center center" id="field-container">
        <mat-form-field appearance="fill" fxFlexFill>
          <mat-label>Safety Sheet Hyperlinks</mat-label>
          <input matInput formControlName="safetySheetHyperlinks">
          <mat-error *ngIf="inventory.get('safetySheetHyperlinks').invalid">The input is not a valid Safety Sheet Hyperlinks!</mat-error>
        </mat-form-field>
      </div>
      
      <div fxLayoutAlign="center center" id="field-container">
        <mat-form-field appearance="fill" fxFlexFill>
          <mat-label>Website Links</mat-label>
          <input matInput formControlName="websiteLinks">
          <mat-error *ngIf="inventory.get('websiteLinks').invalid">The input is not a valid Website Links!</mat-error>
        </mat-form-field>
      </div>
      
      <div fxLayoutAlign="center center" id="field-container">
        <mat-form-field appearance="fill" fxFlexFill>
          <mat-label>Applicable Laws or Regulation Sections</mat-label>
          <input required matInput formControlName="applicableLaws">
          <mat-error *ngIf="inventory.get('applicableLaws').invalid">The input is not a valid Applicable Laws or Regulation Sections!</mat-error>
        </mat-form-field>
      </div>
    </div>
    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="space-between center">
      <!-- <div fxLayoutAlign="center center" id="field-container">
        <mat-form-field appearance="fill" fxFlexFill>
          <mat-label>Parent Product(s) or Product Variation Provenance Code</mat-label>
          <mat-select [required]="!inventory.get('isRawMaterial').value" formControlName="parentProducts" multiple>
            <mat-optgroup *ngFor="let order of orders" [label]="order.order_no">
              <mat-option *ngFor="let asset of order.assets" [value]="asset?.id">
                {{ asset?.asset_type }} - {{ asset?.id }}
              </mat-option>
            </mat-optgroup>
          </mat-select>
          <mat-error *ngIf="inventory.get('parentProducts').invalid">The input is not a valid Parent Product or Product Variation Provenance Code!</mat-error>
        </mat-form-field>
      </div> -->

      <div fxLayoutAlign="center center" id="field-container">
        <mat-checkbox formControlName="isRawMaterial">Raw Material</mat-checkbox>
      </div>

      <div fxLayoutAlign="center center" id="field-container">
        <mat-form-field appearance="fill" fxFlexFill>
          <mat-label>Quantity</mat-label>
          <input matInput required formControlName="quantity">
          <!-- <mat-error *ngIf="order.get('projectCostCentreCode').invalid">The input is not a valid Project Cost Centre Code!</mat-error> -->
        </mat-form-field>
      </div>
    </div>
    <div fxLayout="row" fxLayout.lt-md="column" fxLayoutAlign="end center">
      <div fxLayoutAlign="center center" id="field-container">
        <button type="submit" mat-raised-button fxFlexFill [disabled]="inventory.invalid" style="color: white; background-color: #2196f3;">Submit</button>
      </div>
    </div>
</form>