<h1 mat-dialog-title>{{data.title}}</h1>
<mat-dialog-content class="mat-typography">
  <ng-container *ngIf="data.paragraphs.length > 0">
    <p *ngFor="let paragraph of data.paragraphs; let i = index;">{{paragraph}}</p>
    <img *ngFor="let imgLink of data.imgLinks; let i = index;" [src]="getImageLink(imgLink, data.isMobileTutorial)" [width]="isMobile || data.isMobileTutorial ? '300' : '800'">
  </ng-container>
</mat-dialog-content>
<mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cancel</button>
</mat-dialog-actions>
