import { Component, Input, OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NzModalRef } from 'ng-zorro-antd/modal';

import { v4 as uuidv4 } from 'uuid';
import * as moment from 'moment';


@Component({
  selector: 'app-create-account-modal',
  templateUrl: './create-account-modal.component.html'
})
export class CreateAccountModalComponent implements OnInit {

  @Input() name?: string;
  @Input() address?: string;

  account: FormGroup;

  constructor(private modal: NzModalRef, private fb: FormBuilder) { }

  ngOnInit(): void {
    this.account = this.fb.group({
      id: [uuidv4(), [Validators.required]],
      name: [this.name ? this.name : null, [Validators.required]],
      officeAddress: [this.address ? this.address : null],
      billingAddress: [this.address ? this.address : null],
      phoneNo: [null],
      createdOn: [null]
    });
  }

  createAccount(): void {
    const {value, valid} = this.account;
    if (valid) {
      value['createdOn'] = moment().valueOf();
      this.modal.destroy(value);
    }
  }

}
