<div *nzModalTitle>
  Custom Modal Title
</div>
<div class="container">
  <nz-tabset nzSize="small" nzType="card">
    <nz-tab nzTitle="Assign Users" [nzDisabled]="!user?.canAssignUsers">
      <form novalidate nz-form [formGroup]="proj" (ngSubmit)="assignUsers()">
        <div class="form-row">
          <div class="col-md-12">
            <div class="form-group row">
              <div class="col-sm-9">
                <nz-select nzMode="multiple" formControlName="assignedUsers" [nzDisabled]="!user?.canAssignUsers">
                  <nz-option *ngFor="let user of users" [nzLabel]="user.name" [nzValue]="user.publicKey" [nzHide]="!isNotSelected(user)"></nz-option>
                </nz-select>
              </div>
              <div class="col-sm-3">
                <nz-form-control>
                  <button nz-button nzType="primary" [disabled]="!proj.valid || !user?.canAssignUsers">Assign</button>
                </nz-form-control>
              </div>
            </div>
          </div>
        </div>
      </form>
    </nz-tab>
    <nz-tab nzTitle="Invite Users" [nzDisabled]="!user?.canInviteUsers">
      <form novalidate nz-form [formGroup]="invitation" (ngSubmit)="inviteUser()">
        <div class="form-row">
          <div class="col-md-12">
            <div class="form-group row">

              <div class="col-sm-9">
                <nz-form-control nzErrorTip="Please input an email">
                  <nz-input-group [nzSuffix]="inputClear">
                    <input type="email" nz-input formControlName="inviteUser" />
                  </nz-input-group>
                </nz-form-control>
                <ng-template #inputClear>
                  <i nz-icon class="ant-input-clear-icon" nzTheme="fill" nzType="close-circle" *ngIf="invitation.get('inviteUser').value" (click)="invitation.get('inviteUser').setValue(null)"></i>
                </ng-template>
              </div>
              <div class="col-sm-3">
                <nz-form-control>
                  <button nz-button nzType="primary" [disabled]="!invitation.valid">Invite</button>
                </nz-form-control>
              </div>
            </div>
          </div>
        </div>
      </form>
    </nz-tab>
  </nz-tabset>
</div>
