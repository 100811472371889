<form novalidate [formGroup]="phase">
  <div class="form-row">
    <div class="col-md-12">
      <div class="form-group row">
        <label for="name" class="col-sm-5 col-form-label required">Name</label>
        <div class="col-sm-7">
          <input type="text" formControlName="name" class="form-control form-control-sm" [ngClass]="{ 'is-invalid': (phase.get('name').dirty || phase.get('name').touched) && phase.get('name').errors }" id="name">
          <div class="invalid-feedback" *ngIf="(phase.get('name').dirty || phase.get('name').touched) && phase.get('name').hasError('required')">
            The input is not valid Name!
          </div>
        </div>
      </div>
      <div class="form-group row">
        <label for="number" class="col-sm-5 col-form-label required">No.</label>
        <div class="col-sm-7">
          <input type="number" formControlName="number" class="form-control form-control-sm" [ngClass]="{ 'is-invalid': (phase.get('number').dirty || phase.get('number').touched) && phase.get('number').errors }" id="number">
          <div class="invalid-feedback" *ngIf="(phase.get('number').dirty || phase.get('number').touched) && phase.get('number').hasError('required')">
            The input is not valid No.!
          </div>
        </div>
      </div>
    </div>
  </div>
</form>
