import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import { OrderService } from '@plasma/services/order.plasma';
import { AuthService } from '@plasma/services/auth.plasma';

import { IOrder } from '@plasma/models/order';
import { ISupplier } from '@plasma/models/supplier';
import { IAgent } from '@plasma/models/agent';

import { NzMessageService } from 'ng-zorro-antd/message';
import { UploadChangeParam, UploadFile } from 'ng-zorro-antd/upload';


@Component({
  selector: 'app-order',
  templateUrl: './order.component.html',
  styleUrls: ['./order.component.scss']
})
export class OrderComponent implements OnInit {

  id: string;
  order: any;
  stageFiles: UploadFile[] = [];
  uploading = false;

  constructor(private orderService: OrderService,
              private authService: AuthService,
              private message: NzMessageService,
              protected activatedRoute: ActivatedRoute,
              private location: Location) { }

  ngOnInit(): void {

    this.id = this.activatedRoute.snapshot.paramMap.get('id');
    if (!this.id) {
      return;
    }

    this.orderService.get(this.id)
      .subscribe((order) => {
        this.order = order;
        console.log('order', order);
      }, (error) => {
        console.error('Error', error);
        this.message.create('error', `Error: ${error.error.error}`);
      });

  }

  extractFilename(s) {
    return s.split(/^.*[\\\/](.+)/)[1];
  }

  beforeUpload = (file: UploadFile): boolean => {
    this.stageFiles = this.stageFiles.concat(file);
    return false;
  }

  handleUpload(e: any): void {
    e.preventDefault();
    const formData = new FormData();

    this.stageFiles.forEach((file: any) => {
      formData.append('documents', file);
    });
    this.uploading = true;

    this.orderService.uploadDocuments(this.id, formData)
      .subscribe((resp) => {
        console.log('resp', resp);
        this.uploading = false;
        this.stageFiles = [];
        for (const document of resp?.links) {
          this.order?.documents?.push(document);
        }
        this.message.success(`Files uploaded successfully!`);
      }, (error) => {
        console.error('Error', error);
        this.message.error(`Error ${error.error.error}`);
      });
  }

  onBack() {
    this.location.back();
  }

}
