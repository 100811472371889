import { Component, HostListener, OnInit, ViewContainerRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import { ProjectService } from '@plasma/services/project.plasma';
import { AuthService } from '@plasma/services/auth.plasma';
import { PhaseService } from '@plasma/services/phase.plasma';
import { OrderService } from '@plasma/services/order.plasma';
import { AgentsService } from '@plasma/services/agents.plasma';
import { EmailService } from '@plasma/services/email.plasma';

import { IAgent } from '@plasma/models/agent';
import { IProject } from '@plasma/models/project';

import { NzModalRef, NzModalService } from 'ng-zorro-antd/modal';
import { NzMessageService } from 'ng-zorro-antd/message';

import { LoadingService } from '@plasma/components/loading/loading.service';

import { CreatePhaseModalComponent } from '@plasma/components/modal/create-phase-modal.component';
import { AssignUsersModalComponent } from '@plasma/components/modal/assign-users-modal.component';
import { ImportOrdersModalComponent } from '@plasma/components/modal/import-orders-modal.component';

import * as moment from 'moment';


@Component({
  selector: 'app-project-status',
  templateUrl: './project-status.component.html',
  styleUrls: ['./project-status.component.scss']
})
export class ProjectStatusComponent implements OnInit {

  id: string;
  user: IAgent;
  project: any;
  delayedOrders: any;
  showLoading = false;
  displayRejectedDamagedStatus = false;
  initOpts = {
    width: 200,
    height: 300
  };

  stackedBarChart: any = {
    tooltip: {
        trigger: 'axis',
        axisPointer: {            // Use axis to trigger tooltip
            type: 'shadow'        // 'shadow' as default; can also be 'line' or 'shadow'
        }
    },
    legend: {},
    grid: {
      left: '3%',
      right: '4%',
      bottom: '3%',
      containLabel: true
    },
    xAxis: {
        type: 'value',
        show: false
    },
    yAxis: {
        type: 'category',
        data: []
    },
    series: [],
    color: ['#91cc75', '#fac858', '#ee6666']
  };

  rejectedDamagedAssetsChart: any = {
    tooltip: {
        trigger: 'axis',
        axisPointer: {            // Use axis to trigger tooltip
            type: 'shadow'        // 'shadow' as default; can also be 'line' or 'shadow'
        }
    },
    legend: {},
    grid: {
        left: '3%',
        right: '4%',
        bottom: '3%',
        containLabel: true
    },
    xAxis: {
        type: 'value',
        show: false
    },
    yAxis: {
        type: 'category',
        data: []
    },
    series: [],
    color: ['#ee6666', '#fac858']
  };

  nestedPieChart: any = {
    tooltip: {
      trigger: 'item',
      formatter: '{a} <br/>{b}: {c}'
    },
    legend: {
      data: [
        'On Time',
        'Under Float Days',
        'Delayed'
      ]
    },
    series: [
      {
        name: 'Phase 1',
        type: 'pie',
        radius: ['10%', '20%'],
        label: {
          position: 'inner',
          fontSize: 14
        },
        labelLine: {
          show: false
        },
        data: [
          { value: 1, name: 'On Time' },
        ]
      },
      {
        name: 'Phase 1',
        type: 'pie',
        radius: ['30%', '40%'],
        label: {
          position: 'inner',
          fontSize: 14
        },
        labelLine: {
          show: false
        },
        data: [
          { value: 3, name: 'Under Float Days' }
        ]
      },
      {
        name: 'Phase 1',
        type: 'pie',
        radius: ['50%', '60%'],
        labelLine: {
          length: 30
        },
        label: {
          position: 'inner',
          fontSize: 14
        },
        data: [
          { value: 2, name: 'Delayed' }
        ]
      }
    ],
    color: ['#91cc75', '#fac858', '#ee6666']
  };

  configParameters = {
    rotate: {
      min: -90,
      max: 90
    },
    align: {
      options: {
        left: 'left',
        center: 'center',
        right: 'right'
      }
    },
    verticalAlign: {
      options: {
        top: 'top',
        middle: 'middle',
        bottom: 'bottom'
      }
    },
    distance: {
      min: 0,
      max: 100
    }
  };

  config = {
    rotate: 90,
    align: 'left',
    verticalAlign: 'middle',
    position: 'insideBottom',
    distance: 15
  };

  labelOption = {
    show: true,
    position: this.config.position,
    distance: this.config.distance,
    align: this.config.align,
    verticalAlign: this.config.verticalAlign,
    rotate: this.config.rotate,
    formatter: '{c}  {name|{a}}',
    fontSize: 16,
    rich: {
      name: {}
    }
  };

  constructor(private projectService: ProjectService,
              private phaseService: PhaseService,
              private orderService: OrderService,
              private authService: AuthService,
              private agentService: AgentsService,
              private emailService: EmailService,
              private modal: NzModalService,
              private message: NzMessageService,
              protected activatedRoute: ActivatedRoute,
              private viewContainerRef: ViewContainerRef,
              private loadingService: LoadingService,
              private location: Location) { }

  ngOnInit(): void {

    this.id = this.activatedRoute.snapshot.queryParamMap.get('projectId');
    if (!this.id) {
      return;
    }

    this.authService.getUser()
      .subscribe((user: IAgent) => {
        console.log('user/agent', user);
        this.user = user;
    });

    this.loadProject();
    this.loadRejectedDamagedStatus();

  }

  isChartBig = false;

  damagedChartInstance: any;
  onTimeChartInstance: any;

  @HostListener('window:resize', []) 
  onResize() {
    if (window.innerWidth > 570 && !this.isChartBig) {
      this.damagedChartInstance.resize({
        "width": 450,
        "height": 300
      })

      this.onTimeChartInstance.resize({
        "width": 450,
        "height": 300
      })

      this.isChartBig = true
    }
    else if (window.innerWidth <= 570 && this.isChartBig) {
      this.damagedChartInstance.resize({
        "width": 250,
        "height": 300
      })

      this.onTimeChartInstance.resize({
        "width": 250,
        "height": 300
      })

      this.isChartBig = false
    }
  }

  // Setting initial size of the bar chart
  onChartInit (eChart, chartName: string) {

    if (chartName == "damaged") {
      this.damagedChartInstance = eChart
    }
    else if (chartName == "timing") {
      this.onTimeChartInstance = eChart
    }
    let windowSize = window.innerWidth

    if (windowSize > 570) {

      if (chartName == "damaged") {
        this.damagedChartInstance.resize({
          "width": 450,
          "height": 300
        })
      }
      else if (chartName == "timing") {
        this.onTimeChartInstance.resize({
          "width": 450,
          "height": 300
        })
      }
      
      this.isChartBig = true
    }
  }

  loadProject(): void {
    this.projectService.getStatus(this.id)
      .subscribe((project) => {
        this.project = project;
        // const orders = project.orders;
        const stages = project.stages;
        // const phases = project.phases.sort((a,b) => a.number - b.number);
        this.stackedBarChart.yAxis.data = stages.map(stage => stage.name);
        this.stackedBarChart.legend.data = ['On Time', 'Under Float Days', 'Delayed'];
        this.nestedPieChart.legend.data = ['On Time', 'Under Float Days', 'Delayed'];
        const data = {};
        data['On Time'] = [];
        data['Under Float Days'] = [];
        data['Delayed'] = [];

        stages.forEach((stage) => {
          const floatStatuses = stage.orders.map(order => order.floatStatus);
          data['On Time'].push(floatStatuses.filter(status => status === 0).length);
          data['Under Float Days'].push(floatStatuses.filter(status => status === 1).length);
          data['Delayed'].push(floatStatuses.filter(status => status === 2).length);
          this.nestedPieChart.series.push({
            name: stage.name,
            type: 'pie',
            radius: ['10%', '20%'],
            label: {
              position: 'inner',
              fontSize: 14
            },
            labelLine: {
              show: false
            },
            data: [{value: data['On Time'][data['On Time'].length - 1 ], name: 'On Time'}]
          });

          this.nestedPieChart.series.push({
            name: stage.name,
            type: 'pie',
            radius: ['30%', '40%'],
            label: {
              position: 'inner',
              fontSize: 14
            },
            labelLine: {
              show: false
            },
            data: [{value: data['Under Float Days'][data['Under Float Days'].length - 1 ], name: 'Under Float Days'}]
          });

          this.nestedPieChart.series.push({
            name: stage.name,
            type: 'pie',
            radius: ['50%', '60%'],
            label: {
              position: 'inner',
              fontSize: 14
            },
            labelLine: {
              show: false
            },
            data: [{value: data['Delayed'][data['Delayed'].length - 1 ], name: 'Delayed'}]
          });
        });

        /* phases.forEach((phase) => {
          const floatStatuses = orders.filter(order => order.phase.id === phase.id).map(order => order.floatStatus);
          data['On Time'].push(floatStatuses.filter(status => status === 0).length);
          data['Under Float Days'].push(floatStatuses.filter(status => status === 1).length);
          data['Delayed'].push(floatStatuses.filter(status => status === 2).length);
          this.nestedPieChart.series.push({
            name: phase.name,
            type: 'pie',
            radius: ['10%', '20%'],
            label: {
              position: 'inner',
              fontSize: 14
            },
            labelLine: {
              show: false
            },
            data: [{value: data['On Time'][data['On Time'].length - 1 ], name: 'On Time'}]
          });

          this.nestedPieChart.series.push({
            name: phase.name,
            type: 'pie',
            radius: ['30%', '40%'],
            label: {
              position: 'inner',
              fontSize: 14
            },
            labelLine: {
              show: false
            },
            data: [{value: data['Under Float Days'][data['Under Float Days'].length - 1 ], name: 'Under Float Days'}]
          });

          this.nestedPieChart.series.push({
            name: phase.name,
            type: 'pie',
            radius: ['50%', '60%'],
            label: {
              position: 'inner',
              fontSize: 14
            },
            labelLine: {
              show: false
            },
            data: [{value: data['Delayed'][data['Delayed'].length - 1 ], name: 'Delayed'}]
          });
        }); */

        this.stackedBarChart.series.push({
              name: 'On Time',
              type: 'bar',
              stack: 'total',
              barGap: 0,
              label: {
                show: false
              },
              emphasis: {
                  focus: 'series'
              },
              data: data['On Time']
          });
        // this.nestedPieChart.series.push({
        //   name: 'Phase 1',
        //       type: 'pie',
        //       radius: ['10%', '20%'],
        //       label: {
        //         position: 'inner',
        //         fontSize: 14
        //       },
        //       labelLine: {
        //         show: false
        //       },
        //       data: [{value: 1, name: 'On Time'}]
        // });
        this.stackedBarChart.series.push({
              name: 'Under Float Days',
              type: 'bar',
              stack: 'total',
              label: {
                show: false
              },
              emphasis: {
                  focus: 'series'
              },
              data: data['Under Float Days']
          });
        // this.nestedPieChart.series.push({
        //   name: 'Phase 1',
        //   type: 'pie',
        //   radius: ['30%', '40%'],
        //   label: {
        //     position: 'inner',
        //     fontSize: 14
        //   },
        //   labelLine: {
        //     show: false
        //   },
        //   data: [{value: 3, name: 'Under Float Days'}]
        // });
        this.stackedBarChart.series.push({
              barWidth: 40,
              name: 'Delayed',
              type: 'bar',
              stack: 'total',
              label: {
                show: false
              },
              emphasis: {
                  focus: 'series'
              },
              data: data['Delayed']
          });

        // this.nestedPieChart.series.push({
        //   name: 'Phase 1',
        //   type: 'pie',
        //   radius: ['50%', '60%'],
        //   label: {
        //     position: 'inner',
        //     fontSize: 14
        //   },
        //   labelLine: {
        //     show: false
        //   },
        //   data: [{value: 2, name: 'Delayed'}]
        // });

        console.log('data', data);

        console.log('project', project);
        this.delayedOrders = [];
        project.stages.forEach((stage) => {
          this.delayedOrders.push(...(stage.orders.filter((order) => order.floatStatus >= 1)));
        });
        /* this.delayedOrders = project.stages.orders.filter((order) => order.floatStatus >= 1); */
      }, (error) => {
        console.error('Error', error);
        this.message.create('error', `Error: ${error.error.error}`);
    });
  }

  loadRejectedDamagedStatus(): void {
    this.projectService.getRejectedAndDamagedAssetStatus(this.id)
      .subscribe((project) => {
        // console.log('orders', project.stages);
        this.displayRejectedDamagedStatus = true;
        // const orders = project.orders;
        const stages = project.stages;
        // this.rejectedDamagedAssetsChart.yAxis.data = stages.map(stage => stage.name);
        this.rejectedDamagedAssetsChart.legend.data = ['Rejected', 'Damaged'];
        const data = {};
        data['Rejected'] = [];
        data['Damaged'] = [];

        project.stages.forEach((stage) => {
          /* console.log('stage', stage); */
          data['Rejected'].push(stage.ordersRejected);
          data['Damaged'].push(stage.ordersDamaged);
          this.rejectedDamagedAssetsChart.yAxis.data.push(stage.name);
        });


        this.rejectedDamagedAssetsChart.series.push({
              name: 'Rejected',
              type: 'bar',
              stack: 'total',
              label: {
                  show: false
              },
              emphasis: {
                  focus: 'series'
              },
              data: data['Rejected']
          });
        this.rejectedDamagedAssetsChart.series.push({
              barWidth: 40,
              name: 'Damaged',
              type: 'bar',
              stack: 'total',
              label: {
                  show: false
              },
              emphasis: {
                  focus: 'series'
              },
              data: data['Damaged']
          });

        // this.delayedOrders = project.orders.filter((order) => order.floatStatus >= 1);
      }, (error) => {
        console.error('Error', error);
        this.message.create('error', `Error: ${error.error.error}`);
    });
  }

  onBack() {
    this.location.back();
  }

}
