<form novalidate [formGroup]="project">
  <div class="form-row">
    <div class="col-md-12">
      <div class="form-group row">
        <label for="projectId" class="col-sm-5 col-form-label required">Project ID</label>
        <div class="col-sm-7">
          <input type="text" formControlName="projectId" class="form-control form-control-sm" [ngClass]="{ 'is-invalid': (project.get('projectId').dirty || project.get('projectId').touched) && project.get('projectId').errors }" id="projectId">
          <div class="invalid-feedback" *ngIf="(project.get('projectId').dirty || project.get('projectId').touched) && project.get('projectId').hasError('required')">
            The input is not valid Project ID!
          </div>
        </div>
      </div>
      <div class="form-group row">
        <label for="name" class="col-sm-5 col-form-label required">Name</label>
        <div class="col-sm-7">
          <input type="text" formControlName="name" class="form-control form-control-sm" [ngClass]="{ 'is-invalid': (project.get('name').dirty || project.get('name').touched) && project.get('name').errors }" id="name">
          <div class="invalid-feedback" *ngIf="(project.get('name').dirty || project.get('name').touched) && project.get('name').hasError('required')">
            The input is not valid Name!
          </div>
        </div>
      </div>
      <div class="form-group row">
        <label for="description" class="col-sm-5 col-form-label">Description</label>
        <div class="col-sm-7">
          <input type="text" formControlName="description" class="form-control form-control-sm" [ngClass]="{ 'is-invalid': (project.get('description').dirty || project.get('description').touched) && project.get('description').errors }" id="description">
          <div class="invalid-feedback" *ngIf="(project.get('description').dirty || project.get('description').touched) && project.get('description').hasError('required')">
            The input is not valid Description!
          </div>
        </div>
      </div>
      <!-- <div class="form-group row">
        <label for="phases" class="col-sm-5 col-form-label required">Specify Phases</label>
        <div class="col-sm-7">
          <input type="number" formControlName="phases" min="1" max="10" class="form-control form-control-sm" [ngClass]="{ 'is-invalid': (project.get('phases').dirty || project.get('phases').touched) && project.get('phases').errors }" id="phases">
          <div class="invalid-feedback" *ngIf="(project.get('phases').dirty || project.get('phases').touched) && project.get('phases').hasError('required')">
            The input is not valid Phase!
          </div>
        </div>
      </div> -->
      <div class="form-group row">
        <label for="phases" class="col-sm-5 col-form-label required">Specify Stages</label>
        <div class="col-sm-7">
          <input type="number" formControlName="noOfStages" min="1" max="10" class="form-control form-control-sm" [ngClass]="{ 'is-invalid': (project.get('noOfStages').dirty || project.get('noOfStages').touched) && project.get('noOfStages').errors }" id="noOfStages">
          <div class="invalid-feedback" *ngIf="(project.get('noOfStages').dirty || project.get('noOfStages').touched) && project.get('noOfStages').hasError('noOfStages')">
            The input is not valid Stage!
          </div>
        </div>
      </div>
      <div class="form-group row">
        <label for="assignedProjectOwners" class="col-sm-5 col-form-label required">Assign Project Owner</label>
        <div class="col-sm-7">
          <nz-select style="width: 100%;" id="assignedProjectOwners" nzMode="multiple" formControlName="assignedProjectOwners">
            <nz-option *ngFor="let user of userList" [nzLabel]="user.name" [nzValue]="user.publicKey" [nzHide]="!isNotSelected(user)"></nz-option>
          </nz-select>
          <!-- TODO: Validation Please Select User -->
        </div>
      </div>
      <ng-template [ngIf]="project.get('noOfStages').value > 0">
        <div class="col-12">
          <p>Add Stage Name and Number of Phases</p>
        </div>
      </ng-template>
      <ng-container formArrayName="stages" *ngFor="let stage of project.get('stages')['controls']; let i = index" >
        <ng-container [formGroupName]="i">
          <div class="form-group row">
            <label for="phases" class="col-sm-5 col-form-label required">Order</label>
            <div class="col-sm-7">
              <input type="number" formControlName="sequence" min="1" max="100" class="form-control form-control-sm" [ngClass]="{ 'is-invalid': (stage.get('sequence').dirty || stage.get('sequence').touched) && stage.get('sequence').errors }" id="sequence">
              <div class="invalid-feedback" *ngIf="(stage.get('sequence').dirty || stage.get('sequence').touched) && stage.get('sequence').hasError('sequence')">
                The input is not valid Stage Order!
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label for="phases" class="col-sm-5 col-form-label required">Name</label>
            <div class="col-sm-7">
              <input type="text" formControlName="name" class="form-control form-control-sm" [ngClass]="{ 'is-invalid': (stage.get('name').dirty || stage.get('name').touched) && stage.get('name').errors }" id="name">
              <div class="invalid-feedback" *ngIf="(stage.get('name').dirty || stage.get('name').touched) && stage.get('name').hasError('name')">
                The input is not valid Stage Name!
              </div>
            </div>
          </div>
          <div class="form-group row">
            <label for="phases" class="col-sm-5 col-form-label required">Phases</label>
            <div class="col-sm-7">
              <input type="number" formControlName="phases" min="1" max="10" class="form-control form-control-sm" [ngClass]="{ 'is-invalid': (stage.get('phases').dirty || stage.get('phases').touched) && stage.get('phases').errors }" id="phases">
              <div class="invalid-feedback" *ngIf="(stage.get('phases').dirty || stage.get('phases').touched) && stage.get('phases').hasError('phases')">
                The input is not valid Stage Phase!
              </div>
            </div>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>
</form>
