<nz-page-header class="site-page-header" (nzBack)="onBack()" nzBackIcon>
  <nz-page-header-title style="color: #046888;"><strong>Project Stages</strong></nz-page-header-title>
</nz-page-header>
<!-- <button nz-button nzType="primary" (click)="createModal()">Create Phase</button> -->
<!-- <ng-template [ngIf]="user?.subRole?.type === 'ADMINISTRATOR'">
  <button nz-button nzType="primary" style="margin-left: 8px;" (click)="createAssignProjectOwnerModal()">Assign Project Owner</button>
</ng-template> -->
<ng-template [ngIf]="user?.canAssignUsers || user?.canInviteUsers">
  <button nz-button nzType="primary" style="margin-left: 8px;" (click)="createAssignUsersModal(modalTitle)">Assign Users</button>
</ng-template>
<ng-template [ngIf]="user?.subRole?.type !== 'USER'">
  <button nz-button nzType="primary" style="margin-left: 8px;" [routerLink]="['/dashboard', { outlets: { dashboard: ['project-status'] } }]" [queryParams]="{projectId: id}">Project Status</button>
</ng-template>
<ng-template #modalTitle>
  <span style="color: #046888;">Assign Users to {{project.name}}</span>
</ng-template>
<!-- <div style="padding:30px;">
  <div nz-row [nzGutter]="8">
    <div nz-col [nzSpan]="8" *ngFor="let phase of phases">
      <nz-card [nzTitle]="phase?.name">
        <p>{{phase?.phases?.length}} Phases</p>
        <p>{{phase?.updatedOn | date: 'yyyy-MM-dd, h:mm a'}}</p>
        <a nzType="primary" [routerLink]="['/dashboard', { outlets: { dashboard: [ 'phase', phase?.id ] } }]">View Phase</a>
      </nz-card>
    </div>
  </div>
</div> -->
<nz-table #stageTable [nzData]="stages" [nzLoading]="showLoading" style="margin-top: 10px;">
  <thead>
    <tr>
      <th style="background: #4c4c4c; color: white; box-shadow: 5px 0 5px -2px #4c4c4c; z-index: 5;"><strong>Project ID</strong></th>
      <th style="background: #00a2a4; color: white; box-shadow: 5px 0 5px -2px #00a2a4; z-index: 4;"><strong>Name</strong></th>
      <th style="background: #b5c435; color: white; box-shadow: 5px 0 5px -2px #b5c435; z-index: 3;"><strong>Phases</strong></th>
      <th style="background: #046888; color: white; box-shadow: 5px 0 5px -2px #046888; z-index: 2;"><strong>Owner</strong></th>
      <th style="background: #004b62; color: white; box-shadow: 5px 0 5px -2px #046888; z-index: 1;"><strong>Last Updated</strong></th>
      <th style="background: #004b62; color: white;"><strong></strong></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let stage of stageTable.data; let i = index;" [ngStyle]="{'background-color': (i % 2 == 0) ? '#f3f3f3' : '#ffffff' }">
      <td style="color: #4c4c4c;" [style.border-right]="'1px solid #d7d7d7'" [style.box-shadow]="(i+1)+'px 0 5px -2px #e2e2e2'">{{ stage?.project?.projectId }}</td>
      <td style="color: #00a2a4;" [style.border-right]="'1px solid #d7d7d7'" [style.box-shadow]="(i+1)+'px 0 5px -2px #e2e2e2'">{{ stage?.name }}</td>
      <td style="color: #b5c435;" [style.border-right]="'1px solid #d7d7d7'" [style.box-shadow]="(i+1)+'px 0 5px -2px #e2e2e2'">{{ stage?.phases?.length }}</td>
      <td style="color: #046888;" [style.border-right]="'1px solid #d7d7d7'" [style.box-shadow]="(i+1)+'px 0 5px -2px #e2e2e2'">{{ stage?.owner?.name }}</td>
      <td style="color: #046888;" [style.border-right]="'1px solid #d7d7d7'" [style.box-shadow]="(i+1)+'px 0 5px -2px #e2e2e2'">{{ stage?.updatedOn | date: 'yyyy-MM-dd, h:mm a' }}</td>
      <!-- <td><button nzTooltipTitle="delete" nzTooltipPlacement="top" nzShape="circle" nz-button nzType="link" nz-tooltip (click)="delete(order?.id)"><i nz-icon nzType="delete"></i></button></td> -->
      <td>
        <ng-template [ngIf]="user?.canTrackOrders">
          <button nzTooltipTitle="Track" nzTooltipPlacement="top" nzShape="circle" nz-button nzType="link" nz-tooltip [routerLink]="['/dashboard', { outlets: { dashboard: ['stage', stage?.id] } }]"><fa-icon icon="truck-moving"></fa-icon></button>
        </ng-template>
        <div class="circle" [ngClass]="{'green': stage?.floatStatus === 0, 'yellow': stage?.floatStatus === 1, 'red': stage?.floatStatus === 2}"></div>
      </td>
    </tr>
  </tbody>
  <ng-template #projectOrdersHeading>
    <strong style="color: #046888;">Project Orders</strong>
  </ng-template>
</nz-table>

