import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';

import { HttpClientJsonpModule } from '@angular/common/http';
import { GoogleMapsModule } from '@angular/google-maps';

import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { FlexLayoutModule } from '@angular/flex-layout';

import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatDividerModule } from '@angular/material/divider';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatDialogModule } from '@angular/material/dialog';
import { MatTableModule } from '@angular/material/table';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatTabsModule } from '@angular/material/tabs';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatTooltipModule } from '@angular/material/tooltip';

import { ClipboardModule } from '@angular/cdk/clipboard'; 

import { NgxEchartsModule } from 'ngx-echarts';

import { DashboardComponent } from './dashboard.component';
import { ProfileComponent } from './profile/profile.component';
import { AccountComponent } from './account/account.component';
import { ProjectComponent } from './project/project.component';
import { StageComponent } from './stage/stage.component';
import { CreateOrderComponent } from './create-order/create-order.component';
import { CreateRequisitionComponent } from './create-requisition/create-requisition.component';
import { OrdersComponent } from './orders/orders.component';
import { OrderComponent } from './order/order.component';
import { PaymentComponent } from './payment/payment.component';
import { AssetsComponent } from './assets/assets.component';
import { AssetComponent } from './asset/asset.component';
import { SuppliersComponent } from './suppliers/suppliers.component';
import { TrackComponent } from './track/track.component';
import { ProvenanceComponent } from './provenance/provenance.component';
import { HelpComponent } from './help/help.component';
import { UsersComponent } from './users/users.component';
import { UserComponent } from './user/user.component';
import { ProjectStatusComponent } from './project-status/project-status.component';
import { MapComponent } from './map/map.component';
import { SuppliersStatusComponent } from './suppliers-status/suppliers-status.component';
import { LinkedOrdersComponent } from './linked-orders/linked-orders.component';
import { ProvenanceDetailComponent } from './provenance-detail/provenance-detail.component';
import { PayTariffComponent } from './pay-tariff/pay-tariff.component';
import { StageStatusComponent } from './stage-status/stage-status.component';
import { InventoryComponent } from './inventory/inventory.component';
import { CreateInventoryComponent } from './create-inventory/create-inventory.component';
import { EditInventoryComponent } from './edit-inventory/edit-inventory.component';

import { IconsProviderModule } from '../../icons-provider.module';
import { NzLayoutModule } from 'ng-zorro-antd/layout';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzFormModule } from 'ng-zorro-antd/form';
import { NzInputModule } from 'ng-zorro-antd/input';
import { NzInputNumberModule } from 'ng-zorro-antd/input-number';
import { NzSelectModule } from 'ng-zorro-antd/select';
import { NzButtonModule } from 'ng-zorro-antd/button';
import { NzDatePickerModule } from 'ng-zorro-antd/date-picker';
import { NzCheckboxModule } from 'ng-zorro-antd/checkbox';
import { NzDividerModule } from 'ng-zorro-antd/divider';
import { NzSpaceModule } from 'ng-zorro-antd/space';
import { NzEmptyModule } from 'ng-zorro-antd/empty';
import { NzMessageModule } from 'ng-zorro-antd/message';
import { NzTableModule } from 'ng-zorro-antd/table';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NzAvatarModule } from 'ng-zorro-antd/avatar';
import { NzDropDownModule } from 'ng-zorro-antd/dropdown';
import { NzDescriptionsModule } from 'ng-zorro-antd/descriptions';
import { NzBadgeModule } from 'ng-zorro-antd/badge';
import { NzUploadModule } from 'ng-zorro-antd/upload';
import { NzCardModule } from 'ng-zorro-antd/card';
import { NzPageHeaderModule } from 'ng-zorro-antd/page-header';
import { NzAutocompleteModule } from 'ng-zorro-antd/auto-complete';
import { NzStatisticModule } from 'ng-zorro-antd/statistic';
import { NzSwitchModule } from 'ng-zorro-antd/switch';

import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import { ButtonsModule } from 'ngx-bootstrap/buttons';

import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import {
  faTruckMoving,
  faTree,
  faFileAlt,
  faFileWord,
  faFilePdf,
  faMoneyBill,
  faLink,
  faListAlt,
  faCalendarAlt,
  faUserPlus,
  faPen,
  faEye,
  faBarcode,
  faQrcode,
  faFileUpload,
  faInfoCircle,
  faProjectDiagram,
  faFileDownload,
  faSignInAlt,
  faFileInvoiceDollar,
  faPlusSquare,
  faWarehouse
} from '@fortawesome/free-solid-svg-icons';

import { ComponentsModule } from '../../components/';
import { GooglePlaceModule } from "ngx-google-places-autocomplete";

import { SharedModule } from '../../shared';

const modules = [
  DashboardComponent,
  ProfileComponent,
  AccountComponent,
  ProjectComponent,
  StageComponent,
  CreateOrderComponent,
  CreateRequisitionComponent,
  OrdersComponent,
  OrderComponent,
  PaymentComponent,
  AssetsComponent,
  AssetComponent,
  SuppliersComponent,
  TrackComponent,
  ProvenanceComponent,
  HelpComponent,
  UsersComponent,
  UserComponent,
  ProjectStatusComponent,
  MapComponent,
  SuppliersStatusComponent,
  LinkedOrdersComponent,
  ProvenanceDetailComponent,
  PayTariffComponent,
  StageStatusComponent,
  InventoryComponent,
  CreateInventoryComponent,
  EditInventoryComponent
];


@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    ComponentsModule,
    HttpClientJsonpModule,
    FlexLayoutModule,
    NgxEchartsModule,
    GoogleMapsModule,
    IconsProviderModule,
    NzLayoutModule,
    NzMenuModule,
    NzFormModule,
    NzInputModule,
    NzInputNumberModule,
    NzSelectModule,
    NzButtonModule,
    NzDatePickerModule,
    NzCheckboxModule,
    NzDividerModule,
    NzSpaceModule,
    NzEmptyModule,
    NzMessageModule,
    NzTableModule,
    NzToolTipModule,
    NzAvatarModule,
    NzDropDownModule,
    NzDescriptionsModule,
    NzBadgeModule,
    NzUploadModule,
    NzCardModule,
    NzPageHeaderModule,
    NzAutocompleteModule,
    NzStatisticModule,
    NzSwitchModule,
    BsDatepickerModule.forRoot(),
    ButtonsModule.forRoot(),
    FontAwesomeModule,
    MatFormFieldModule,
    MatInputModule,
    MatDividerModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatIconModule,
    MatButtonModule,
    MatDialogModule,
    MatTableModule,
    MatPaginatorModule,
    MatToolbarModule,
    MatCheckboxModule,
    MatChipsModule,
    MatAutocompleteModule,
    MatTabsModule,
    MatProgressSpinnerModule,
    MatTooltipModule,
    ClipboardModule,
    GooglePlaceModule,
    SharedModule
  ],
  providers: [
  ],
  declarations: modules,
  exports: modules
})
export class DashboardModule {
  constructor(library: FaIconLibrary) {
    library.addIcons(
      faTruckMoving,
      faTree,
      faFileAlt,
      faFileWord,
      faFilePdf,
      faMoneyBill,
      faLink,
      faListAlt,
      faCalendarAlt,
      faUserPlus,
      faPen,
      faEye,
      faBarcode,
      faQrcode,
      faFileUpload,
      faInfoCircle,
      faProjectDiagram,
      faFileDownload,
      faSignInAlt,
      faFileInvoiceDollar,
      faPlusSquare,
      faWarehouse);
  }
}
