import { HttpClient } from '@angular/common/http';
import { Observable, of, BehaviorSubject } from 'rxjs';
import { Injectable } from '@angular/core';

import { getUrl } from './utils';
import { BasePlasmaService } from './base.plasma';

import { AgentsService } from './agents.plasma';

import { IAgent } from '@plasma/models/agent';
import { IUser } from '@plasma/models/user';

import { take } from 'rxjs/operators';


@Injectable({
  providedIn: 'root'
})
export class AuthService extends BasePlasmaService {

  protected namespace = 'authentication';
  public authToken: string;
  public user: IAgent;
  public user$: BehaviorSubject<IAgent> = new BehaviorSubject<IAgent>(null);

  constructor(protected http: HttpClient, private agentsService: AgentsService) {
    super(http);
  }

  public setUser(user: IUser) {
    if (user) {
      this.user = user.agent;
      this.user$.next(user.agent);
      this.setAuthToken(user.authorization);
    }
  }

  public setAgent(agent: IAgent) {
    if (agent) {
      this.user = agent;
      this.user$.next(agent);
    }
  }

  public getUser(): Observable<IAgent> {
    return this.user$.asObservable();
  }

  get isLoggedIn(): boolean {
    if (localStorage.length > 0) {
      return !!localStorage.getItem('sessionId');
    }
    // let user: IAgent;
    // this.getUser().pipe(take(1)).subscribe(u => user = u);
    // return !!user;
  }

  public setAuthToken(jwt: string) {
    this.authToken = jwt;
    localStorage.setItem('sessionId', jwt);
  }

  public getAuthToken() {
    if (this.authToken) {
      return this.authToken;
    } else if (localStorage.length > 0) {
      return localStorage.getItem('sessionId');
    }

    return null;
  }

  public clearAuthToken() {
    this.authToken = null;
    this.setUser(null);
    localStorage.clear();
  }

  public getPublicKey() {
    const token = this.getAuthToken();
    if (!token) {
      return null;
    }

    const content = atob(token.split('.')[1]);
    return JSON.parse(content).public_key;
  }

  public signup(agent: IAgent) {
    return this.agentsService.create(agent);
  }

  public login(agent: any) {
    return this.makePost(getUrl(this.namespace), agent) as Observable<any>;
  }
}
