import { Component, OnInit } from '@angular/core';
import { Clipboard } from '@angular/cdk/clipboard';

import { OrderService } from '@plasma/services/order.plasma';
import { AuthService } from '@plasma/services/auth.plasma';
import { NzMessageService } from 'ng-zorro-antd/message';

import { pipe } from 'rxjs';
import { first } from 'rxjs/operators';


@Component({
    selector: 'app-service-provider-orders',
    templateUrl: './orders.component.html',
    styleUrls: ['./orders.component.scss']
})
export class OrdersComponent implements OnInit {

  orders: any[];
  user: any;
  showLoading: boolean = false;

  displayedColumns: string[] = ['contractNo', 'accountNo', 'projectCostCentreCode', 'orderNo', 'phase', 'noOfItems', 'agreedPrice', 'destination', 'createdOn', 'status'];

  constructor(private orderService: OrderService,
              private authService: AuthService,
              private clipboard: Clipboard,
              private message: NzMessageService) {}

  ngOnInit(): void {
    this.authService.getUser()
      .pipe(first())
      .subscribe((user: any) => {
        this.user = user;
        console.log('user', user);
        this.showLoading = true;
        this.orderService.getByServiceProvider(user.publicKey)
          .subscribe((orders) => {
            console.log('orders -----', orders);
            this.orders = orders;
            this.showLoading = false;
          }, (error) => {
            this.showLoading = false;
            console.error('Error', error);
            this.message.create('error', `Error: ${error.error}`);
          });
      });
  }

  copyToClipboard(text: string) {
    if (!text) {
      return;
    }
    this.clipboard.copy(text);
  }


}
