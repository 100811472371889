import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.plasma';
import { AgentsService } from '../../services/agents.plasma';

import { NzMessageService } from 'ng-zorro-antd/message';


@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  validateForm: FormGroup;
  passwordVisible = false;

  constructor(private fb: FormBuilder,
              private authService: AuthService,
              private agentService: AgentsService,
              private router: Router,
              private message: NzMessageService) {}

  ngOnInit(): void {
    this.validateForm = this.fb.group({
      email: [null, [Validators.required, Validators.email]],
      password: [null, [Validators.required]],
      remember: [true]
    });
  }

  submitForm(): void {
    for (const i in this.validateForm.controls) {
      this.validateForm.controls[i].markAsDirty();
      this.validateForm.controls[i].updateValueAndValidity();
    }

    const { valid, value } = this.validateForm;

    if (valid) {
      this.authService.login(value).subscribe(resp => {

        this.authService.setAuthToken(resp.authorization);
        this.agentService.get(this.authService.getPublicKey())
          .subscribe((agent) => {
            this.authService.setUser({agent, authorization: resp.authorization});

            if (agent.role.type === 'SUPPLIER') {
              this.router.navigate(['/dashboard', { outlets: { dashboard: 'orders' }}]);
            } else if (agent.role.type === 'SERVICE_PROVIDER') {
              this.router.navigate(['/service-provider', { outlets: { dashboard: 'orders' } }]);
            } else {
              this.router.navigate(['/dashboard', { outlets: { dashboard: 'account' }}]);
            }

          },

          (error) => {
            console.error('Error', error);
            this.message.create('error', `Error: ${error.error.error}`);
          });
      },

      (error) => {
        console.error('Error', error);
        this.message.create('error', `Error: ${error.error.error}`);
      });
    }

  }

}
