import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Location } from '@angular/common';

import { Clipboard } from '@angular/cdk/clipboard';
import { TrackDataSource } from './track.datasource';

import { OrderService } from '@plasma/services/order.plasma';
import { AssetService } from '@plasma/services/asset.plasma';
import { AuthService } from '@plasma/services/auth.plasma';

import { IAsset } from '@plasma/models/asset';
// import { ISupplier } from '@plasma/models/supplier';

import { NzMessageService } from 'ng-zorro-antd/message';
import { UploadChangeParam, UploadFile } from 'ng-zorro-antd/upload';

import * as moment from 'moment';


@Component({
  selector: 'app-track',
  templateUrl: './track.component.html',
  styleUrls: ['./track.component.scss']
})
export class TrackComponent implements OnInit {

  id: string;
  order: any;
  displayedColumns: string[] = ['id', 'assetType', 'destination', 'plannedCompletionDate', 'plannedDeliveryDate', 'plannedInstalledDate', 'stage', 'modeOfTransport', 'preState', 'postState', 'actualCompletionDate', 'actualDeliveryDate', 'actualInstalledDate', 'dateItemCritical', 'status'];
  dataSource = null;
  assets: any;
  constructor(private orderService: OrderService,
              private assetService: AssetService,
              public authService: AuthService,
              private clipboard: Clipboard,
              private message: NzMessageService,
              protected activatedRoute: ActivatedRoute,
              private location: Location) { }

  ngOnInit(): void {

    this.id = this.activatedRoute.snapshot.paramMap.get('id');
    if (!this.id) {
      return;
    }

    /* this.dataSource = new TrackDataSource(this.orderService, this.id); */
    /* this.assets = this.dataSource.assets;
    console.log('this.assets', this.assets); */

    this.orderService.get(this.id)
      .subscribe((order) => {
        this.order = order;
        this.assets = order.assets;
        console.log('order', order);
      }, (error) => {
        console.error('Error', error);
        this.message.create('error', `Error: ${error.error.error}`);
      });

  }

  calculateCriticalDate(deliveryDate, floatDays) {
    if (deliveryDate === 0) {
      return;
    }
    return moment(deliveryDate).add(floatDays, 'days').valueOf();
  }

  calculateStatus(deliveryDate, actualDeliveryDate, floatDays) {
    if (deliveryDate === 0) {
      return;
    }

    const criticalDate = moment(deliveryDate).add(floatDays, 'days');
    if (moment().isSameOrBefore(deliveryDate)) {
      return 'green';
    } else if (moment().isAfter(deliveryDate) && moment().isSameOrBefore(criticalDate)) {
      return 'yellow';
    } else if (moment().isAfter(criticalDate)) {
      return 'red';
    }
  }

  delete(id) {
    this.assetService.delete(id)
      .subscribe((resp) => console.log('resp', resp),
                 (error) => console.error('Error', error));
  }

  copyToClipboard(text: string) {
    if (!text) {
      return;
    }
    this.clipboard.copy(text);
  }

  onBack() {
    this.location.back();
  }

}
