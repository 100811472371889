<nz-page-header class="site-page-header" (nzBack)="onBack()" nzBackIcon>
  <nz-page-header-title style="color: #046888;"><strong>Project Orders</strong></nz-page-header-title>
</nz-page-header>
<!-- <button nz-button nzType="primary" (click)="createModal()">Create Phase</button> -->
<ng-template [ngIf]="user?.canManageOrders">
  <button nz-button nzType="primary" [routerLink]="['/dashboard', { outlets: { dashboard: ['create-order'] } }]" [queryParams]="{projectId: project?.id}">Create Order</button>
</ng-template>
<!-- <ng-template [ngIf]="user?.subRole?.type === 'ADMINISTRATOR'">
  <button nz-button nzType="primary" style="margin-left: 8px;" (click)="createAssignProjectOwnerModal()">Assign Project Owner</button>
</ng-template> -->
<ng-template [ngIf]="user?.subRole?.type !== 'USER'">
  <button nz-button nzType="primary" style="margin-left: 8px;" (click)="createImportOrdersModal()">Import Order(s)</button>
</ng-template>
<ng-template [ngIf]="user?.canManageOrders">
  <button nz-button nzType="primary" style="margin-left: 8px;" [routerLink]="['/dashboard', { outlets: { dashboard: ['create-requisition'] } }]" [queryParams]="{projectId: project?.id}">Create Requisition</button>
</ng-template>
<ng-template [ngIf]="user?.canManageOrders">
  <button nz-button nzType="primary" style="margin-left: 8px;" [routerLink]="['/dashboard', { outlets: { dashboard: ['stage-status', id] } }]">Stage Status</button>
</ng-template>
<ng-template #modalTitle>
  <span style="color: #046888;">Assign Users to {{project.name}}</span>
</ng-template>
<!-- <div style="padding:30px;">
  <div nz-row [nzGutter]="8">
    <div nz-col [nzSpan]="8" *ngFor="let phase of phases">
      <nz-card [nzTitle]="phase?.name">
        <p>{{phase?.phases?.length}} Phases</p>
        <p>{{phase?.updatedOn | date: 'yyyy-MM-dd, h:mm a'}}</p>
        <a nzType="primary" [routerLink]="['/dashboard', { outlets: { dashboard: [ 'phase', phase?.id ] } }]">View Phase</a>
      </nz-card>
    </div>
  </div>
</div> -->
<nz-table #orderTable [nzData]="orders" [nzLoading]="showLoading" style="margin-top: 10px;">
  <thead>
    <tr>
      <th style="background: #4c4c4c; color: white; box-shadow: 5px 0 5px -2px #4c4c4c; z-index: 10;"><strong>Contract No</strong></th>
      <th style="background: #00a2a4; color: white; box-shadow: 5px 0 5px -2px #00a2a4; z-index: 9;"><strong>Account No</strong></th>
      <th style="background: #b5c435; color: white; box-shadow: 5px 0 5px -2px #b5c435; z-index: 8;"><strong>Project Cost Centre Code</strong></th>
      <th style="background: #046888; color: white; box-shadow: 5px 0 5px -2px #046888; z-index: 7;"><strong>Order No</strong></th>
      <th style="background: #004b62; color: white; box-shadow: 5px 0 5px -2px #004b62; z-index: 6;"><strong>Phase</strong></th>
      <th style="background: #4c4c4c; color: white; box-shadow: 5px 0 5px -2px #4c4c4c; z-index: 5;"><strong>No. of Items</strong></th>
      <th style="background: #00a2a4; color: white; box-shadow: 5px 0 5px -2px #00a2a4; z-index: 4;"><strong>Total Price</strong></th>
      <th style="background: #b5c435; color: white; box-shadow: 5px 0 5px -2px #b5c435; z-index: 3;"><strong>Destination</strong></th>
      <th style="background: #046888; color: white; box-shadow: 5px 0 5px -2px #046888; z-index: 2;"><strong>Created On</strong></th>
      <th style="background: #004b62; color: white; box-shadow: 5px 0 5px -2px #004b62; z-index: 1;"><strong>Status</strong></th>
      <th style="background: #004b62; color: white;"><strong></strong></th>
    </tr>
  </thead>
  <tbody>
    <tr *ngFor="let order of orderTable.data; let i = index;" [ngStyle]="{'background-color': (i % 2 == 0) ? '#f3f3f3' : '#ffffff' }">
      <td style="color: #4c4c4c;" [style.border-right]="'1px solid #d7d7d7'" [style.box-shadow]="(i+1)+'px 0 5px -2px #e2e2e2'">{{ order?.contract_no }}</td>
      <td style="color: #00a2a4;" [style.border-right]="'1px solid #d7d7d7'" [style.box-shadow]="(i+1)+'px 0 5px -2px #e2e2e2'">{{ order?.account_no }}</td>
      <td style="color: #b5c435;" [style.border-right]="'1px solid #d7d7d7'" [style.box-shadow]="(i+1)+'px 0 5px -2px #e2e2e2'">{{ order?.project_cost_centre_code }}</td>
      <td style="color: #046888;" [style.border-right]="'1px solid #d7d7d7'" [style.box-shadow]="(i+1)+'px 0 5px -2px #e2e2e2'"><a [routerLink]="['/dashboard', { outlets: { dashboard: ['order', order?.id] } }]">{{ order?.order_no }}</a></td>
      <td style="color: #004b62;" [style.border-right]="'1px solid #d7d7d7'" [style.box-shadow]="(i+1)+'px 0 5px -2px #e2e2e2'">{{ order?.phase?.number }}</td>
      <td style="color: #4c4c4c;" [style.border-right]="'1px solid #d7d7d7'" [style.box-shadow]="(i+1)+'px 0 5px -2px #e2e2e2'">{{ order?.assets?.length }}</td>
      <td style="color: #00a2a4;" [style.border-right]="'1px solid #d7d7d7'" [style.box-shadow]="(i+1)+'px 0 5px -2px #e2e2e2'">£{{ order?.agreed_price | number: '1.2-3' }}</td>
      <td style="color: #b5c435;" [style.border-right]="'1px solid #d7d7d7'" [style.box-shadow]="(i+1)+'px 0 5px -2px #e2e2e2'">{{ order?.destination }}</td>
      <td style="color: #046888;" [style.border-right]="'1px solid #d7d7d7'" [style.box-shadow]="(i+1)+'px 0 5px -2px #e2e2e2'">{{ order?.createdOn | date: 'yyyy-MM-dd, h:mm a' }}</td>
      <td style="color: #004b62;" [style.border-right]="'1px solid #d7d7d7'" [style.box-shadow]="(i+1)+'px 0 5px -2px #e2e2e2'"><div class="circle" [ngClass]="calculateStatus(order?.assets)"></div></td>
      <!-- <td><button nzTooltipTitle="delete" nzTooltipPlacement="top" nzShape="circle" nz-button nzType="link" nz-tooltip (click)="delete(order?.id)"><i nz-icon nzType="delete"></i></button></td> -->
      <td>
        <ng-template [ngIf]="user?.canTrackOrders && !order?.is_requisition">
          <button nzTooltipTitle="Track" nzTooltipPlacement="top" nzShape="circle" nz-button nzType="link" nz-tooltip [routerLink]="['/dashboard', { outlets: { dashboard: ['track', order?.id] } }]"><fa-icon icon="truck-moving"></fa-icon></button>
        </ng-template>
        <ng-template [ngIf]="user?.canAuthorisePayment && !order?.is_requisition">
          <button nzTooltipTitle="Pay" nzTooltipPlacement="top" nzShape="circle" nz-button nzType="link" nz-tooltip [routerLink]="['/dashboard', { outlets: { dashboard: ['payment', order?.id] } }]"><fa-icon icon="money-bill"></fa-icon></button>
        </ng-template>
        <ng-template [ngIf]="user?.canCheckProvenance && !order?.is_requisition">
          <button nzTooltipTitle="Provenance" nzTooltipPlacement="top" nzShape="circle" nz-button nzType="link" nz-tooltip [routerLink]="['/dashboard', { outlets: { dashboard: ['provenance', order?.id] } }]"><fa-icon icon="tree"></fa-icon></button>
        </ng-template>
        <ng-template [ngIf]="user?.subRole?.type !== 'USER' && !order?.is_requisition">
          <button nzTooltipTitle="Linked Orders" nzTooltipPlacement="top" nzShape="circle" nz-button nzType="link" nz-tooltip [routerLink]="['/dashboard', { outlets: { dashboard: ['linked-orders', order?.id] } }]"><fa-icon icon="link"></fa-icon></button>
        </ng-template>
        <ng-template [ngIf]="user?.subRole?.type !== 'USER' && user?.role?.type !== 'SUPPLIER' && !order?.is_requisition">
          <button nzTooltipTitle="Download Compliance Documents" nzTooltipPlacement="top" nzShape="circle" nz-button nzType="link" nz-tooltip><fa-icon icon="file-download" (click)="openUploadedDocumentsDialog(order)"></fa-icon></button>
        </ng-template>
        <ng-template [ngIf]="user?.canAuthorisePayment && !order?.is_requisition">
          <button nzTooltipTitle="Pay Tariff" nzTooltipPlacement="top" nzShape="circle" nz-button nzType="link" nz-tooltip [routerLink]="['/dashboard', { outlets: { dashboard: ['pay-tariff', order?.id] } }]"><fa-icon icon="file-invoice-dollar"></fa-icon></button>
        </ng-template>
      </td>
    </tr>
  </tbody>
  <ng-template #projectOrdersHeading>
    <strong style="color: #046888;">Project Orders</strong>
  </ng-template>
</nz-table>

