import {
  Routes
} from '@angular/router';

import { AccountComponent } from './account/account.component';
import { ProjectComponent } from './project/project.component';
import { CreateOrderComponent } from './create-order/create-order.component';
import { CreateRequisitionComponent } from './create-requisition/create-requisition.component';
import { ProfileComponent } from './profile/profile.component';
import { OrdersComponent } from './orders/orders.component';
import { OrderComponent } from './order/order.component';
import { PaymentComponent } from './payment/payment.component';
import { AssetsComponent } from './assets/assets.component';
import { AssetComponent } from './asset/asset.component';
import { SuppliersComponent } from './suppliers/suppliers.component';
import { TrackComponent } from './track/track.component';
import { ProvenanceComponent } from './provenance/provenance.component';
import { HelpComponent } from './help/help.component';
import { UsersComponent } from './users/users.component';
import { UserComponent } from './user/user.component';
import { ProjectStatusComponent } from './project-status/project-status.component';
import { MapComponent } from './map/map.component';
import { SuppliersStatusComponent } from './suppliers-status/suppliers-status.component';
import { LinkedOrdersComponent } from './linked-orders/linked-orders.component';
import { StageComponent } from './stage/stage.component';
import { ProvenanceDetailComponent } from './provenance-detail/provenance-detail.component';
import { PayTariffComponent } from './pay-tariff/pay-tariff.component';
import { StageStatusComponent } from './stage-status/stage-status.component';
import { InventoryComponent } from './inventory/inventory.component';
import { CreateInventoryComponent } from './create-inventory/create-inventory.component';
import { EditInventoryComponent } from './edit-inventory/edit-inventory.component';


export const routes: Routes = [
  {
    path: '',
    redirectTo: 'account',
    pathMatch: 'full'
  },
  {
    path: 'account',
    component: AccountComponent,
    outlet: 'dashboard'
  },
  {
    path: 'project/:id',
    component: ProjectComponent,
    outlet: 'dashboard'
  },
  {
    path: 'stage/:id',
    component: StageComponent,
    outlet: 'dashboard'
  },
  {
    path: 'project-status',
    component: ProjectStatusComponent,
    outlet: 'dashboard'
  },
  {
    path: 'create-order',
    component: CreateOrderComponent,
    outlet: 'dashboard'
  },
  {
    path: 'create-requisition',
    component: CreateRequisitionComponent,
    outlet: 'dashboard'
  },
  {
    path: 'profile',
    component: ProfileComponent,
    outlet: 'dashboard'
  },
  {
    path: 'orders',
    component: OrdersComponent,
    outlet: 'dashboard'
  },
  {
    path: 'order/:id',
    component: OrderComponent,
    outlet: 'dashboard'
  },
  {
    path: 'payment/:id',
    component: PaymentComponent,
    outlet: 'dashboard'
  },
  {
    path: 'pay-tariff/:id',
    component: PayTariffComponent,
    outlet: 'dashboard'
  },
  {
    path: 'stage-status/:id',
    component: StageStatusComponent,
    outlet: 'dashboard'
  },
  {
    path: 'track/:id',
    component: TrackComponent,
    outlet: 'dashboard'
  },
  {
    path: 'provenance/:id',
    component: ProvenanceComponent,
    outlet: 'dashboard'
  },
  {
    path: 'provenance-detail/:id',
    component: ProvenanceDetailComponent,
    outlet: 'dashboard'
  },
  {
    path: 'map',
    component: MapComponent,
    outlet: 'dashboard'
  },
  {
    path: 'items',
    component: AssetsComponent,
    outlet: 'dashboard'
  },
  {
    path: 'items/:id',
    component: AssetComponent,
    outlet: 'dashboard'
  },
  {
    path: 'suppliers',
    component: SuppliersComponent,
    outlet: 'dashboard'
  },
  {
    path: 'suppliers-status',
    component: SuppliersStatusComponent,
    outlet: 'dashboard'
  },
  {
    path: 'users',
    component: UsersComponent,
    outlet: 'dashboard'
  },
  {
    path: 'users/:id',
    component: UserComponent,
    outlet: 'dashboard'
  },
  {
    path: 'linked-orders/:id',
    component: LinkedOrdersComponent,
    outlet: 'dashboard'
  },
  {
    path: 'help',
    component: HelpComponent,
    outlet: 'dashboard'
  },
  {
    path: 'inventory',
    component: InventoryComponent,
    outlet: 'dashboard'
  },
  {
    path: 'inventory/:id',
    component: EditInventoryComponent,
    outlet: 'dashboard'
  },
  {
    path: 'create-inventory',
    component: CreateInventoryComponent,
    outlet: 'dashboard'
  }
];
