import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { ComponentsModule } from '../components';
import { LoginModule } from './login';
import { SignUpModule } from './sign-up';
import { DashboardModule } from './dashboard';
import { InvitationSignUpModule } from './invitation-sign-up';


import { SupplierAModule } from './supplier-a';
import { SupplierBModule } from './supplier-b';
import { SupplierCModule } from './supplier-c';


// import { NzLayoutModule } from 'ng-zorro-antd/layout';
// import { NzMenuModule } from 'ng-zorro-antd/menu';
// import { NZ_I18N } from 'ng-zorro-antd/i18n';
// import { en_US } from 'ng-zorro-antd/i18n';

const modules = [
];


@NgModule({
  imports: [
    CommonModule,
    ReactiveFormsModule,
    RouterModule,
    ComponentsModule,
    LoginModule,
    SignUpModule,
    DashboardModule,
    InvitationSignUpModule,
    SupplierAModule,
    SupplierBModule,
    SupplierCModule
  ],
  providers: [
  ],
  declarations: modules,
  exports: modules
})
export class PageModule {}
